import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/custom.css';
import './index.scss';
import App from './components/App';
import { Provider } from 'react-redux';
import store from './store/store';
import Amplify from 'aws-amplify';

Amplify.configure({
	Auth: {
		mandatorySignIn: true,
		region: process.env.REACT_APP_COGNITO_REGION,
		userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
		identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
		userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID
	},
	Storage: {
		bucket: process.env.REACT_APP_Bucket_name,
		region: process.env.REACT_APP_region,
		userPoolId: process.env.REACT_APP_userPoolId
		//identityPoolId: process.env.REACT_APP_identityPoolId
	}
});

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById('root')
);
