import {
    CS_SEARCH_PENDING,
    CS_SEARCH_FAILED,
    CS_SEARCH_SUCCESS,
} from "../../util/constant";
import api from "../../util/api";
import appURLConstant from "../../util/appURLConstants";
import _ from "lodash";

export const getCustomerSupportList = async (params, dispatch) => {
    try {
        dispatch({
            type: CS_SEARCH_PENDING,
        });

        const response = await api.post(
            `${appURLConstant.REACT_CS_SEARCH_LIST_URL}`,
            params
        );

        response.data.orbitResponse.statusCode === 400 ||
            !_.isEmpty(response.data.orbitResponse.errorMessage)
            ? dispatch({
                type: CS_SEARCH_FAILED,
                payload:
                    response.data.orbitResponse.statusCode === 400 ||
                    (!_.isEmpty(response.data.orbitResponse.errorMessage) &&
                        "Search not found."),
            })
            : dispatch({
                type: CS_SEARCH_SUCCESS,
                payload: _.isEmpty(response.data.orbitResponse.body)
                    ? "no data found"
                    : response.data.orbitResponse.body,
            });
      
    } catch (error) {
        dispatch({
            type: CS_SEARCH_FAILED,
            payload: error,
        });
    }
};

export const resetCustomerSupportList = async (dispatch) => {
    dispatch({
        type: "RESET_CS_LIST_SUCCESS",
    });
};

export const setSearchInputValues = async (data, dispatch) => {
    dispatch({
        type: "SET_SEARCH_INPUT_VALUES",
        payload: data,
    });
};

