import { Auth } from 'aws-amplify';
import axios from 'axios';
import { toLower, includes } from 'lodash';

const environment = toLower(process.env.REACT_APP_ENVIRONMENT);

axios.defaults.headers.common['x-api-key'] =
  process.env.REACT_APP_ORBIT_API_KEY;

if (!includes(['prod', 'sandbox'], environment)) {
  const message = environment === 'uat' ? 'let-me-in-to-uat' : 'let-me-in';
  axios.defaults.headers.common[`${environment}-access`] = message;
}

const Api = {
  // Logged-in Users will get a valid Cognito Token, which is pass in Authorization header
  // For non-logged in users, leave the Authorization header empty
  // This will continue to allow access to public APIs, while correctly securing access to secure APIs

  fetch: (path) => {
    axios.interceptors.request.use(async function (config) {
      try {
        const currentAuthSession = await Auth.currentSession();
        const token = currentAuthSession.getIdToken().getJwtToken();
        config.headers.Authorization = token;
        return config;
      } catch (error) {
        config.headers.Authorization = '';
        return config;
      }
    });
    return axios.get(path);
  },
  post: (path, data) => {
    axios.interceptors.request.use(async function (config) {
      try {
        const currentAuthSession = await Auth.currentSession();
        const token = currentAuthSession.getIdToken().getJwtToken();
        config.headers.Authorization = token;
        return config;
      } catch (error) {
        config.headers.Authorization = '';
        return config;
      }
    });
    return axios.post(path, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },
  put: (path, data) => {
    axios.interceptors.request.use(async function (config) {
      try {
        const currentAuthSession = await Auth.currentSession();
        const token = currentAuthSession.getIdToken().getJwtToken();
        config.headers.Authorization = token;
        return config;
      } catch (error) {
        config.headers.Authorization = '';
        return config;
      }
    });
    return axios.put(path, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },
};

export default Api;
