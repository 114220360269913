import React from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  setSearchInputValues,
  resetCustomerSupportList,
} from "../../../../store/actions/CS_SearchAction";
import resetSearchInputs from "../../../../util/hooks/ResetSearchInputs";

const Breadcrumbs = ({
  isTravelHistory,
  isFailedTransaction,
  setSearchInputValues,
  resetCustomerSupportList,
}) => {
  const history = useHistory();
  const onTravelHistory = () => {
    resetCustomerSupportList();
    resetSearchInputs(setSearchInputValues);
    history.push("/travelhistory");
  };
  const onFailedTransaction = () => {
    resetCustomerSupportList();
    resetSearchInputs(setSearchInputValues);
    history.push("/failedtransactions");
  };
  const onCSPortalClick = () => {
    resetCustomerSupportList();
    resetSearchInputs(setSearchInputValues);
    history.push("/csportal");
  };
  return (
    <Breadcrumb className="customer-support-breadcrumb">
      {" "}
      <BreadcrumbItem>
        <Link
          className={`font14 ${history.location.pathname === "/"
              ? "breadcrumb-active"
              : "breadcrumb-default"
            }`}
          to={{
            pathname: "/",
          }}
        >
          Dashboard{" "}
        </Link>
      </BreadcrumbItem>{" "}
      <BreadcrumbItem>
        <Link
          className={`font14 ${history.location.pathname === "/csportal"
              ? "breadcrumb-active"
              : "breadcrumb-default"
            }`}
          to="#"
          onClick={onCSPortalClick}
        >
          Customer Support Portal{" "}
        </Link>
      </BreadcrumbItem>{" "}
      {isTravelHistory && (
        <BreadcrumbItem>
          <Link
            className={`font14 ${history.location.pathname === "/travelhistory"
                ? "breadcrumb-active"
                : "breadcrumb-default"
              }`}
            to="#"
            onClick={onTravelHistory}
          >
            Travel History{" "}
          </Link>
        </BreadcrumbItem>
      )}
      {isFailedTransaction && (
        <BreadcrumbItem>
          <Link
            className={`font14 ${history.location.pathname === "/failedtransactions"
                ? "breadcrumb-active"
                : "breadcrumb-default"
              }`}
            
            to="#"
            onClick={onFailedTransaction}
          >
            Failed Transaction Result{" "}
          </Link>
        </BreadcrumbItem>
      )}
    </Breadcrumb>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    setSearchInputValues: (params) => setSearchInputValues(params, dispatch),
    resetCustomerSupportList: () => resetCustomerSupportList(dispatch),
  };
};
export default connect(null, mapDispatchToProps)(Breadcrumbs);
