import React, { useState } from 'react';
import {
    Container,
    Card,
    CardBody,
    FormGroup,
    Label,
    Input,
    Row,
    Col,
    Spinner
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import MainWrapper from '../../../MainWrapper';
import { notificationFetchById } from '../../../../store/actions/NotificationAction';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import NotificationHeader from './NotificationHeader';
import EditRoutesList from '../routeslist/EditRoutesList';
import { useEffect } from 'react';
import { format } from 'date-fns';

const NotificationView = ({
    getNotifications,
    viewNotificationsById: { viewNotificationsById },
    auth: { user: { given_name } },
    fetchViewError: { fetchViewError },
    viewByIdLoading: { viewByIdLoading }
    
}) => {
    const notification_id = useParams();
    const [errorMsg, setErrorMsg] = useState('');
    const [state, setState] = useState({
        alertType: '',
        deliveryType: '',
        routeIds: '',
        startDate: new Date(),
        status: '',
        title: '',
        message: '',
        description: '',
        sentTo: '',
        route_id: [],
        routeLongName: '',
        operatorCode: ''
    });

    const [routesList, setRoutesList]=useState([])
    const [sentTo, setSentTo] = useState('')
    const [dateOptions, setDateOptions]=useState(false)

    useEffect(() => {
        const id = _.get(notification_id, 'id');
        getNotifications(window.atob(id));
    }, [notification_id]);

    useEffect(
        () => {
            if (!_.isEmpty(viewNotificationsById)) {
                let startDt = new Date(_.get(viewNotificationsById, 'startDate',new Date()));
                setState({
                    ...viewNotificationsById,
                    startDate: format(new Date(startDt), 'MMM dd, yyyy')
                });
                setRoutesList(_.get(viewNotificationsById, 'oprRoutes',[]))
                setSentTo(_.get(viewNotificationsById, 'sentTo',''))
                setDateOptions(_.get(viewNotificationsById, 'immediate',false))
                
                
            }
        },
        [viewNotificationsById]
    );

    useEffect(
        () => {
            if (!_.isEmpty(fetchViewError)) {
                setErrorMsg('Error Occur on data fetch.');
            }
        },
        [fetchViewError]
    );
    
    return (
        <MainWrapper>
                <NotificationHeader userName={given_name} showView={true} />
                <Container>
                    <Row>
                        <Col xs="12">
                        <h3 className="font24-b text-uppercase">
                                Notification Information
						</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <div className={!_.isEmpty(errorMsg) ? 'error-msg' : ''}>
                                {errorMsg}
                            </div>
                        </Col>
                    </Row>
                <Card className="createNotification">
                    {viewByIdLoading && (
                        <div className="loader">
                            <div className="spinner">
                                <Spinner animation="border" variant="primary" />
                            </div>
                        </div>
                    )}
                    <CardBody className="px-5 py-4">
                        <div>
                            <Row>
                                <Col sm="12">
                                    <div className="float-left mr-5 font16-b">
                                        Select Alert Types:
										</div>
                                    <div className="float-left mr-5">
                                        <FormGroup tag="fieldset" className="font16 mb-0">
                                            <Label check>
                                                <Input
                                                    type="radio"
                                                    value="Operational"
                                                    name="operational"
                                                    id="alertType"
                                                    checked={state.alertType === 'Operational'}
                                                    disabled
                                                    
                                                />{' '}
													Operational Alerts
                                                <span className="radioMark"></span>
												</Label>
                                        </FormGroup>
                                    </div>
                                    
                                    <div className="clearfix"></div>
                                    <hr />
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="12">
                                    <div className="float-left mr-5 font16-b">Delivery Type:</div>
                                    <div className="font16 float-left mr-5">
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                value="push"
                                                checked={!_.isEmpty(state.deliveryType) && state.deliveryType.includes('push')}
                                                id="deliverType"
                                                disabled={true}
                                            />{' '}
												Push Notification
                                            <span className="checkBoxMark"></span>
											</Label>
                                    </div>

                                    <div className="font16 float-left">
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                id="deliverType"
                                                checked={!_.isEmpty(state.deliveryType) && state.deliveryType.includes('sms')}
                                                value="sms"
                                                disabled={true}
                                            />{' '}
												Text SMS
                                            <span className="checkBoxMark"></span>
											</Label>
                                    </div>
                                   
                                    <div className="clearfix"></div>
                                    <hr />
                                </Col>
                            </Row>

                            <Row>
                                {!_.isEmpty(state.deliveryType) && (
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label for="title" className="font14">Title </Label>
                                            <Input
                                                type="text"
                                                id="title"
                                                name="title"
                                                value={state.title}
                                                className={
                                                   
                                                    'form-control'
                                                       
                                                }
                                                disabled={true}
                                            />
                                           
                                        </FormGroup>
                                       
                                    </Col>
                                )}
                                {(!_.isEmpty(state.deliveryType) && _.includes(state.deliveryType, 'sms')) && (
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label for="message" className="font14">Sub Message</Label>
                                            <Input
                                                type="textarea"
                                                id="message"
                                                name="message"
                                                value={state.message}
                                                className={
                                                  
                                                    'form-control'
                                                        
                                                }
                                                disabled={true}
                                            />
                                        </FormGroup>
                                    </Col>
                                )}
                            </Row>
                            {(!_.isEmpty(state.deliveryType) && _.includes(state.deliveryType, 'push')) && (
                                <Row className={'card-tr'}>
                                    <Col sm="6" className="mb-5">
                                        <FormGroup>
                                            <Label for="description" className="font14">Description</Label>
                                            <Input
                                                type="textarea"
                                                id="description"
                                                name="description"
                                                disabled={true}
                                                value={state.description}
                                                className={
                                                   
                                                    'form-control'
                                                       
                                                }
                                            />
                                            
                                        </FormGroup>
                                       
                                    </Col>
                                </Row>
                            )}
                            <Row>
                                <Col sm="12" className="mt-3">
                                    <Label className="float-left mr-5 font16-b">
                                        {' '}
											Select Date :{' '}
                                    </Label>
                                    <div className="float-left mr-5">
                                        <FormGroup tag="fieldset" className="font16">
                                            <Label check>
                                                <Input
                                                    type="radio"
                                                    value="now"
                                                    name="now"
                                                    checked={dateOptions}
                                                    readOnly={true}
                                                    disabled
                                                />{' '}
													Now
												<span className="radioMark"></span>
                                            </Label>
                                        </FormGroup>
                                    </div>
                                    <div className="float-left ml-5 font16">
                                        <FormGroup tag="fieldset">
                                            <Label check>
                                                <Input
                                                    type="radio"
                                                    value="later"
                                                    name="later"
                                                    selected={true}
                                                    checked={!dateOptions}
                                                    disabled={true}
                                                />{' '}
													Later
												<span className="radioMark"></span>
                                            </Label>
                                        </FormGroup>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="4">
                                    <FormGroup className="date_picker_width">
                                        <DatePicker
                                            value={state.startDate}
                                            popperPlacement="right-bottom"
                                            className="startDateField form-control"
                                            readOnly={true}
                                        />
                                        
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="4" className="font12">
                                    {!dateOptions ? (
                                        <p>
                                            <b>Please note:</b>{' '}
                                            <i>
                                                Messages are processed at 4 AM (EST), but may be
                                                delivered throughout the day based on carrier delivery
                                                times.
												</i>
                                        </p>
                                    ) : (
                                            <p>
                                                <b>Please note:</b>{' '}
                                                <i>
                                                    It may take some time to process all messages and
                                                    delivery may not be immediate”
												</i>
                                            </p>
                                        )}
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs="12">
                                    <div className="clearfix"></div>
                                    <hr />
                                </Col>
                                <Col sm="4">
                                    <div className="font16-b float-left">
                                        <b>Send to:</b>
                                    </div>
                                </Col>
                                <Col sm="4">
                                    <div className="font16-b float-left">
                                      
                                    </div>
                                </Col>
                                
                                <Col sm="4">
                                    {!_.isEmpty(sentTo) && sentTo === 'All' ? (
                                        <div className="float-right">
                                            <Label check>
                                                <Input
                                                    type="checkbox"
                                                    value="allRoutesSelect"
                                                    checked={true}
                                                    readOnly={true}
                                                    disabled
                                                />{' '}
													Select All Routes
												<span className="checkBoxMark"></span>
                                            </Label>
                                        </div>
                                    ) : (
                                            ''
                                        )}
                                </Col>
                                
                            </Row>
                            <Row className={'card-tr'}>
                                <Col sm="12" className="mb-3">
                                    {!viewByIdLoading &&
                                         (
                                            <EditRoutesList
                                            routes={routesList}
                                            sentTo={sentTo}
                                            />
                                        )}
                                </Col>
                            </Row>
                            
                        </div>
                    </CardBody>
                </Card>
                </Container>
                
            
        </MainWrapper>
    );
};

const mapStateToProps = ({ auth, notificationReducer }) => {
    return {
        viewNotificationsById: notificationReducer,
        fetchViewError: notificationReducer,
        viewByIdLoading: notificationReducer,
        auth
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getNotifications: (param) => notificationFetchById(param, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationView);

