import React, { useState, useEffect } from 'react';
import { fetchAgency } from '../../../../store/actions/AgencyAction';
import { connect } from 'react-redux';
import { Spinner, Label } from 'reactstrap';
import _ from 'lodash';

const EditRoutesList = ({
	handleAgencyData,
	agencyItems: { agencyItems },
	isAgencyLoading: { isAgencyLoading },
	isLoading: { isLoading },
	routes,
	sentTo
}) => {
	const [ agencyData, setAgencyData ] = useState([]);

	useEffect(
		() => {
			const param = {
				tblKey: 'allAgenciesAndRoutes'
			};
			handleAgencyData(param);
		},
		[handleAgencyData]
	);

	useEffect(
		() => {
			const agency = !_.isEmpty(agencyItems) ? agencyItems : [];
			setAgencyData(agency);
			
		},
		[agencyItems, routes ]
	);

	return (
		<div className="routeCheckbox">
			{(isLoading || isAgencyLoading) && (
				<div className="loader">
					<div className="spinner">
						<Spinner animation="border" variant="primary" />
					</div>
					<br />
				</div>
			)}
			<div className="routeCheckboxList">
				
				{agencyData.map((a, index) => {
				
					return (
							<div className="mb-2 bg-light pt-2 pl-3" key={index}>
								{sentTo === 'All' ? (
									<Label check>
										<input
											type="checkbox"
											checked={true}
											readOnly={true}
											className="mr-2"
											disabled
										/>
										{a.agencyName}
										<span className="checkBoxMark"></span>
									</Label>
								) : (
										<Label check>
											<input
												type="checkbox"
												checked={!_.isEmpty(routes[a.operatorCode])}
												name={a.operatorCode}
												value={a.id}
												readOnly={true}
												className="mr-2"
												disabled
											/>
											{a.agencyName}
											<span className="checkBoxMark"></span>
										</Label>)}
								{sentTo !== 'All' && (
									<ul className="notification_sub_checkbox  list-group list-group-flush ml-2 mt-2">
										{
											_.map(_.get(routes, `${a.operatorCode}`, []), (r, rIndex) => {
												return (
									
													<li key={rIndex} className="list-group-item px-2 py-1 bg-light border-bottom-0">
														<Label check>
															<input
																type="checkbox"
																value={r.route_id}
																checked={r.route_id}
																readOnly={true}
																className="mr-2"
																disabled
															/>
															{r.route_long_name}
															<span className="checkBoxMark"></span>
														</Label>
													</li>
												)
											})}
										
									</ul>
								)}
							</div>
					);
				})}
			</div>
		</div>
	);
};

const mapStateToProps = ({ agencyReducer, viewGtfsReducer }) => {
	return {
		agencyItems: agencyReducer,
		isAgencyLoading: agencyReducer,
		gtfsTablesData: viewGtfsReducer,
		isLoading: viewGtfsReducer
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		handleAgencyData: (tblKey) => fetchAgency(tblKey, dispatch)
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(EditRoutesList);
