import React, { useState } from 'react';
import NotificationEditView from './NotificationEditView';
import {
	updateRoutesList,
	notificationFetchById,
	notificationEdit,
	notificationEditDate,
	emptyEditNotification
} from '../../../../store/actions/NotificationAction';
import { fetchGtfsData } from '../../../../store/actions/ViewAction';
import ConfirmationModal from '../confirmation/ConfirmationModal'
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import _ from 'lodash';
import { format } from 'date-fns';

const NotificationEdit = ({
	getNotifications,
	saveNotifications: { saveNotifications },
	updateNotifications: { updateNotifications },
	updateRoutesList,
	history: { push },
	isNotificationsLoading: { isNotificationsLoading },
	saveError: { saveError },
	updateError: { updateError },
	viewNotificationsById: { viewNotificationsById },
	viewByIdLoading: { viewByIdLoading },
	handleNotificationEdit,
	editNotifications: { editNotifications },
	isEditLoading: { isEditLoading },
	editError: { editError },
	editDateError: { editDateError },
	emptyEditNotification
}) => {
	let { id } = useParams();
	id = window.atob(id);
	const [open, setOpen] = useState(false);
	const [ state, setState ] = useState({
		title: '',
		message: '',
		description: ''
	});
	const [ fieldsError, setFieldsError ] = useState({
		title: '',
		message: '',
		description: '',
		alertType: '',
		deliverType: ''
	});
	const [ alertTypeValue, setAlertTypeValue ] = useState('');
	const [ deliverType, setDeliverType ] = useState([]);
	const [ startDate, setStartDate ] = useState(new Date());
	const [ nextStep, setNextStep ] = useState(0);
	const [ errorMsg, setErrorMsg ] = useState(null);
	const [alertId, setAlertId] = useState('');
	const [routeList, setRouteList] = useState([]);
	const [sentTo, setSentTo]=useState('')
	const [dateOptions, setDateOptions] = useState(false)
	
	const validateForm = () => {
		let formIsValid = true;
		let fieldsError = {};

		if (deliverType.includes('push') && deliverType.includes('sms')) {
			if (_.isEmpty(state.title.trim())) {
				fieldsError.title = 'Please provide title';
				formIsValid = false;
			}
			if (_.isEmpty(state.message.trim())) {
				fieldsError.message = 'Please provide sms message';
				formIsValid = false;
			}
			if (_.isEmpty(state.description.trim())) {
				fieldsError.description = 'Please provide push message';
				formIsValid = false;
			}
		}
		if (!_.includes(deliverType, 'sms')) {
			if (_.isEmpty(state.title.trim())) {
				fieldsError.title = 'Please provide title';
				formIsValid = false;
			}
			if (_.isEmpty(state.description.trim())) {
				fieldsError.description = 'Please provide push message';
				formIsValid = false;
			}

		}
		if (!_.includes(deliverType, 'push')) {
			if (_.isEmpty(state.title.trim())) {
				fieldsError.title = 'Please provide a title';
				formIsValid = false;
			}
			if (_.isEmpty(state.message.trim())) {
				fieldsError.message = 'Please provide a short message';
				formIsValid = false;
			}

		}

		if (_.isEmpty(alertTypeValue)) {
			fieldsError.alertType = 'Please select alert type';
			formIsValid = false;
		}
		if (_.isEmpty(deliverType)) {
			fieldsError.deliverType = 'Please select delivery type';
			formIsValid = false;
		}
		
		if (!formIsValid) {
			setFieldsError(fieldsError);
		}
		return formIsValid;
	};

	const isDeliverTypeSelected = () => {
		if (!_.isEmpty(deliverType) && !_.includes(deliverType, 'push') ? (!_.isEmpty(state.title.trim()) && !_.isEmpty(state.message.trim())) :
			!_.includes(deliverType, 'sms') ? (!_.isEmpty(state.title.trim()) && !_.isEmpty(state.description.trim())) :
				(_.includes(deliverType, 'push') && _.includes(deliverType, 'sms')) ? (!_.isEmpty(state.title.trim()) && !_.isEmpty(state.message.trim()) && !_.isEmpty(state.description.trim()))
					: ''
		) {
			return false
		}
		return true
	}

	const handleSubmit = async (e) => {
		e.preventDefault();
		window.scrollTo(0, 0);
		if (validateForm()) {
			if (!_.isEmpty(viewNotificationsById)) {
				const editData = {
					id: alertId,
					alertType: alertTypeValue,
					deliveryType: deliverType,
					title: state.title.trim(),
					message: state.message.trim(),
					description: state.description.trim(),
					
				};
				handleNotificationEdit(editData);
			}
		}
	};



	useEffect(
		() => {
			if (!_.isEmpty(viewNotificationsById)) {
				const editData = viewNotificationsById;
				setState(editData);
				setAlertId(_.get(editData, 'id'));
				setAlertTypeValue(_.get(editData, 'alertType'));
				const deliveryTypes = _.get(editData, 'deliveryType');
				setDeliverType(deliveryTypes);
				setRouteList(_.get(editData, 'oprRoutes'))
				setSentTo(_.get(editData,'sentTo'))
				const startDt =
					_.get(editData, 'startDate') === 'Invalid date'
						? format(new Date(), 'MMM dd, yyyy')
						: format(new Date(_.get(editData, 'startDate')), 'MMM dd, yyyy');
				setStartDate(startDt);
				setDateOptions(_.get(editData, 'immediate'))
			}
			return () => {
				emptyEditNotification()
			};
		},
		
		[ viewNotificationsById ]
	);

	useEffect(
		() => {
			if (!_.isEmpty(saveNotifications)) {
				setErrorMsg('');
				
			}
		},
		[ saveNotifications ]
	);

	useEffect(
		() => {
			if (!_.isEmpty(editNotifications) ) {
				setErrorMsg('');
				setOpen(true)
			}
		},
		[ editNotifications ]
	);
	
	useEffect(
		() => {
			if (!_.isEmpty(updateNotifications)) {
				setErrorMsg('');
				setNextStep((prevState) => prevState - 1);
				push(`/notification`);
			}
		},
		[ updateNotifications,push ]
	);

	const handleChange = ({ target: { id, value } }) => {
		const fieldsError = Object.assign({}, state.fieldsError);
		switch (id) {
			case 'title':
				if (typeof value !== 'undefined') {
					fieldsError.title = '';
				}
				break;
			case 'message':
				if (typeof value !== 'undefined') {
					fieldsError.message = '';
				}
				break;
			case 'description':
				if (typeof value !== 'undefined') {
					fieldsError.description = '';
				}
				break;
			default:
				break;
		}
		setFieldsError(fieldsError);
		setErrorMsg('');
		setState({
			...state,
			[id]: value
		});
	};
	const ResetData = () => {
		setAlertTypeValue('');
		setDeliverType([]);
		setState({ title: '', message: '', description: '' });
	};

	useEffect(
		() => {
			if (!_.isEmpty(saveError)) {
				updateRoutesList([]);
				setErrorMsg(saveError);
				ResetData();
			}
		},
		[saveError, updateRoutesList ]
	);

	useEffect(
		() => {
			if (!_.isEmpty(updateError)) {
				setErrorMsg('Error coming on saving data!');
			}
		},
		[ updateError ]
	);
	useEffect(
		() => {
			if (!_.isEmpty(editError)) {
				setErrorMsg('Error coming on editing data!');
			}
		},
		[ editError ]
	);

	useEffect(
		() => {
			if (!_.isEmpty(editDateError)) {
				setErrorMsg('Error coming on editing data!');
			}
		},
		[ editDateError ]
	);

	useEffect(() => {
		setErrorMsg('');
		if (!_.isEmpty(id)) {
			getNotifications(id);
		}
	}, [getNotifications,id]);

	const onAlertTypeClick = (e) => {
		fieldsError.alertType = '';
		setFieldsError(fieldsError);
		setErrorMsg('');
		setAlertTypeValue(e.target.value);
	};

	const onDeliverHandleChange = (e) => {
		setErrorMsg('');

		if (e.target.checked) {
			fieldsError.deliverType = '';
			setFieldsError(fieldsError);
			setDeliverType([...deliverType, e.target.value]);
			
		} else {
			setDeliverType(
				_.filter(deliverType, (dl) => dl !== e.target.value)
			);
		}
		
	};

	return (
		<div>
			<NotificationEditView
				state={state}
				handleChange={handleChange}
				handleSubmit={handleSubmit}
				fieldsError={fieldsError}
				saveError={saveError}
				deliverType={deliverType}
				onAlertTypeClick={onAlertTypeClick}
				alertTypeValue={alertTypeValue}
				onDeliverHandleChange={onDeliverHandleChange}
				nextStep={nextStep}
				startDate={startDate}
				isNotificationsLoading={isNotificationsLoading}
				isEditLoading={isEditLoading}
				routes={routeList}
				errorMsg={errorMsg}
				viewByIdLoading={viewByIdLoading}
				sentTo={sentTo}
				dateOptions={dateOptions}
				isDeliverTypeSelected={isDeliverTypeSelected}
			/>
			<ConfirmationModal message="edited" open={open} />
		</div>
	);
};

const mapStateToProps = ({
	notificationReducer,
	agencyReducer,
	productFareReducer
}) => {
	return {
		saveNotifications: notificationReducer,
		updateNotifications: notificationReducer,
		agencyItems: agencyReducer,
		productFareZone: productFareReducer,
		isAgencyLoading: agencyReducer,
		isNotificationsLoading: notificationReducer,
		saveError: notificationReducer,
		updateError: notificationReducer,
		viewNotificationsById: notificationReducer,
		fetchViewError: notificationReducer,
		viewByIdLoading: notificationReducer,
		editNotifications: notificationReducer,
		isEditLoading: notificationReducer,
		editError: notificationReducer,
		editDateError: notificationReducer
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		handleNotificationEdit: (data) => notificationEdit(data, dispatch),
		handleNotificationEditDate: (data) => notificationEditDate(data, dispatch),
		getRoutesData: (tblKey) => fetchGtfsData(tblKey, dispatch),
		updateRoutesList: (list) => updateRoutesList(list, dispatch),
		getNotifications: (param) => notificationFetchById(param, dispatch),
		emptyEditNotification: () => emptyEditNotification( dispatch)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationEdit);
