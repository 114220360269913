import React, { useEffect, useMemo, useState } from "react";
import { Col, Container, Row, Spinner } from "reactstrap";
import { connect, useSelector } from "react-redux";
import NoResultsPopup from "./modal/NoResultsPopup";
import MainWrapper from "../../MainWrapper";
import List from "./list/CustomerSupportGrid";
import Breadcrumbs from "./navigation/Breadcrumbs";
import Navigation from "./navigation/Navigation";
import Search from "./Search";
import { getCustomerSupportList } from "../../../store/actions/CS_SearchAction";
import { getViewOrder } from "../../../store/actions/CS_ViewOrderAction";
import _ from "lodash";
import { useHistory } from "react-router-dom";

const Home = ({ getCSList, getOrder }) => {
  const { isSearchLoading, listData, searchError } = useSelector(
    (state) => state.csSearchReducer
  );
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState(false);
  const [noResultsPopup, setnoResultsPopup] = useState(false);

  const history = useHistory();
  useEffect(() => {
    setFilters(false);
    setnoResultsPopup(false);
    setData([]);
  }, []);
  const columns = useMemo(
    () => [
      {
        Header: "Purchase Date",
        accessor: "purchase_date",
        width: 100,
      },
      {
        Header: "Email Address",
        accessor: "email",
        width: 200,
      },
      {
        Header: "Order Number",
        accessor: "order_id",
        width: 200,
      },
      {
        Header: "Ticket Number",
        accessor: "ticket_id",
        width: 250,
      },
      {
        Header: "Origin FareZone",
        accessor: "origin_farezone",
        width: 110,
      },
      {
        Header: "Destination FareZone",
        accessor: "destination_farezone",
        width: 140,
      },
      {
        Header: "Valid From",
        accessor: "valid_from",
      },
      {
        Header: "Valid To",
        accessor: "valid_to",
      },
      {
        Header: "Ticket Type",
        accessor: "ticket_type",
      },
      {
        Header: "Rides Remaining",
        accessor: "rides_remaining",
        width: 130,
      },
      {
        Header: "Order Status",
        accessor: "order_status",
        width: 120,
      },
      {
        Header: "Wells Fargo Ref Number",
        accessor: "cyber_source_ref_id",
        width: 200,
      },
      {
        Header: "Order Total",
        accessor: "total_amount",
        // width: 130,
      },
      {
        Header: "Customer Name",
        accessor: "customer_name",
        width: 130,
      },
    ],
    []
  );
  const onSearch = (data) => {
    getCSList(data);
    setFilters((!_.isEmpty(data.additionalFilterKey)) && (data.fromDate || data.ToDate) && true);
  };

  useEffect(() => {
    setData([]);
    const orderData = !_.isEmpty(listData) && _.get(listData,"searchOrder",[])
    if (filters && (_.includes(orderData, "no data") || _.isEmpty(orderData)) && !isSearchLoading) {
      setnoResultsPopup(true);
    } else {
      _.mapValues(orderData, (v) => setData((prev) => [...prev, ...v]));
    }
   
  }, [listData, filters]);

  useEffect(() => {
    if (!_.isEmpty(searchError)) setnoResultsPopup(true);
  }, [searchError]);

  const onClear = () => {
    setData([]);
  };
  const handleViewOrder = (data) => {
    getOrder(data);
    if (data) history.push("/vieworder");
  };
  
  return (
    <MainWrapper>
      {isSearchLoading && (
        <div className="loader">
          <div className="spinner">
            <Spinner animation="border" variant="primary" />
          </div>
        </div>
      )}
      <Container>
        <Breadcrumbs />
        {noResultsPopup && (
          <NoResultsPopup
            modalOpen={noResultsPopup}
            handleClose={() => setnoResultsPopup(!noResultsPopup)}
          />
        )}
        <Search onSearch={onSearch} onClear={()=> onClear()} />
        <Navigation/>
        {!_.isEmpty(data) && (
          <>
            <Row className="mt-4">
              <Col>
                <span className="font24-b">CUSTOMER SUPPORT PORTAL LIST</span>
              </Col>
            </Row>
            <List
              data={data}
              columns={columns}
              handleViewOrder={handleViewOrder}
            />
          </>
        )}
      </Container>
    </MainWrapper>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCSList: (params) => getCustomerSupportList(params, dispatch),
    getOrder: (data) => getViewOrder(data, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(Home);
