import React from "react";
import { Button, Card, CardBody, CardTitle } from "reactstrap";
import { useHistory } from "react-router-dom";
import CS from "../../../assets/images/CS.svg";

const CustomerSupportCard = () => {
  const history = useHistory();

  const handleClick = () => {
    history.push(`/csportal`);
  };

  return (
    <Card className="text-center card-box-shadow-8">
      <CardBody
        className="p-6"
      >
        <img src={CS} alt="cs_icon" className="m-2-75" />
        <CardTitle>
          <span className="font34">Customer Support</span>
        </CardTitle>
        <Button
          color="primary"
          className="px-4 py-2-75 text-uppercase btn-pill"
          onClick={() => handleClick()}
        >
          <span className="p-4 font16-b">GO TO CUSTOMER SUPPORT</span>
        </Button>
      </CardBody>
    </Card>
  );
};

export default CustomerSupportCard;
