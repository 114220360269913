import React from "react";
import _ from "lodash";
import { Card, Col, Row, Table } from "reactstrap";
import {
  useBlockLayout,
  usePagination,
  useResizeColumns,
  useTable,
} from "react-table";
import PaginationLeft from "../pagination/PaginationHeader";
import PaginationRight from "../pagination/PaginationRightSide";
import PaginationFooter from "../pagination/PaginationFooter";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { getMonthlyPassTicket, toLocalDate } from "../../../../util/func";
import useUserAccessDetails from '../../../../util/hooks/UserAccessDetails';

function List({ columns, data, handleViewOrder, isFailedTransaction }) {
  const userAccessDetails = useUserAccessDetails();
  const access = _.get(userAccessDetails, "csAdminHome", {})
  
  const onNextPage = () => {
    nextPage();
  };
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30,
      width: 100,
      maxWidth: 400,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,

    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useBlockLayout,
    useResizeColumns,
    usePagination
  );
  return (
    <>
      <Row className="productFareListHead">
        {(!_.isEmpty(data) && !isFailedTransaction) &&
          <Col sm="4">
            <PaginationLeft
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              pageOptions={pageOptions}
              pageCount={pageCount}
              gotoPage={gotoPage}
              nextPage={onNextPage}
              previousPage={previousPage}
              setPageSize={setPageSize}
              pageSize={pageSize}
              pageIndex={pageIndex}
              data={data}
            />
          </Col>
          }
        {(!_.isEmpty(data) && _.size(data) > 10 && !isFailedTransaction) &&
          <Col sm="8">
            <div className="display_record">
              <PaginationRight
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                pageOptions={pageOptions}
                pageCount={pageCount}
                gotoPage={gotoPage}
                nextPage={onNextPage}
                previousPage={previousPage}
                setPageSize={setPageSize}
                pageSize={pageSize}
                pageIndex={pageIndex}
                page={page}
              />
            </div>
          </Col>
        }
        </Row>
      
      <Card>
        <div className="table-responsive">
          <Table
            borderless
            className="table customerSupportList"
            {...getTableProps()}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th className="font12-b" {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return cell.column.id === "order_id" ? (
                        <td {...cell.getCellProps()}>
                          {_.get(access, "viewOrderAccess", true) ?
                            (<Link
                              to="#"
                              className="font12"
                              onClick={() => handleViewOrder(row.original)}
                            >
                              {cell.render("Cell")}
                            </Link>) :
                            <span className="font12"> {cell.render("Cell")} </span>
                          }
                        </td>
                      ) : (
                          <td {...cell.getCellProps()}>
                            {cell.column.id === "scan_date_time" &&
                              !_.isEmpty(row.original.scan_date_time) ? (
                                format(
                                  new Date(row.original.scan_date_time),
                                  "MM/dd/yyyy HH:mm:ss"
                                )
                              ) :
                              cell.column.id === "transactionOn" && 
                                !_.isEmpty(row.original.transactionOn) ? (
                                  format(
                                    new Date(row.original.transactionOn),
                                    "MM/dd/yyyy HH:mm:ss"
                                  )
                                )
                                :
                            cell.column.id === "purchase_date" &&
                              !_.isEmpty(row.original.purchase_date) ? (
                                format(
                                  new Date(row.original.purchase_date),
                                  "MM/dd/yyyy"
                                )
                              ) : cell.column.id === "valid_from" &&
                                !_.isEmpty(row.original.valid_from) ? (
                                  format(
                                    toLocalDate(row.original.valid_from),
                                    "MM/dd/yyyy"
                                  )
                                ) : cell.column.id === "valid_to" &&
                                  !_.isEmpty(row.original.valid_to) ? (
                                        format(toLocalDate(row.original.valid_to), 'MM/dd/yyyy')
                                  ) : cell.column.id === "total_amount" ? (
                                        `$ ${parseFloat(row.original.total_amount).toFixed(2)}`
                                      ) :
                                        cell.column.id === "orderAmount" ? (
                                          `$ ${parseFloat(row.original.orderAmount).toFixed(2)}`
                                        ) 
                                      :
                                      cell.column.id === "failedAction"  ?
                                        (<Link to="#"
                                          className="font12"
                                          onClick={() => handleViewOrder(row.original)}
                                        >
                                          View
                                        </Link>)
                                            : 
                                            cell.column.id === "ticket_type" ? (
                                              _.includes(
                                                row.original.ticket_type.toUpperCase(),
                                                "MONTHLY"
                                              ) ? (
                                                  getMonthlyPassTicket(
                                                    row.original.valid_from
                                                  ).toUpperCase()
                                                ) : (
                                                  row.original.ticket_type
                                                )
                                            )
                                              :
                                              cell.column.id === "action" ? 
                                                _.startCase(_.toLower(row.original.action))
                                              :
                                      (
                                      <span className="font12">
                                        {" "}
                                        {cell.render("Cell")}
                                      </span>
                                    )}
                          </td>
                        );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Card>
      <div className="text-center">
        {(!_.isEmpty(data) && _.size(data)>10) && (
          <PaginationFooter
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageOptions={pageOptions}
            pageCount={pageCount}
            gotoPage={gotoPage}
            nextPage={onNextPage}
            previousPage={previousPage}
            setPageSize={setPageSize}
            pageSize={pageSize}
            pageIndex={pageIndex}
            page={page}
          />
        ) }
      </div>
    </>
  );
}

const CustomerSupportGrid = ({ data, columns, handleViewOrder,isFailedTransaction }) => {
  return (
    <>
      <List
        columns={columns}
        data={data}
        handleViewOrder={handleViewOrder}
        pageSize={50}
        isFailedTransaction={isFailedTransaction}
      />
    </>
  );
};

export default CustomerSupportGrid;
