import React, { useEffect} from 'react';
import { connect } from 'react-redux';
import { Spinner } from 'reactstrap';
import { useHistory } from "react-router-dom";
import AdminDashboard from './pages/Dashboard';
import {  useSelector } from 'react-redux';
import { getUser } from '../store/actions/AuthAction';

const Home = ({ getCurrentUser}) => {
	const history = useHistory();
	const {isAccessLoading, isAuthenticated} = useSelector(state => state.auth);
	useEffect(() => {
		if (isAuthenticated) {
			getCurrentUser();
			history.push("/dashboard");
		}
	}, [isAuthenticated]);

	return (
		<div>
			{isAccessLoading && (
				<div className="loader">
					<div className="spinner">
						<Spinner animation="border" variant="primary" />
					</div>
				</div>
			)}
				<AdminDashboard/>
		</div>
	);
};
const mapDispatchToProps = (dispatch) => {
	return {
		getCurrentUser: () => getUser(dispatch),
		
	};
};

export default connect(null, mapDispatchToProps)(Home);