import {
  TICKET_REDEMP_INSERT_PENDING,
  TICKET_REDEMP_INSERT_FAILED,
  TICKET_REDEMP_INSERT_SUCCESS,
  TICKET_REDEMP_FETCH_PENDING,
  TICKET_REDEMP_FETCH_FAILED,
  TICKET_REDEMP_FETCH_SUCCESS,
} from "../../util/constant";
//import _ from 'lodash';
//import { data } from '../../gtfsJson/notificationmessages.json';

const initialState = {
  savedRedeemResponse: [],
  saveTicketRedeemError: {},
  viewTicketRedeemError:{},
  isLoading: false,
  ticketsData: [],
  newExpiryDateResponse: {},
  ticketInfoData: [],
  activeButton: ""
};

const ticketRedemReducer = (state = initialState, action) => {
  switch (action.type) {
    case TICKET_REDEMP_INSERT_PENDING:
      return {
        ...state,
        isLoading: true,
        saveTicketRedeemError:{},
        savedRedeemResponse: [],
        ticketInfoData: [],
        activeButton:""
      };
    case TICKET_REDEMP_INSERT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        savedRedeemResponse: action.payload,
        ticketInfoData: [],
        activeButton: ""
      };
    case TICKET_REDEMP_INSERT_FAILED:
      return {
        ...state,
        isLoading: false,
        saveTicketRedeemError: action.payload,
      };
    case TICKET_REDEMP_FETCH_PENDING:
      return {
        ...state,
        isLoading: true,
        savedRedeemResponse: [],
        viewTicketRedeemError:{}
      };
    case TICKET_REDEMP_FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ticketsData: action.payload
      };
    case TICKET_REDEMP_FETCH_FAILED:
      return {
        ...state,
        isLoading: false,
        viewTicketRedeemError: action.payload,
        ticketsData: [],
      };
    case 'SET_REDEMP_TICKET_INFO_SUCCESS':
      return {
        ...state,
        ticketInfoData: action.info,
        activeButton: action.activeButton
      };
    default:
      return state;
  }
};

export default ticketRedemReducer;
