const initialState = {
  viewOrderData: {},
  viewOrderError: {},
  viewNoteHistoryError: {},
  viewCustomerInfoError:{},
  errorInSendingEmail: false,
  isViewOrderLoading: false,
  isViewNoteLoading:false,
  noteHistory: [],
  noteHistoryUpdated: false,
  customerInformation: {},
  emailRecieptSent: false,
  errorUpdatingNotes: false,
  errorState:{},
  stateData:[],
  isStateLoading:false
};
const csViewOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CS_VIEW_ORDER_PENDING":
      return {
        ...state,
        isViewOrderLoading: true,
        error: {},
        noteHistoryUpdated: false,
        emailRecieptSent: false,
        errorInSendingEmail: false,
        viewOrderData: {},
        errorUpdatingNotes: false,
      };
    case "CS_VIEW_ORDER_SUCCESS":
      return {
        ...state,
        isViewOrderLoading: false,
        viewOrderData: action.payload,
      };
    case "CS_VIEW_ORDER_FAILED":
      return {
        ...state,
        isViewOrderLoading: false,
        viewOrderError: action.payload,
      };

    case "CS_GET_NOTE_HISTORY_PENDING":
      return {
        ...state,
        isViewNoteLoading: true,
        viewNoteHistoryError: {},
        noteHistory: [],
        noteHistoryUpdated: false,
        emailRecieptSent: false,
        errorInSendingEmail: false,
        errorUpdatingNotes: false,
      };
    case "CS_GET_NOTE_HISTORY_SUCCESS":
      return {
        ...state,
        isViewNoteLoading: false,
        noteHistory: action.payload,
      };
    case "CS_GET_NOTE_HISTORY_FAILED":
      return {
        ...state,
        isViewNoteLoading: false,
        viewNoteHistoryError: action.payload,
      };

    case "UPDATE_NOTE_HISTORY_PENDING":
      return {
        ...state,
        isViewOrderLoading: true,
        noteHistoryUpdated: false,
        emailRecieptSent: false,
        errorInSendingEmail: false,
        errorUpdatingNotes: false,
      };
    case "UPDATE_NOTE_HISTORY_SUCCESS":
      return {
        ...state,
        isViewOrderLoading: false,
        noteHistoryUpdated: true,
      };
    case "UPDATE_NOTE_HISTORY_FAILED":
      return {
        ...state,
        isViewOrderLoading: false,
        noteHistoryUpdated: false,
        errorUpdatingNotes: action.payload,
      };

    case "CS_GET_CUSTOMER_INFORMATION_PENDING":
      return {
        ...state,
        isViewOrderLoading: true,
        viewCustomerInfoError: {},
        noteHistory: [],
        noteHistoryUpdated: false,
        emailRecieptSent: false,
        customerInformation: {},
        errorInSendingEmail: false,
        errorUpdatingNotes: false,
      };
    case "CS_GET_CUSTOMER_INFORMATION_SUCCESS":
      return {
        ...state,
        isViewOrderLoading: false,
        customerInformation: action.payload,
      };
    case "CS_GET_CUSTOMER_INFORMATION_FAILED":
      return {
        ...state,
        isViewOrderLoading: false,
        viewCustomerInfoError: action.payload,
      };
    case "SEND_EMAIL_RECIEPT_PENDING":
      return {
        ...state,
        isViewOrderLoading: true,
        emailRecieptSent: false,
        noteHistoryUpdated: false,
        errorInSendingEmail: false,
        errorUpdatingNotes: false,
      };
    case "SEND_EMAIL_RECIEPT_SUCCESS":
      return {
        ...state,
        isViewOrderLoading: false,
        emailRecieptSent: true,
      };
    case "SEND_EMAIL_RECIEPT_FAILED":
      return {
        ...state,
        isViewOrderLoading: false,
        emailRecieptSent: false,
        errorInSendingEmail: action.payload,
      };
    case "STATE_LIST_PENDING":
      return {
        ...state,
        isStateLoading: true,
        errorState:{},
        stateData:[]
      };
    case "STATE_LIST_SUCCESS":
      return {
        ...state,
        isStateLoading: false,
        stateData: action.payload,
      };
    case "STATE_LIST_FAILED":
      return {
        ...state,
        isStateLoading: false,
        errorState: action.payload,
      };
    default:
      return state;
  }
};

export default csViewOrderReducer;
