import React from "react";
import { useState } from "react";
import { Card, Container } from "reactstrap";
import { Col, Row, Button, Form, FormGroup, Label, Input } from "reactstrap";
import Backarrow from "../../../../assets/images/down-arrow.svg";

const EditInformation = ({ backClick }) => {
  const [customerInfo, setCustomerInfo] = useState({
    firstname: "",
    middleinitial: "",
    lastname: "",
    email: "",
    street: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zipcode: "",
    phonecode: "",
    phonenumber: "",
  });

  const handleChange = ({ target: { id, value } }) => {
    setCustomerInfo((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };
  return (
    <Container className="mt-3">
      <>
        <span
          className="font16-b"
          onClick={backClick}
          style={{ color: "#279CF7" }}
        >
          <img
            src={Backarrow}
            style={{ transform: "rotate(-270deg)" }}
            alt="backarrow"
          />
          Back
        </span>
        <Card body className="edit-card-container mx-2 my-3">
          <span className="title font20-b">Customer information</span>
          <Form className="mt-2">
            <Row form>
              <Col md={4}>
                <FormGroup>
                  <Label className="font14-b">First name</Label>
                  <Input
                    onChange={handleChange}
                    type="text"
                    name="firstname"
                    id="firstname"
                    value={customerInfo.firstname}
                    placeholder="Enter first name"
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label className="font14-b">Middle initial</Label>
                  <Input
                    onChange={handleChange}
                    type="text"
                    name="middleinitial"
                    id="middleinitial"
                    placeholder="Enter middle initial"
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label className="font14-b">Last name</Label>
                  <Input
                    onChange={handleChange}
                    type="text"
                    name="lastname"
                    id="lastname"
                    placeholder="Enter last name"
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row className="mt-1" form>
              <Col md={4}>
                <FormGroup>
                  <Label className="font14-b">Email address</Label>
                  <Input
                    onChange={handleChange}
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter email address"
                  />
                </FormGroup>
              </Col>
              <Col md={8}>
                <FormGroup>
                  <Label className="font14-b">Street address</Label>
                  <Input
                    onChange={handleChange}
                    type="text"
                    name="streetaddress"
                    id="streetaddress"
                    placeholder="Enter street address"
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row className="mt-1" form>
              <Col md={8}>
                <FormGroup>
                  <Label className="font14-b">
                    Address line 2 <span className="font14">(Optional)</span>
                  </Label>
                  <Input
                    onChange={handleChange}
                    type="text"
                    name="address2"
                    id="address2"
                    placeholder="Enter address line 2"
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label className="font14-b">City</Label>
                  <Input
                    onChange={handleChange}
                    type="select"
                    defaultValue="Paramus"
                    name="city"
                    id="city"
                  >
                    <option>Default Select</option>
                    <option>Paramus</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>

            <Row className="mt-1" form>
              <Col md={4}>
                <FormGroup>
                  <Label className="font14-b">State</Label>
                  <Input
                    onChange={handleChange}
                    type="select"
                    defaultValue="New Jersey"
                    name="state"
                    id="state"
                  >
                    <option>Default Select</option>
                    <option>New Jersey</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label className="font14-b">Zip code</Label>
                  <Input
                    onChange={handleChange}
                    type="text"
                    name="zipcode"
                    id="zipcode"
                    placeholder="Enter zip code"
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <Row form>
                  <Col md={5}>
                    <FormGroup>
                      <Label className="font14-b">Phone Code</Label>
                      <Input
                        onChange={handleChange}
                        type="select"
                        defaultV
                        value="+1"
                        name="phonecode"
                        id="phonecode"
                      >
                        <option>Default Select</option>
                        <option>+1</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md={7}>
                    <FormGroup>
                      <Label className="font14-b">Phone number</Label>
                      <Input
                        onChange={handleChange}
                        type="phone"
                        name="phonenumber"
                        id="phonenumber"
                        placeholder="Enter phone number"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col className="text-right">
                <Button className="button-cancel font14-b" onClick={backClick}>
                  Cancel
                </Button>
                <Button className="button-send font14-b">Update</Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </>
    </Container>
  );
};

export default EditInformation;
