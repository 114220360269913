import React from 'react';

const PaginationHeader = ({
	pageSize,
	data
}) => {
	
	return (
		<>
			<h6 className="font16 showing-result">
				Showing{' '}
				<span>
					{pageSize} of {data.length} results
					{/* {pageIndex + 1} of {pageOptions.length} results */}
				</span>{' '}
			</h6>
		</>
	);
};

export default PaginationHeader;
