import React from "react";
import { Modal } from "react-bootstrap";
import Warning from "../../../../assets/images/warning-icon.svg";
import { Col, Row, Button } from "reactstrap";
import { MdClose } from "react-icons/md";
const WarningPopup = ({ modalOpen, handleClose, onSave }) => {
  return (
    <Modal  show={modalOpen}>
     
      <div className="text-right"><span
        closeButton
        onClick={handleClose}
        className="h2 inline-block mr-1 cursor-pointer"
      >
        <MdClose />
      </span>
      </div>
      
        <div className="content px-8 mx-2">
          <Row className="my-3">
            <Col className="text-center">
              <img src={Warning} alt="Allseticon" />
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <span className="font24-b">Are you sure?</span>
            </Col>
          </Row>
          <Row>
            <Col className="text-center mx-2 mt-1">
              <span className="font18">Do you want to save your changes before continuing?</span>
            </Col>
          </Row>
        </div>
      <div className="py-4 text-center">
        <Button outline color="primary" className="button-cancel py-2-75 px-3 btn-pill font16-b mr-2 text-uppercase" onClick={handleClose}>
           No, Don't Save Changes
          </Button>
        <Button color="primary" className="button-search btn-pill py-2-75 px-3 font16-b text-uppercase" onClick={onSave}>
            Yes, Save Changes
          </Button>
      </div>
    </Modal>
  );
};

export default WarningPopup;
