import { useCallback } from "react";

const useValidationMessage = () => {
  const emailNotValidLoginMessage = useCallback((isError) => {
    if (isError) {
      return "Please enter valid Employee ID";
    }
    return null;
  }, []);
  const passwordInvalidLogin = useCallback((isError) => {
    if (isError) {
      return "Please enter valid Password";
    }
    return null;
  }, []);

  return {
    emailNotValidLoginMessage,
    passwordInvalidLogin,
  };
};

export default useValidationMessage;
