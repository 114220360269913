import {
	NOTIFICATION_CREATE_PENDING,
	NOTIFICATION_CREATE_FAILED,
	NOTIFICATION_CREATE_SUCCESS,
	UPDATE_ROUTES_LIST_PENDING,
	UPDATE_ROUTES_LIST_SUCCESS,
	UPDATE_ROUTES_LIST_FAILED,
	NOTIFICATION_FETCH_PENDING,
	NOTIFICATION_FETCH_SUCCESS,
	NOTIFICATION_FETCH_FAILED,
	SUBSCRIPTION_FETCH_PENDING,
	SUBSCRIPTION_FETCH_SUCCESS,
	SUBSCRIPTION_FETCH_FAILED,
	NOTIFICATION_UPDATE_PENDING,
	NOTIFICATION_UPDATE_FAILED,
	NOTIFICATION_UPDATE_SUCCESS,
	NOTIFICATION_FETCH_BY_ID_PENDING,
	NOTIFICATION_FETCH_BY_ID_SUCCESS,
	NOTIFICATION_FETCH_BY_ID_FAILED,
	NOTIFICATION_DELETE_SUCCESS,
	NOTIFICATION_DELETE_PENDING,
	NOTIFICATION_DELETE_FAILED,
	NOTIFICATION_SEARCH_PENDING,
	NOTIFICATION_SEARCH_FAILED,
	NOTIFICATION_SEARCH_SUCCESS,
	NOTIFICATION_EDIT_PENDING,
	NOTIFICATION_EDIT_SUCCESS,
	NOTIFICATION_EDIT_FAILED,
	NOTIFICATION_EDIT_DATE_PENDING,
	NOTIFICATION_EDIT_DATE_SUCCESS,
	NOTIFICATION_EDIT_DATE_FAILED
} from '../../util/constant';
import api from '../../util/api';
import appURLConstant from '../../util/appURLConstants';
import _ from 'lodash';


export const notificationCreate = async (data, dispatch) => {
	try {
		dispatch({
			type: NOTIFICATION_CREATE_PENDING
		});
		const response = await api.post(
			`${appURLConstant.REACT_SAVE_NOTIFICATIONS_URL}`,
			data
		);

		response.data.orbitResponse.statusCode === 400 ||
		!_.isEmpty(response.data.orbitResponse.errorMessage)
			? dispatch({
					type: NOTIFICATION_CREATE_FAILED,
					payload:
						response.data.orbitResponse.statusCode === 400
							? _.includes(response.data.orbitResponse.body, 'already-exists') ? 'Notification already exists for provided agency'
								: 'Error coming on save notification'
							: !_.isEmpty(response.data.orbitResponse.errorMessage) && 'Error coming on save notification'
			})
			: 
				dispatch({
					type: NOTIFICATION_CREATE_SUCCESS,
					payload: response.data.orbitResponse.body
				});
	} catch (error) {
		dispatch({
			type: NOTIFICATION_CREATE_FAILED,
			payload: 'Error coming on save notification'
		});
	}
};

export const notificationUpdate = async (data, dispatch) => {
	try {
		dispatch({
			type: NOTIFICATION_UPDATE_PENDING
		});
		const response = await api.post(
			`${appURLConstant.REACT_SAVE_NOTIFICATIONS_URL}`,
			data
		);

		response.data.orbitResponse.statusCode === 400 ||
		!_.isEmpty(response.data.orbitResponse.errorMessage)
			? dispatch({
					type: NOTIFICATION_UPDATE_FAILED,
					payload: 'error'
				})
			: 
				dispatch({
					type: NOTIFICATION_UPDATE_SUCCESS,
					payload: response.data.orbitResponse.body
				});
	} catch (error) {
		dispatch({
			type: NOTIFICATION_CREATE_FAILED,
			payload: error
		});
	}
};

export const notificationEdit = async (data, dispatch) => {
	try {
		dispatch({
			type: NOTIFICATION_EDIT_PENDING
		});
		const response = await api.post(
			`${appURLConstant.REACT_UPDATE_NOTIFICATIONS_URL}`,
			data
		);

		response.data.orbitResponse.statusCode === 400 ||
		response.data.orbitResponse === '' ||
		!_.isEmpty(response.data.orbitResponse.errorMessage)
			? dispatch({
					type: NOTIFICATION_EDIT_FAILED,
					payload: 'error'
				})
			: //response.data.orbitResponse.body;
				dispatch({
					type: NOTIFICATION_EDIT_SUCCESS,
					payload: response.data.orbitResponse.body
				});
	} catch (error) {
		dispatch({
			type: NOTIFICATION_EDIT_FAILED,
			payload: error
		});
	}
};

export const notificationEditDate = async (data, dispatch) => {
	try {
		dispatch({
			type: NOTIFICATION_EDIT_DATE_PENDING
		});
		const response = await api.post(
			`${appURLConstant.REACT_UPDATE_NOTIFICATIONS_URL}`,
			data
		);

		response.data.orbitResponse.statusCode === 400 ||
		response.data.orbitResponse === '' ||
		!_.isEmpty(response.data.orbitResponse.errorMessage)
			? dispatch({
					type: NOTIFICATION_EDIT_DATE_FAILED,
					payload: 'error'
				})
			: //response.data.orbitResponse.body;
				dispatch({
					type: NOTIFICATION_EDIT_DATE_SUCCESS,
					payload: response.data.orbitResponse.body
				});
	} catch (error) {
		dispatch({
			type: NOTIFICATION_EDIT_DATE_FAILED,
			payload: error
		});
	}
};

export const updateRoutesList = (data, dispatch) => {
	try {
		dispatch({
			type: UPDATE_ROUTES_LIST_PENDING
		});
		dispatch({
			type: UPDATE_ROUTES_LIST_SUCCESS,
			payload: data
		});
	} catch (error) {
		dispatch({
			type: UPDATE_ROUTES_LIST_FAILED,
			payload: error
		});
	}
};

export const emptyEditNotification = ( dispatch) => {
	try {
		dispatch({
			type: NOTIFICATION_EDIT_PENDING
		});
		dispatch({
			type: NOTIFICATION_EDIT_SUCCESS,
			payload: {}
		});
	} catch (error) {
		dispatch({
			type: NOTIFICATION_EDIT_FAILED,
			payload: error
		});
	}
};


export const notificationFetch = async (dispatch) => {
	try {
		dispatch({
			type: NOTIFICATION_FETCH_PENDING
		});
		const response = await api.fetch(appURLConstant.REACT_GET_NOTIFICATIONS);

		const results =
			response.data.orbitResponse !== '' ||
			!_.isEmpty(response.data.orbitResponse.body)
				? response.data.orbitResponse.body
				: [];

		response.data.orbitResponse.statusCode === 400 ||
		!_.isEmpty(response.data.orbitResponse.errorMessage)
			? dispatch({
					type: NOTIFICATION_FETCH_FAILED,
					payload: 'error'
				})
			: dispatch({
					type: NOTIFICATION_FETCH_SUCCESS,
					payload: results
				
				});

		// dispatch({
		// 	type: NOTIFICATION_FETCH_SUCCESS,
		// 	//payload: results
		// 	payload: data
		// });
	} catch (error) {
		dispatch({
			type: NOTIFICATION_FETCH_FAILED,
			payload: error
		});
	}
};

export const notificationFetchById = async (id, dispatch) => {
	try {
		dispatch({
			type: NOTIFICATION_FETCH_BY_ID_PENDING
		});
		const response = await api.fetch(
			`${appURLConstant.REACT_GET_NOTIFICATIONS}/${id}`
		);

		const results =
			response.data.orbitResponse !== '' ||
			!_.isEmpty(response.data.orbitResponse.body)
				? response.data.orbitResponse.body
				: [];

		response.data.orbitResponse.statusCode === 400 ||
		!_.isEmpty(response.data.orbitResponse.errorMessage)
			? dispatch({
					type: NOTIFICATION_FETCH_BY_ID_FAILED,
					payload: 'error'
				})
			:
			dispatch({
					type: NOTIFICATION_FETCH_BY_ID_SUCCESS,
					payload: results
				});
	} catch (error) {
		dispatch({
			type: NOTIFICATION_FETCH_BY_ID_FAILED,
			payload: error
		});
	}
};

export const subscriptionFetch = async (dispatch) => {
	try {
		dispatch({
			type: SUBSCRIPTION_FETCH_PENDING
		});
		const response = await api.post(appURLConstant.REACT_GET_SUBSCRIPTIONS);
	
		const results =
			response.data.orbitResponse.statusCode === 400
				? []
				: response.data.orbitResponse.body;
		dispatch({
			type: SUBSCRIPTION_FETCH_SUCCESS,
			//payload: []
			payload: results
		});
	} catch (error) {
		dispatch({
			type: SUBSCRIPTION_FETCH_FAILED,
			payload: error.message
		});
	}
};

export const notificationDelete = async (data, dispatch) => {
	try {
		dispatch({
			type: NOTIFICATION_DELETE_PENDING
		});
		const response = await api.post(
			`${appURLConstant.REACT_DELETE_NOTIFICATIONS_URL}`,
			data
		);

		response.data.orbitResponse.statusCode === 400 ||
		!_.isEmpty(response.data.orbitResponse.errorMessage)
			? dispatch({
					type: NOTIFICATION_DELETE_FAILED,
					payload:
						response.data.orbitResponse.statusCode === 400
							? response.data.orbitResponse.body
							: 'error'
				})
			: //response.data.orbitResponse.body;
				dispatch({
					type: NOTIFICATION_DELETE_SUCCESS,
					payload: response.data.orbitResponse.body
				});
	} catch (error) {
		dispatch({
			type: NOTIFICATION_DELETE_FAILED,
			payload: error
		});
	}
};

export const notificationSearch = async (data, dispatch) => {
	try {
		dispatch({
			type: NOTIFICATION_SEARCH_PENDING
		});
		const response = await api.post(
			`${appURLConstant.REACT_SEARCH_NOTIFICATIONS_URL}`,
			data
		);

		response.data.orbitResponse.statusCode === 400 ||
			response.data.orbitResponse === '' ||
			_.isEmpty(response.data.orbitResponse.body)||
		!_.isEmpty(response.data.orbitResponse.errorMessage)
			? dispatch({
					type: NOTIFICATION_SEARCH_FAILED,
					payload:
						response.data.orbitResponse.statusCode === 400 &&
								'No Alert Found'
				})
			: 
				dispatch({
					type: NOTIFICATION_SEARCH_SUCCESS,
					payload: response.data.orbitResponse.body
				});
	} catch (error) {
		dispatch({
			type: NOTIFICATION_SEARCH_FAILED,
			payload: "No Alert Found"
		});
	}
};
