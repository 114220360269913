import {
	NOTIFICATION_CREATE_PENDING,
	NOTIFICATION_CREATE_SUCCESS,
	NOTIFICATION_CREATE_FAILED,
	NOTIFICATION_EDIT_PENDING,
	NOTIFICATION_EDIT_SUCCESS,
	NOTIFICATION_EDIT_FAILED,
	NOTIFICATION_FETCH_PENDING,
	NOTIFICATION_FETCH_SUCCESS,
	NOTIFICATION_FETCH_FAILED,
	SUBSCRIPTION_FETCH_PENDING,
	SUBSCRIPTION_FETCH_SUCCESS,
	SUBSCRIPTION_FETCH_FAILED,
	UPDATE_ROUTES_LIST_PENDING,
	UPDATE_ROUTES_LIST_SUCCESS,
	UPDATE_ROUTES_LIST_FAILED,
	NOTIFICATION_UPDATE_PENDING,
	NOTIFICATION_UPDATE_FAILED,
	NOTIFICATION_UPDATE_SUCCESS,
	NOTIFICATION_FETCH_BY_ID_PENDING,
	NOTIFICATION_FETCH_BY_ID_SUCCESS,
	NOTIFICATION_FETCH_BY_ID_FAILED,
	NOTIFICATION_DELETE_SUCCESS,
	NOTIFICATION_DELETE_PENDING,
	NOTIFICATION_DELETE_FAILED,
	NOTIFICATION_SEARCH_PENDING,
	NOTIFICATION_SEARCH_FAILED,
	NOTIFICATION_SEARCH_SUCCESS,
	NOTIFICATION_EDIT_DATE_PENDING,
	NOTIFICATION_EDIT_DATE_SUCCESS,
	NOTIFICATION_EDIT_DATE_FAILED
} from '../../util/constant';


const initialState = {
	notifications: [],
	error: {},
	saveError: {},
	isLoading: false,
	editNotificationData: {},
	subscriptions: [],
	sentRoutesList: [],
	isNotificationsLoading: false,
	saveNotifications: {},
	updateNotifications: {},
	updateError: {},
	viewNotifications: [],
	fetchError: {},
	viewNotificationsById: [],
	fetchViewError: {},
	viewByIdLoading: false,
	isViewLoading: false,
	deleteNotifications: {},
	isAlertDeleteLoading: false,
	deleteError: {},
	isSearchLoading: false,
	searchError: {},
	searchData: [],
	editError: {},
	isEditLoading: false,
	editNotifications: {},
	onEditDate: '',
	editDateError: {}
};

const notificationReducer = (state = initialState, action) => {
	switch (action.type) {
		case NOTIFICATION_CREATE_PENDING:
			return {
				...state,
				isNotificationsLoading: true,
				saveError: {},
				saveNotifications:{}
			};
		case NOTIFICATION_CREATE_SUCCESS:
			return {
				...state,
				isNotificationsLoading: false,
				saveNotifications: action.payload
			};
		case NOTIFICATION_CREATE_FAILED:
			return {
				...state,
				isNotificationsLoading: false,
				saveError: action.payload
			};
		case NOTIFICATION_UPDATE_PENDING:
			return {
				...state,
				isNotificationsLoading: true
			};
		case NOTIFICATION_UPDATE_SUCCESS:
			return {
				...state,
				isNotificationsLoading: false,
				updateError: {},
				updateNotifications: action.payload
			};
		case NOTIFICATION_UPDATE_FAILED:
			return {
				...state,
				isNotificationsLoading: false,
				updateError: action.payload
			};
		case NOTIFICATION_EDIT_PENDING:
			return {
				...state,
				isEditLoading: true,
				editError: {},
				editNotifications: {}
			};
		case NOTIFICATION_EDIT_SUCCESS:
			return {
				...state,
				isEditLoading: false,
				editNotifications: action.payload
			};
		case NOTIFICATION_EDIT_FAILED:
			return {
				...state,
				isEditLoading: false,
				editError: action.payload
			};
		case NOTIFICATION_EDIT_DATE_PENDING:
			return {
				...state,
				isEditLoading: true,
				onEditDate: ''
			};
		case NOTIFICATION_EDIT_DATE_SUCCESS:
			return {
				...state,
				isEditLoading: false,
				editDateError: {},
				onEditDate: action.payload
			};
		case NOTIFICATION_EDIT_DATE_FAILED:
			return {
				...state,
				isEditLoading: false,
				editDateError: action.payload
			};

		case NOTIFICATION_DELETE_PENDING:
			return {
				...state,
				deleteError: {},
				deleteNotifications: {},
				isAlertDeleteLoading: true
			};
		case NOTIFICATION_DELETE_SUCCESS:
			return {
				...state,
				isAlertDeleteLoading: false,
				deleteNotifications: action.payload
			};
		case NOTIFICATION_DELETE_FAILED:
			return {
				...state,
				isAlertDeleteLoading: false,
				deleteError: action.payload
			};
		case NOTIFICATION_SEARCH_PENDING:
			return {
				...state,
				searchError: {},
				searchData: [],
				isSearchLoading: true
			};
		case NOTIFICATION_SEARCH_SUCCESS:
			return {
				...state,
				isSearchLoading: false,
				searchData: action.payload
			};
		case NOTIFICATION_SEARCH_FAILED:
			return {
				...state,
				isSearchLoading: false,
				searchError: action.payload
			};
		case UPDATE_ROUTES_LIST_PENDING:
			return {
				...state,
				sentRoutesList: [],
				isLoading: true
			};
		case UPDATE_ROUTES_LIST_SUCCESS:
			return {
				...state,
				isLoading: false,
				sentRoutesList: action.payload
				
			};
		case UPDATE_ROUTES_LIST_FAILED:
			return {
				...state,
				isLoading: false,
				error: action.payload
			};
		case NOTIFICATION_FETCH_PENDING:
			return {
				...state,
				isViewLoading: true
			};
		case NOTIFICATION_FETCH_SUCCESS:
			return {
				...state,
				isViewLoading: false,
				onEditDate: '',
				updateNotifications: {},
				saveNotifications:{},
				viewNotifications: action.payload
			};
		case NOTIFICATION_FETCH_FAILED:
			return {
				...state,
				isViewLoading: false,
				fetchError: action.payload
			};
		case NOTIFICATION_FETCH_BY_ID_PENDING:
			return {
				...state,
				viewNotificationsById: [],
				fetchViewError:{},
				viewByIdLoading: true
			};
		case NOTIFICATION_FETCH_BY_ID_SUCCESS:
			return {
				...state,
				viewByIdLoading: false,
				viewNotificationsById: action.payload
			};
		case NOTIFICATION_FETCH_BY_ID_FAILED:
			return {
				...state,
				viewByIdLoading: false,
				fetchViewError: action.payload
			};
		case SUBSCRIPTION_FETCH_PENDING:
			return {
				...state,
				isLoading: true
			};
		case SUBSCRIPTION_FETCH_SUCCESS:
			return {
				...state,
				isLoading: false,
				subscriptions: action.payload
			};
		case SUBSCRIPTION_FETCH_FAILED:
			return {
				...state,
				isLoading: false,
				error: action.payload
			};
		default:
			return state;
	}
};

export default notificationReducer;
