
import React from 'react';
import { Label } from 'reactstrap';
import _ from 'lodash';

const MultipleRouteList = ({
    routeDetails,
    onRoutesChange,
    selectedRoutes,
    agencyId
}) => {
 
    return (       
    
                        <li
                            className="list-group-item px-2 py-2 border-bottom-0" 
        >
                        <Label check>
                           
                            <input
                                type="checkbox"
                                checked={ _.find(selectedRoutes[agencyId], (a) => a === routeDetails.routeId) === routeDetails.routeId}
                                name={agencyId}
                                value={routeDetails.routeId}
                                onChange={onRoutesChange}
                                className="mr-2"
                            />
                            {routeDetails.routeLongName}
                            <span className="checkBoxMark"></span>
                        </Label>
                
                        </li>
       
    );
};

export default MultipleRouteList;