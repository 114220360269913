import {
    CS_SEARCH_PENDING,
    CS_SEARCH_FAILED,
    CS_SEARCH_SUCCESS,
} from "../../util/constant";
import { subDays } from "date-fns";

const initialState = {
    listData: [],
    searchError: {},
    isSearchLoading: false,
    searchInputValues: {
        fieldEdited: "",
        searchFields: {
            orderNumber: "",
            ticketId: "",
            customerName: "",
            emailAddress: "",
            wellsFargoReferenceNumber: "",
            totalOrderAmount: "",
        },
        fromDate: subDays(new Date(), 90),
        toDate: new Date(),
    },
};
const csSearchReducer = (state = initialState, action) => {
    switch (action.type) {
        case CS_SEARCH_PENDING:
            return {
                ...state,
                isSearchLoading: true,
                searchError: {},
                listData: [],
            };
        case CS_SEARCH_SUCCESS:
            return {
                ...state,
                isSearchLoading: false,
                listData: action.payload,
            };
        case CS_SEARCH_FAILED:
            return {
                ...state,
                isSearchLoading: false,
                searchError: action.payload,
            };
        case "RESET_CS_LIST_SUCCESS":
            return {
                ...state,
                isSearchLoading: false,
                listData: [],
            };
        case "SET_SEARCH_INPUT_VALUES":
            return {
                ...state,

                searchInputValues: action.payload,
            };
        default:
            return state;
    }
};

export default csSearchReducer;
