import React, { useEffect } from "react";
import { Container, Row, Col, Button, Spinner } from "reactstrap";
import { useState } from "react";
import MainWrapper from "../../../MainWrapper";
import EditInformation from "../edit/EditInformation";
import OrderInformation from "./OrderInformation";
import CustomerInformation from "./CustomerInformation";
import TicketInformation from "./TicketInformation";
import NoteHistory from "./NoteHistory";
import { connect, useSelector } from "react-redux";
import {
  getCustomerInformation,
  getNoteHistory,
  fetchStates,
  updateNoteHistory
} from "../../../../store/actions/CS_ViewOrderAction";
import _ from "lodash";
import { format } from "date-fns";
import {
  getTicketRedemptions,
  ticketRedempInsert,
  setRedemptionsTicketInfo,
} from "../../../../store/actions/TicketRedemAction";
import { useHistory } from "react-router-dom";
import AllSetPopup from "../modal/AllSetPopup";
import WarningPopup from "../modal/WarningPopup";
import AddNotesPopup from "../modal/AddNotesPopup";
import useUserAccessDetails from '../../../../util/hooks/UserAccessDetails';

const ViewOrder = ({
  getTicketRedemptions,
  getNoteHistory,
  getCustomerDetails,
  ticketRedempInsert,
  setRedemptionsTicketInfo,
  fetchStates,
  updateNoteHistory
}) => {
  const [orderInformation, setOrderInformation] = useState({});
  const [customerinformation, setCustomerInformation] = useState({});
  const [showWarning, setShowWarning] = useState(false);
  const [noteHistoryDetail, setNoteHistoryDetail] = useState([]);
  const [isAddNote, setIsAddNote] = useState(false);
  const [isAllSetPopup, setAllSetPopup] = useState(false);
  const [isEmailSentPopup, setEmailSentPopup] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [onBackButtonClick, setonBackButtonClick] = useState(false);
  const [onAddNoteClick, setOnAddNoteClick] = useState(false);
  const [showEmailErrorPopup, setShowEmailErrorPopup] = useState(false);
  const [showAddNotesErrorPopup, setShowAddNotesErrorPopup] = useState(false);
  const [stateValue, setStateValue] = useState("");
  const [ticketRedeemData, setTicketRedeemData] = useState([]);
  const [automatedNotes, setAutomatedNotes] = useState(false);
  const [resendTicketsEmail, setResendTicketsEmail] = useState({});
  const [orderAccess, setOrderAccess]=useState({})
  const {
    viewOrderData,
    noteHistoryUpdated,
    customerInformation,
    isViewOrderLoading,
    noteHistory,
    emailRecieptSent,
    errorInSendingEmail,
    errorUpdatingNotes,
    stateData,
    viewNoteHistoryError,
    viewCustomerInfoError,
    errorState, 
    viewOrderError
  } = useSelector((state) => state.csViewOrderReducer);
  const { ticketsData, isLoading, ticketInfoData, activeButton, viewTicketRedeemError } = useSelector(
    (state) => state.ticketRedemReducer
  );
  const { currentUser } = useSelector((state) => state.auth);
  const userAccessDetails = useUserAccessDetails();
  const history = useHistory();
  useEffect(() => {
    if (!_.isEmpty(userAccessDetails)) {
      setOrderAccess(_.get(userAccessDetails, "csViewDetails", {}))
    }
  }, []);
  useEffect(() => {
    if (!_.isEmpty(viewOrderData)) {
      const data = {
        searchText: !_.isEmpty(viewOrderData) && _.get(viewOrderData, "order_id", ""),
        searchBy: "Order Id",
        profile: false,
      };
      getTicketRedemptions(data);
    }
  }, [viewOrderData]);

  useEffect(() => {
    if (!_.isEmpty(viewTicketRedeemError)) {
      setTicketRedeemData([])
    }
  }, [viewTicketRedeemError]);
  
  useEffect(() => {
    if (!_.isEmpty(ticketsData)) {
      setTicketRedeemData(ticketsData)
    }
  }, [ticketsData]);
  useEffect(() => {
    if (!_.isEmpty(viewOrderData)) {
      const data = {
        type: viewOrderData.request_source,
        id: viewOrderData.orchesration_transaction_id,
      };
      getCustomerDetails(data);
    }
  }, [viewOrderData]);

  useEffect(() => {
    if (errorInSendingEmail && _.get(orderAccess, "automatedNotesAccess", true)) {
      setShowEmailErrorPopup(true);
      const userName = _.get(currentUser, "signInUserSession.idToken.payload", "");
      const name = !_.isEmpty(userName.given_name) ? userName.given_name : "" + " " + !_.isEmpty(userName.family_name) ? userName.family_name : "";
      const noteData = {
        noteText: `${name} on  ${format(new Date(), "MM/dd/yyyy")} at ${format(new Date(), "HH: mm")} error coming on resent the tickets/receipt for Order number ${_.get(viewOrderData, "order_id", "")} to ${resendTicketsEmail}.`,
        noteCategory: "ResendTickets"
      }; 
      onAutomatedNotes(noteData)
    }
  }, [errorInSendingEmail]);
  useEffect(() => {
    if (errorUpdatingNotes) {
      setShowAddNotesErrorPopup(true);
    }
  }, [errorUpdatingNotes]);

  useEffect(() => {
    if (!automatedNotes && _.get(orderAccess, "viewNotesAccess", true)) {
      const data = {
        id: _.get(viewOrderData, "order_id", ""),
        type: "order",
      };
      getNoteHistory(data);
    }
  }, [viewOrderData]);

  useEffect(() => {
    if (noteHistoryUpdated) {
     if(!automatedNotes) setAllSetPopup(true);
      const data = {
        id: _.get(viewOrderData,"order_id",""),
        type: "order",
      };
      getNoteHistory(data);
    }
    
  }, [noteHistoryUpdated]);

  useEffect(() => {
    if (emailRecieptSent && _.get(orderAccess, "automatedNotesAccess", true)) {
      setEmailSentPopup(true);
      const userName = _.get(currentUser, "signInUserSession.idToken.payload", "");
      const name = !_.isEmpty(userName.given_name) ? userName.given_name : "" + " " + !_.isEmpty(userName.family_name) ? userName.family_name : "";
      const noteData = {
        noteText: `${name} on  ${format(new Date(), "MM/dd/yyyy")} at ${format(new Date(), "HH: mm")} resent the tickets/receipt for Order number ${_.get(viewOrderData, "order_id", "")} to ${resendTicketsEmail}.`,
        noteCategory: "ResendTickets"
      };
      onAutomatedNotes(noteData)
    }
  }, [emailRecieptSent]);

  useEffect(() => {
    if (noteHistory) setNoteHistoryDetail(noteHistory);
  }, [noteHistory]);

  useEffect(() => {
    if (!_.isEmpty(viewNoteHistoryError)) setNoteHistoryDetail([]);
  }, [viewNoteHistoryError]);
  
  useEffect(() => {
    if (!_.isEmpty(customerInformation)) {
      setCustomerInformation(customerInformation);
      const code = _.get(customerInformation, "country")
      fetchStates(code)
    }
  }, [customerInformation]);

  useEffect(() => {
    if (!_.isEmpty(viewCustomerInfoError)) {
      setCustomerInformation([]);
    }
  }, [viewCustomerInfoError]);

  useEffect(() => {
    if (!_.isEmpty(stateData)) {
      const stateCode = _.get(customerInformation, "province_code")
      const stateVal = _.find(_.filter(stateData, (s) => s.value === stateCode))
      setStateValue(_.get(stateVal,"label"))
    }
  }, [stateData]); 
  useEffect(() => {
    if (!_.isEmpty(errorState)) {
      setStateValue("")
    }
  }, [errorState]);

  useEffect(() => {
    const orderInformation = {
      order_id: viewOrderData.order_id,
      tickets_quantity: viewOrderData.tickets_quantity,
      purchase_date: viewOrderData.purchase_date,
      order_status: viewOrderData.order_status,
      total_amount: viewOrderData.total_amount,
      transactionId: viewOrderData.orchesration_transaction_id,
    };
    setOrderInformation(orderInformation);
  }, [viewOrderData]);

  useEffect(() => {
    if (_.isEmpty(viewOrderData)) {
      history.push("/csportal");
    }
  }, [viewOrderData]);

  const addNotes = async (notes) => {
    if (!_.isEmpty(_.get(viewOrderData, "order_id", ""))) {
      const response = await fetch("https://geolocation-db.com/json/");
      const data = await response.json();
      const userName = _.get(currentUser, "username", "");
      const noteData = {
        customerId: _.get(viewOrderData, "customer_id", ""),
        orderId: _.get(viewOrderData, "order_id", ""),
        ticketId: notes.ticketId,
        agentId: "",
        noteText: notes.noteText,
        browserIp: data.IPv4,
        noteCategory: notes.noteCategory,
        userEmail: userName,
      };
      updateNoteHistory(noteData);
    }
  }
  const onAddNotes = (notes) => {
    setAutomatedNotes(false)
    addNotes(notes)
  }
  const onAutomatedNotes = (notes) => {
    setAutomatedNotes(true);
    addNotes(notes);
  }
  

  useEffect(() => {
    if (_.get(orderAccess, "automatedNotesAccess", true)){
      const userName = _.get(currentUser, "signInUserSession.idToken.payload", "");
      const name = !_.isEmpty(userName.given_name) ? userName.given_name : "" + " " + userName.family_name ? userName.family_name : "";
      const notes = {
        noteText: `${name} on ${format(new Date(), "MM/dd/yyyy")} at ${format(new Date(), "HH: mm")} viewed Order number ${_.get(viewOrderData, "order_id", "")}`,
        noteCategory: "OrderViewed"
      };
      onAutomatedNotes(notes)
    }
   
  }, [viewOrderData])

  useEffect(() => {
    if (!_.isEmpty(viewOrderError) && _.get(orderAccess, "automatedNotesAccess", true)) {
      const userName = _.get(currentUser, "signInUserSession.idToken.payload", "");
      const name = !_.isEmpty(userName.given_name) ? userName.given_name : "" + " " + !_.isEmpty(userName.family_name) ? userName.family_name : "";
      const notes = {
        noteText: `${name} on ${format(new Date(), "MM/dd/yyyy")} at ${format(new Date(), "HH: mm")} not viewed Order due to error coming on view order`,
        noteCategory: "OrderViewed"
      };
      setAutomatedNotes(true);
      addNotes(notes);
    }
  }, [viewOrderError])

  const handleBackToResults = () => {
    setonBackButtonClick(true);
    if (_.isEmpty(activeButton)) {
      history.push("/csportal");
    } else {
      setShowWarning(true);
    }
  };
  const onSavePopup = () => {
    updateTicket(ticketInfoData);
    setShowWarning(!showWarning);
  };
  const onWarningClose = () => {
    setShowWarning(!showWarning);
    setRedemptionsTicketInfo([], "");
    if (onBackButtonClick) history.push("/csportal");
    if (onAddNoteClick) setIsAddNote(!isAddNote);
  };

  const updateTicket = (redemptionsData) => {
    const redemeedData = redemptionsData
      .filter((data) => data.added)
      .map((data) => {
        return {
          id: data.id,
          email: data.email,
          redeemedTicketCount: data.redeemed_ticket_count,
          added:
            data.added === "plus" ? true : data.added === "minus" ? false : "",
        };
      });
    const updatedData = {
      redemption: true,
      data: redemeedData,
    };
    if (!_.isEmpty(redemeedData)) {
      ticketRedempInsert(updatedData);
    }
    window.scrollTo(0, 0);
  };

  const onAddNoteButton = () => {
    setOnAddNoteClick(true)
    if (_.isEmpty(activeButton)) {
      setIsAddNote(!isAddNote)
    }
    else {
      setShowWarning(true);
    }
  }

  return (
    <MainWrapper>
      {isEdit ? (
        <EditInformation backClick={() => setIsEdit(!isEdit)} />
      ) : (
          <>
            {" "}
            {(isViewOrderLoading || isLoading) && (
              <div className="loader">
                <div className="spinner">
                  <Spinner animation="border" variant="primary" />
                </div>
              </div>
            )}
            {(isAllSetPopup && !automatedNotes) ? (
              <AllSetPopup
                modalOpen={isAllSetPopup}
                subText={"Note has been saved successfully."}
                handleClose={() => setAllSetPopup(!isAllSetPopup)}
              />
            ):null}
            {showWarning && (
              <WarningPopup
                modalOpen={showWarning}
                handleClose={() => onWarningClose()}
                onSave={() => onSavePopup()}
              />
            )}
            {isEmailSentPopup && (
              <AllSetPopup
                modalOpen={isEmailSentPopup}
                subText={
                  "Your email ticket(s) & receipt has been sent successfully."
                }
                handleClose={() => setEmailSentPopup(!isEmailSentPopup)}
              />
            )}
            {showEmailErrorPopup  && (
              <AllSetPopup
                modalOpen={showEmailErrorPopup}
                isError={true}
                subText={"Your email ticket(s) & receipt could not be sent."}
                handleClose={() => setShowEmailErrorPopup(!showEmailErrorPopup)}
              />
            )}
            {showAddNotesErrorPopup  && (
              <AllSetPopup
                modalOpen={showAddNotesErrorPopup}
                isError={true}
                subText={"Note could not be saved."}
                handleClose={() =>
                  setShowAddNotesErrorPopup(!showAddNotesErrorPopup)
                }
              />
            )}
            <Container className="mt-2 pt-2">
              <span className="font24">
                View Order - {!_.isEmpty(viewOrderData) && _.get(viewOrderData, "order_id", "")}
              </span>
              <Row className="mt-2">
                <Col xs="6">
                  <OrderInformation
                    orderInformation={orderInformation}
                    setShowWarning={setShowWarning}
                    setResendTicketsEmail={setResendTicketsEmail}
                  />
                </Col>

                <Col xs="6">
                  <CustomerInformation
                    editClick={() => setIsEdit(!isEdit)}
                    info={customerinformation}
                    stateValue={stateValue}
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <TicketInformation
                    ticketsData={ticketRedeemData}
                    orderId={!_.isEmpty(viewOrderData) && _.get(viewOrderData, "order_id", "")}
                    noteHistoryUpdated={noteHistoryUpdated}
                    updateTicket={updateTicket}
                    onAutomatedNotes={onAutomatedNotes}
                    addNotes={onAddNotes}
                    setAutomatedNotes={setAutomatedNotes}
                  />
                </Col>
              </Row>

              <Row className="mt-4">
                <Col>
                  <NoteHistory noteHistory={noteHistoryDetail} />
                </Col>
              </Row>

              <Row className="mt-4">
                <Col className="text-center">
                  <Button
                    className="px-4 py-2-75 text-uppercase btn-pill"
                    color="primary"
                    onClick={handleBackToResults}
                  >
                    <span className="font16-b">Back to results</span>
                  </Button>
                  <Button
                    className="px-4 py-2-75 mx-4 btn-pill text-uppercase"
                    color="primary"
                    onClick={onAddNoteButton}
                    disabled={!_.get(orderAccess, "addNotesAccess", "")}
                >
                  <span className="font16-b">Add Notes</span>
                </Button>
                </Col>
              </Row>
            </Container>
            {isAddNote && (
              <AddNotesPopup
                isAllOverNotes={true}
                modal={isAddNote}
                addNotes={onAddNotes}
                setAutomatedNotes={setAutomatedNotes}
                //orderId={!_.isEmpty(viewOrderData) && _.get(viewOrderData, "order_id","") }
                //customerId={!_.isEmpty(viewOrderData) && _.get(viewOrderData, "customer_id","")}
                handleClose={() => setIsAddNote(!isAddNote)}
              />
            )}
          </>
        )}{" "}
    </MainWrapper>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTicketRedemptions: (orderId) => getTicketRedemptions(orderId, dispatch),
    getNoteHistory: (data) => getNoteHistory(data, dispatch),
    getCustomerDetails: (data) => getCustomerInformation(data, dispatch),
    ticketRedempInsert: (data) => ticketRedempInsert(data, dispatch),
    setRedemptionsTicketInfo: (data, isActive) =>
    setRedemptionsTicketInfo(data, isActive, dispatch),
    fetchStates: (code) => fetchStates(code, dispatch),
    updateNoteHistory: (notes) => updateNoteHistory(notes, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(ViewOrder);
