import React from "react";
import { Button, Card, CardBody, CardTitle } from "reactstrap";
import AlertAdminIcon from "../../../assets/images/alertAdmin.svg";

import { useHistory } from "react-router-dom";
const AlertAdminCard = () => {
  const history = useHistory();
  const handleClick = () => {
    history.push(`/notification`);
  };
  return (
    <Card className="text-center card-box-shadow-8">
      <CardBody className="p-6">
        <img className="m-2" src={AlertAdminIcon} alt="alert_admin_icon" />
        <CardTitle>
          <span className="font34">Alerts Admin</span>
        </CardTitle>
        <Button
          color="primary"
          className="px-4 py-2-75 text-uppercase btn-pill"
          onClick={() => handleClick()}
        >
          <span className="p-4 font16-b">GO TO ALERTS ADMIN</span>
        </Button>
      </CardBody>
    </Card>
  );
};

export default AlertAdminCard;
