import React from 'react';
import { Button, Input } from 'reactstrap';

const Pagination = ({
	canPreviousPage,
	canNextPage,
	pageCount,
	gotoPage,
	nextPage,
	previousPage,
	pageIndex
}) => {
	return (
		<div className="footerPagination">
			<Button
				className="mr-2"
				onClick={() => gotoPage(0)}
				disabled={!canPreviousPage}
			>
				First
			</Button>
			<Button
				className="mr-2"
				onClick={() => previousPage()}
				disabled={!canPreviousPage}
			>
				Previous
			</Button>

			<Input
				type="number"
				id="gotopage"
				bsSize="lg"
				value={pageIndex + 1}
				readOnly={true}
				min={1}
				max={pageCount}
				className="mr-2"
			/>

			<Button
				className="mr-2"
				onClick={() => nextPage()}
				disabled={!canNextPage}
			>
				Next
			</Button>
			<Button
				className="mr-2"
				onClick={() => gotoPage(pageCount - 1)}
				disabled={!canNextPage}
			>
				Last
			</Button>
		</div>
	);
};

export default Pagination;
