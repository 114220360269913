import { combineReducers } from "redux";
import authReducer from "./AuthReducer";
import agencyReducer from "./AgencyReducer";
import viewGtfsReducer from "./ViewGtfsReducer";
import notificationReducer from "./NotificationReducer";
import ticketRedemReducer from "./TicketRedemReducer";
import csSearchReducer from "./CS_SearchReducer";
import csViewOrderReducer from "./CS_ViewOrderReducer";

export default combineReducers({
  auth: authReducer,
  agencyReducer: agencyReducer,
  viewGtfsReducer: viewGtfsReducer,
  notificationReducer: notificationReducer,
  ticketRedemReducer: ticketRedemReducer,
  csSearchReducer: csSearchReducer,
  csViewOrderReducer: csViewOrderReducer,
});
