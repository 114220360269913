// Auth Constants

export const LOGIN_PENDING = 'LOGIN_PENDING';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const SESSION_UPDATE_PENDING = 'SESSION_UPDATE_PENDING';
export const SESSION_UPDATE_SUCCESS = 'SESSION_UPDATE_SUCCESS';
export const SESSION_UPDATE_FAILED = 'SESSION_UPDATE_FAILED';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILED = 'LOGOUT_FAILED';
export const LOGIN_USER_ACCESS_PENDING = 'LOGIN_USER_ACCESS_PENDING';
export const LOGIN_USER_ACCESS_SUCCESS = 'LOGIN_USER_ACCESS_SUCCESS';
export const LOGIN_USER_ACCESS_FAILED = 'LOGIN_USER_ACCESS_FAILED';

// Tickets Redemptions Constants

export const TICKET_REDEMP_INSERT_PENDING = 'TICKET_REDEMP_INSERT_PENDING';
export const TICKET_REDEMP_INSERT_FAILED = 'TICKET_REDEMP_INSERT_FAILED';
export const TICKET_REDEMP_INSERT_SUCCESS = 'TICKET_REDEMP_INSERT_SUCCESS';

export const TICKET_REDEMP_FETCH_PENDING = 'TICKET_REDEMP_FETCH_PENDING';
export const TICKET_REDEMP_FETCH_FAILED = 'TICKET_REDEMP_FETCH_FAILED';
export const TICKET_REDEMP_FETCH_SUCCESS = 'TICKET_REDEMP_FETCH_SUCCESS';

//View GTFS Menus
export const FETCH_GTFS_MENUS_PENDING = 'FETCH_GTFS_MENUS_PENDING';
export const FETCH_GTFS_MENUS_SUCCESS = 'FETCH_GTFS_MENUS_SUCCESS';
export const FETCH_GTFS_MENUS_FAILED = 'FETCH_GTFS_MENUS_FAILED';

//View Notifications Menus
export const FETCH_NOTIFICATION_MENUS_PENDING =
	'FETCH_NOTIFICATION_MENUS_PENDING';
export const FETCH_NOTIFICATION_MENUS_SUCCESS =
	'FETCH_NOTIFICATION_MENUS_SUCCESS';
export const FETCH_NOTIFICATION_MENUS_FAILED =
	'FETCH_NOTIFICATION_MENUS_FAILED';

//View GTFS Tables Constants

export const GTFS_TABLES_FETCH_PENDING = 'GTFS_TABLES_FETCH_PENDING';
export const GTFS_TABLES_FETCH_SUCCESS = 'GTFS_TABLES_FETCH_SUCCESS';
export const GTFS_TABLES_FETCH_FAILED = 'GTFS_TABLES_FETCH_FAILED';

// Product Fare Constants

export const PRODUCT_FARE_CREATE_PENDING = 'PRODUCT_FARE_CREATE_PENDING';
export const PRODUCT_FARE_CREATE_SUCCESS = 'PRODUCT_FARE_CREATE_SUCCESS';
export const PRODUCT_FARE_CREATE_FAILED = 'PRODUCT_FARE_CREATE_FAILED';

export const PRODUCT_FARE_EDIT_FETCH_PENDING =
	'PRODUCT_FARE_EDIT_FETCH_PENDING';
export const PRODUCT_FARE_EDIT_FETCH_SUCCESS =
	'PRODUCT_FARE_EDIT_FETCH_SUCCESS';
export const PRODUCT_FARE_EDIT_FETCH_FAILED = 'PRODUCT_FARE_EDIT_FETCH_FAILED';

export const PRODUCT_FARE_EDIT_PENDING = 'PRODUCT_FARE_EDIT_PENDING';
export const PRODUCT_FARE_EDIT_SUCCESS = 'PRODUCT_FARE_EDIT_SUCCESS';
export const PRODUCT_FARE_EDIT_FAILED = 'PRODUCT_FARE_EDIT_FAILED';

// Agency Constants

export const AGENCY_CREATE_PENDING = 'AGENCY_CREATE_PENDING';
export const AGENCY_CREATE_SUCCESS = 'AGENCY_CREATE_SUCCESS';
export const AGENCY_CREATE_FAILED = 'AGENCY_CREATE_FAILED';

export const AGENCY_EDIT_PENDING = 'AGENCY_EDIT_PENDING';
export const AGENCY_EDIT_SUCCESS = 'AGENCY_EDIT_SUCCESS';
export const AGENCY_EDIT_FAILED = 'AGENCY_EDIT_FAILED';

export const AGENCY_EDIT_FETCH_PENDING = 'AGENCY_EDIT_FETCH_PENDING';
export const AGENCY_EDIT_FETCH_SUCCESS = 'AGENCY_EDIT_FETCH_SUCCESS';
export const AGENCY_EDIT_FETCH_FAILED = 'AGENCY_EDIT_FETCH_FAILED';

// Routes Constants

export const ROUTES_CREATE_PENDING = 'ROUTES_CREATE_PENDING';
export const ROUTES_CREATE_SUCCESS = 'ROUTES_CREATE_SUCCESS';
export const ROUTES_CREATE_FAILED = 'ROUTES_CREATE_FAILED';

export const ROUTES_EDIT_PENDING = 'ROUTES_EDIT_PENDING';
export const ROUTES_EDIT_SUCCESS = 'ROUTES_EDIT_SUCCESS';
export const ROUTES_EDIT_FAILED = 'ROUTES_EDIT_FAILED';

export const ROUTES_EDIT_FETCH_PENDING = 'ROUTES_EDIT_FETCH_PENDING';
export const ROUTES_EDIT_FETCH_SUCCESS = 'ROUTES_EDIT_FETCH_SUCCESS';
export const ROUTES_EDIT_FETCH_FAILED = 'ROUTES_EDIT_FETCH_FAILED';

// Fare Rules Constants

export const FARE_RULES_CREATE_PENDING = 'FARE_RULES_CREATE_PENDING';
export const FARE_RULES_CREATE_SUCCESS = 'FARE_RULES_CREATE_SUCCESS';
export const FARE_RULES_CREATE_FAILED = 'FARE_RULES_CREATE_FAILED';

export const FARE_RULES_EDIT_PENDING = 'FARE_RULES_EDIT_PENDING';
export const FARE_RULES_EDIT_SUCCESS = 'FARE_RULES_EDIT_SUCCESS';
export const FARE_RULES_EDIT_FAILED = 'FARE_RULES_EDIT_FAILED';

export const FARE_RULES_EDIT_FETCH_PENDING = 'FARE_RULES_EDIT_FETCH_PENDING';
export const FARE_RULES_EDIT_FETCH_SUCCESS = 'FARE_RULES_EDIT_FETCH_SUCCESS';
export const FARE_RULES_EDIT_FETCH_FAILED = 'FARE_RULES_EDIT_FETCH_FAILED';

// Fare Attribute Constants

export const FARE_ATTRIBUTE_CREATE_PENDING = 'FARE_ATTRIBUTE_CREATE_PENDING';
export const FARE_ATTRIBUTE_CREATE_SUCCESS = 'FARE_ATTRIBUTE_CREATE_SUCCESS';
export const FARE_ATTRIBUTE_CREATE_FAILED = 'FARE_ATTRIBUTE_CREATE_FAILED';

export const FARE_ATTRIBUTE_EDIT_PENDING = 'FARE_ATTRIBUTE_EDIT_PENDING';
export const FARE_ATTRIBUTE_EDIT_SUCCESS = 'FARE_ATTRIBUTE_EDIT_SUCCESS';
export const FARE_ATTRIBUTE_EDIT_FAILED = 'FARE_ATTRIBUTE_EDIT_FAILED';

export const FARE_ATTRIBUTE_EDIT_FETCH_PENDING =
	'FARE_ATTRIBUTE_EDIT_FETCH_PENDING';
export const FARE_ATTRIBUTE_EDIT_FETCH_SUCCESS =
	'FARE_ATTRIBUTE_EDIT_FETCH_SUCCESS';
export const FARE_ATTRIBUTE_EDIT_FETCH_FAILED =
	'FARE_ATTRIBUTE_EDIT_FETCH_FAILED';

// Frequency Constants

export const FREQUENCY_CREATE_PENDING = 'FREQUENCY_CREATE_PENDING';
export const FREQUENCY_CREATE_SUCCESS = 'FREQUENCY_CREATE_SUCCESS';
export const FREQUENCY_CREATE_FAILED = 'FREQUENCY_CREATE_FAILED';

export const FREQUENCY_EDIT_PENDING = 'FREQUENCY_EDIT_PENDING';
export const FREQUENCY_EDIT_SUCCESS = 'FREQUENCY_EDIT_SUCCESS';
export const FREQUENCY_EDIT_FAILED = 'FREQUENCY_EDIT_FAILED';

export const FREQUENCY_EDIT_FETCH_PENDING = 'FREQUENCY_EDIT_FETCH_PENDING';
export const FREQUENCY_EDIT_FETCH_SUCCESS = 'FREQUENCY_EDIT_FETCH_SUCCESS';
export const FREQUENCY_EDIT_FETCH_FAILED = 'FREQUENCY_EDIT_FETCH_FAILED';

// Calender Constants

export const CALENDER_CREATE_PENDING = 'CALENDER_CREATE_PENDING';
export const CALENDER_CREATE_SUCCESS = 'CALENDER_CREATE_SUCCESS';
export const CALENDER_CREATE_FAILED = 'CALENDER_CREATE_FAILED';

export const CALENDER_EDIT_PENDING = 'CALENDER_EDIT_PENDING';
export const CALENDER_EDIT_SUCCESS = 'CALENDER_EDIT_SUCCESS';
export const CALENDER_EDIT_FAILED = 'CALENDER_EDIT_FAILED';

export const CALENDER_EDIT_FETCH_PENDING = 'CALENDER_EDIT_FETCH_PENDING';
export const CALENDER_EDIT_FETCH_SUCCESS = 'CALENDER_EDIT_FETCH_SUCCESS';
export const CALENDER_EDIT_FETCH_FAILED = 'CALENDER_EDIT_FETCH_FAILED';

// Notification Constants

export const NOTIFICATION_FETCH_PENDING = 'NOTIFICATION_FETCH_PENDING';
export const NOTIFICATION_FETCH_SUCCESS = 'NOTIFICATION_FETCH_SUCCESS';
export const NOTIFICATION_FETCH_FAILED = 'NOTIFICATION_FETCH_FAILED';

export const NOTIFICATION_CREATE_PENDING = 'NOTIFICATION_CREATE_PENDING';
export const NOTIFICATION_CREATE_SUCCESS = 'NOTIFICATION_CREATE_SUCCESS';
export const NOTIFICATION_CREATE_FAILED = 'NOTIFICATION_CREATE_FAILED';

export const NOTIFICATION_EDIT_PENDING = 'NOTIFICATION_EDIT_PENDING';
export const NOTIFICATION_EDIT_SUCCESS = 'NOTIFICATION_EDIT_SUCCESS';
export const NOTIFICATION_EDIT_FAILED = 'NOTIFICATION_EDIT_FAILED';

export const NOTIFICATION_FETCH_BY_ID_PENDING =
	'NOTIFICATION_FETCH_BY_ID_PENDING';
export const NOTIFICATION_FETCH_BY_ID_SUCCESS =
	'NOTIFICATION_FETCH_BY_ID_SUCCESS';
export const NOTIFICATION_FETCH_BY_ID_FAILED =
	'NOTIFICATION_FETCH_BY_ID_FAILED';

export const NOTIFICATION_DELETE_SUCCESS = 'NOTIFICATION_DELETE_SUCCESS';
export const NOTIFICATION_DELETE_PENDING = 'NOTIFICATION_DELETE_PENDING';
export const NOTIFICATION_DELETE_FAILED = 'NOTIFICATION_DELETE_FAILED';

export const SUBSCRIPTION_FETCH_PENDING = 'SUBSCRIPTION_FETCH_PENDING';
export const SUBSCRIPTION_FETCH_SUCCESS = 'SUBSCRIPTION_FETCH_SUCCESS';
export const SUBSCRIPTION_FETCH_FAILED = 'SUBSCRIPTION_FETCH_FAILED';

export const UPDATE_ROUTES_LIST_PENDING = 'UPDATE_ROUTES_LIST_PENDING';
export const UPDATE_ROUTES_LIST_SUCCESS = 'UPDATE_ROUTES_LIST_SUCCESS';
export const UPDATE_ROUTES_LIST_FAILED = 'UPDATE_ROUTES_LIST_FAILED';

export const NOTIFICATION_UPDATE_PENDING = 'NOTIFICATION_UPDATE_PENDING';
export const NOTIFICATION_UPDATE_FAILED = 'NOTIFICATION_UPDATE_FAILED';
export const NOTIFICATION_UPDATE_SUCCESS = 'NOTIFICATION_UPDATE_SUCCESS';

export const NOTIFICATION_SEARCH_PENDING = 'NOTIFICATION_SEARCH_PENDING';
export const NOTIFICATION_SEARCH_FAILED = 'NOTIFICATION_SEARCH_FAILED';
export const NOTIFICATION_SEARCH_SUCCESS = 'NOTIFICATION_SEARCH_SUCCESS';

export const NOTIFICATION_EDIT_DATE_PENDING = 'NOTIFICATION_EDIT_DATE_PENDING';
export const NOTIFICATION_EDIT_DATE_SUCCESS = 'NOTIFICATION_EDIT_DATE_SUCCESS';
export const NOTIFICATION_EDIT_DATE_FAILED = 'NOTIFICATION_EDIT_DATE_FAILED';

// Messages Constants

export const MESSAGES_CREATE_PENDING = 'MESSAGES_CREATE_PENDING';
export const MESSAGES_CREATE_SUCCESS = 'MESSAGES_CREATE_SUCCESS';
export const MESSAGES_CREATE_FAILED = 'MESSAGES_CREATE_FAILED';

export const MESSAGES_EDIT_PENDING = 'MESSAGES_EDIT_PENDING';
export const MESSAGES_EDIT_SUCCESS = 'MESSAGES_EDIT_SUCCESS';
export const MESSAGES_EDIT_FAILED = 'MESSAGES_EDIT_FAILED';

export const MESSAGES_FETCH_PENDING = 'MESSAGES_FETCH_PENDING';
export const MESSAGES_FETCH_SUCCESS = 'MESSAGES_FETCH_SUCCESS';
export const MESSAGES_FETCH_FAILED = 'MESSAGES_FETCH_FAILED';

// Trips constants

export const TRIPS_CREATE_PENDING = 'TRIPS_CREATE_PENDING';
export const TRIPS_CREATE_SUCCESS = 'TRIPS_CREATE_SUCCESS';
export const TRIPS_CREATE_FAILED = 'TRIPS_CREATE_FAILED';
export const TRIPS_EDIT_PENDING = 'TRIPS_EDIT_PENDING';
export const TRIPS_EDIT_SUCCESS = 'TRIPS_EDIT_SUCCESS';
export const TRIPS_EDIT_FAILED = 'TRIPS_EDIT_FAILED';
export const TRIPS_EDIT_FETCH_PENDING = 'TRIPS_EDIT_FETCH_PENDING';
export const TRIPS_EDIT_FETCH_SUCCESS = 'TRIPS_EDIT_FETCH_SUCCESS';
export const TRIPS_EDIT_FETCH_FAILED = 'TRIPS_EDIT_FETCH_FAILED';

//Stops constants
export const STOPS_CREATE_PENDING = 'STOPS_CREATE_PENDING';
export const STOPS_CREATE_SUCCESS = 'STOPS_CREATE_SUCCESS';
export const STOPS_CREATE_FAILED = 'STOPS_CREATE_FAILED';
export const STOPS_EDIT_PENDING = 'STOPS_EDIT_PENDING';
export const STOPS_EDIT_SUCCESS = 'STOPS_EDIT_SUCCESS';
export const STOPS_EDIT_FAILED = 'STOPS_EDIT_FAILED';
export const STOPS_EDIT_FETCH_PENDING = 'STOPS_EDIT_FETCH_PENDING';
export const STOPS_EDIT_FETCH_SUCCESS = 'STOPS_EDIT_FETCH_SUCCESS';
export const STOPS_EDIT_FETCH_FAILED = 'STOPS_EDIT_FETCH_FAILED';

//GTFS ALL Tables Add Edit constants
export const GTFS_UPDATE_FAILED = 'GTFS_UPDATE_FAILED';
export const GTFS_UPDATE_PENDING = 'GTFS_UPDATE_PENDING';
export const GTFS_UPDATE_SUCCESS = 'GTFS_UPDATE_SUCCESS';
export const GTFS_EDIT_PENDING = 'GTFS_EDIT_PENDING';
export const GTFS_EDIT_SUCCESS = 'GTFS_EDIT_SUCCESS';
export const GTFS_EDIT_FAILED = 'GTFS_EDIT_FAILED';

// Fare Zone data
export const PRODUCT_FARE_ZONE_FETCH_PENDING =
	'PRODUCT_FARE_ZONE_FETCH_PENDING';
export const PRODUCT_FARE_ZONE_FETCH_SUCCESS =
	'PRODUCT_FARE_ZONE_FETCH_SUCCESS';
export const PRODUCT_FARE_ZONE_FETCH_FAILED = 'PRODUCT_FARE_ZONE_FETCH_FAILED';
export const PRODUCT_SERVICE_TYPE_FETCH_PENDING =
	'PRODUCT_SERVICE_TYPE_FETCH_PENDING';
export const PRODUCT_SERVICE_TYPE_FETCH_SUCCESS =
	'PRODUCT_SERVICE_TYPE_FETCH_SUCCESS';
export const PRODUCT_SERVICE_TYPE_FETCH_FAILED =
	'PRODUCT_SERVICE_TYPE_FETCH_FAILED';
export const PRODUCT_TICKET_TYPE_FETCH_PENDING =
	'PRODUCT_TICKET_TYPE_FETCH_PENDING';
export const PRODUCT_TICKET_TYPE_FETCH_SUCCESS =
	'PRODUCT_TICKET_TYPE_FETCH_SUCCESS';
export const PRODUCT_TICKET_TYPE_FETCH_FAILED =
	'PRODUCT_TICKET_TYPE_FETCH_FAILED';
//Shapes
export const SHAPES_FETCH_FAILED = 'SHAPES_FETCH_FAILED';
export const SHAPES_FETCH_PENDING = 'SHAPES_FETCH_PENDING';
export const SHAPES_FETCH_SUCCESS = 'SHAPES_FETCH_SUCCESS';

//Landing

export const FETCH_TEAMS_START = 'FETCH_TEAMS_START';
export const FETCH_TEAMS_SUCCESS = 'FETCH_TEAMS_SUCCESS';
export const FETCH_TEAMS_FAILED = 'FETCH_TEAMS_FAILED';

//CS Portal Search
export const CS_SEARCH_PENDING = 'CS_SEARCH_PENDING';
export const CS_SEARCH_FAILED = 'CS_SEARCH_FAILED';
export const CS_SEARCH_SUCCESS = 'CS_SEARCH_SUCCESS';

export const USER_EMPTY_SUCCESS ='USER_EMPTY_SUCCESS'
export const USER_EMPTY_FAILED ='USER_EMPTY_FAILED'