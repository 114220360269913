import React from 'react';
import {
	Container,
	Card,
	CardBody,
	FormGroup,
	Label,
	Input,
	Button,
	Row,
	Col,
	Spinner
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import MainWrapper from '../../../MainWrapper';
import _ from 'lodash';
import EditRoutesList from '../routeslist/EditRoutesList';


const NotificationEditView = ({
	state,
	handleChange,
	handleSubmit,
	isLoading,
	alertTypeValue,
	fieldsError,
	errorMsg,
	startDate,
	deliverType,
	viewByIdLoading,
	isEditLoading,
	routes,
	sentTo,
	dateOptions,
	isDeliverTypeSelected,
	onDeliverHandleChange
}) => {
	
	return (
		<MainWrapper>
			<Container>
				<Row>
					<Col xs="12">
						<h3 className="font24-b">Edit Notification / Alert</h3>
					</Col>
				</Row>
				<Row>
					<Col xs="12">
						<div className={!_.isEmpty(errorMsg) ? 'error-msg' : ''}>
							{errorMsg}
						</div>
					</Col>
				</Row>
				<Card className="createNotification">
					{(viewByIdLoading  ||
						isEditLoading) && (
							<div className="loader">
								<div className="spinner">
									<Spinner animation="border" variant="primary" />
								</div>
							</div>
						)}

					<CardBody className="px-5 py-4">
							<div>
								<Row className={'card-tr'}>
									<Col sm="12">
									<div className="float-left mr-5 font16-b">
											Select Alert Types:
										</div>
										<div className="float-left mr-5">
										<FormGroup tag="fieldset" className="font16 mb-0">
												<Label check>
													<Input
														type="radio"
														value="Operational"
														name="operational"
														id="alertTypeValue"
														checked={alertTypeValue === 'Operational'}
													disabled
													/>{' '}
													Operational Alerts
												<span className="radioMark"></span>
												</Label>
											</FormGroup>
										</div>
										{/* <div className="float-left">
										<FormGroup tag="fieldset" className="font16 mb-0">
												<Label check>
													<Input
														type="radio"
														value="Marketing"
														name="marketing"
														id="alertTypeValue"
														checked={alertTypeValue === 'Marketing'}
														
													/>{' '}
													Marketing and Promotional Alerts
												<span className="radioMark"></span>
												</Label>
											</FormGroup>
										</div> */}
										<div className="float-right">
											{fieldsError.alertType ? (
												<div className="error-msg">{fieldsError.alertType}</div>
											) : null}
										</div>
									</Col>
								</Row>

								<Row className="mt-3">
									<Col sm="12">
									<div className="float-left mr-5 font16-b">Delivery Type:</div>
									<div className="font16 float-left mr-5">
											<Label check>
												<Input
													type="checkbox"
													value="push"
													checked={deliverType.includes('push')}
												id="deliverType"
												onChange={onDeliverHandleChange}
												disabled
												/>{' '}
												Push Notification
											<span className="checkBoxMark"></span>
											</Label>
										</div>

									<div className="font16 float-left" >
											<Label check>
												<Input
													type="checkbox"
													id="deliverType"
													checked={deliverType.includes('sms')}
												value="sms"
												onChange={onDeliverHandleChange}
												disabled={true}
												/>{' '}
												Text SMS
											<span className="checkBoxMark"></span>
											</Label>
										</div>
										<div className="float-right">
											{fieldsError.deliverType ? (
												<div className="error-msg">
													{fieldsError.deliverType}
												</div>
											) : null}
										</div>
									</Col>
								</Row>

								<Row className="mt-3">
								{!_.isEmpty(deliverType) && (
										<Col sm="6">
											<FormGroup>
											<Label for="title" className="font14">Title </Label>
												<Input
													type="text"
													id="title"
													name="title"
													value={state.title}
												onChange={handleChange}
												placeholder="Enter title here..."
													maxLength="20"
													className={
														!_.isEmpty(fieldsError.title) ? (
															'form-control error-field'
														) : (
															'form-control'
														)
													}
												/>
											<div className="letter_count">{state.title.length}/20</div>
											</FormGroup>
											{fieldsError.title ? (
												<div className="error-msg">{fieldsError.title}</div>
											) : null}
										</Col>
									)}
								{(!_.isEmpty(deliverType) && _.includes(deliverType, 'sms')) && (
										<Col sm="6">
											<FormGroup>
											<Label for="message" className="font14">SMS Message</Label>
												<Input
													type="textarea"
													id="message"
													name="message"
													value={state.message}
													maxLength="140"
												onChange={handleChange}
												placeholder='Enter sms message here…'
													className={
														!_.isEmpty(fieldsError.message) ? (
															'form-control error-field'
														) : (
															'form-control'
														)
													}
												/>
												{fieldsError.message ? (
													<div className="error-msg">{fieldsError.message}</div>
												) : null}
											</FormGroup>
										<div className="letter_count">{state.message.length}/140</div>
										</Col>
									)}
								</Row>
							{(!_.isEmpty(deliverType) && _.includes(deliverType, 'push') )&& (
									<Row className={'card-tr'}>
										<Col sm="6" className="mb-5">
											<FormGroup>
											<Label for="description" className="font14">Push Message</Label>
												<Input
													type="textarea"
													id="description"
													name="description"
													maxLength="250"
													value={state.description}
												onChange={handleChange}
												placeholder='Enter push message text here…'
													className={
														!_.isEmpty(fieldsError.description) ? (
															'form-control error-field'
														) : (
															'form-control'
														)
													}
												/>
												{fieldsError.description ? (
													<div className="error-msg">
														{fieldsError.description}
													</div>
												) : null}
											</FormGroup>
										<div className="letter_count">{state.description.length}/250</div>
										</Col>
									</Row>
								)}

								<Row>
									<Col sm="12" className="mt-3">
									<Label className="float-left mr-5 font16-b">
											{' '}
											Select Date :{' '}
										</Label>
									<div className="float-left mr-5">
										<FormGroup tag="fieldset" className="font16">
											<Label check>
												<Input
													type="radio"
													value="now"
													name="now"
													checked={dateOptions}
													readOnly={true}
													disabled
												/>{' '}
													Now
												<span className="radioMark"></span>
											</Label>
										</FormGroup>
									</div>
									<div className="float-left ml-5 font16">
										<FormGroup tag="fieldset">
											<Label check>
												<Input
													type="radio"
													value="later"
													name="later"
													selected={true}
													checked={!dateOptions}
													disabled={true}
												/>{' '}
													Later
												<span className="radioMark"></span>
											</Label>
										</FormGroup>
									</div>
									</Col>
								</Row>
								<Row>
									<Col sm="4">
										<FormGroup className="date_picker_width">
										<DatePicker
											value={startDate}
											popperPlacement="right-bottom"
											readOnly={true}
											className="startDateField form-control"
											/>
											
										</FormGroup>
									</Col>
								</Row>
								<Row>
								<Col sm="4" className="font12">
									{!dateOptions ? (
											<p>
												<b>Please note:</b>{' '}
												<i>
													Messages are processed at 4 AM (EST), but may be
													delivered throughout the day based on carrier delivery
													times.
												</i>
											</p>
										) : (
												<p>
													<b>Please note:</b>{' '}
													<i>
														It may take some time to process all messages and
														delivery may not be immediate”
												</i>
												</p>
											)}
									</Col>
								</Row>
							<Row className="mt-4 mb-3">
								<Col xs="12">
									<div className="clearfix"></div>
									<hr />
								</Col>
									<Col sm="4">
									<div className="font16-b float-left">
											<b>Send to:</b>
										</div>
									</Col>
									<Col sm="4">
										<div className="float-center">
											{fieldsError.routeList ? (
												<div className="error-msg">{fieldsError.routeList}</div>
											) : null}
										</div>
								</Col>
								<Col sm="4">
									{!_.isEmpty(sentTo) && sentTo==='All' ? (
										<div className="float-right">
											<Label check>
												<Input
													type="checkbox"
													value="allRoutesSelect"
													checked={true}
													readOnly={true}
													disabled
												/>{' '}
													Select All Routes
												<span className="checkBoxMark"></span>
											</Label>
										</div>
									) : (
											''
										)}
								</Col>
								</Row>
								<Row className={'card-tr'}>
									<Col sm="12" className="mb-3">
										{!viewByIdLoading &&
										!_.isEmpty(routes) && (
										<EditRoutesList
											routes={routes}
											sentTo={sentTo}
											/>
										)}
									</Col>
								</Row>
								<Row className="mt-4">
									<Col sm="12">
										<div className="text-right">
											<Button
												// bsSize="lg"
												onClick={handleSubmit}
												color="primary"
											className="rounded-pill"
											disabled={isDeliverTypeSelected()}
											>
												{isLoading ? 'Please wait...' : 'SEND NOTIFICATION'}
											</Button>
										</div>
									</Col>
								</Row>
							</div>
					</CardBody>
				</Card>
			</Container>
		</MainWrapper>
	);
};

export default NotificationEditView;
