
import { subDays } from "date-fns";
const resetSearchInputs = (action) => {
    const searchParams = {
        fieldEdited: "",
        searchFields: {
            orderNumber: "",
            ticketId: "",
            customerName: "",
            emailAddress: "",
            wellsFargoReferenceNumber: "",
            totalOrderAmount: "",
        },
        toDate: new Date(),
        fromDate: subDays(new Date(), 90),
    };
    action(searchParams);

}
export default resetSearchInputs;