import {
	LOGIN_FAILED,
	LOGIN_SUCCESS,
	SESSION_UPDATE_FAILED,
	SESSION_UPDATE_PENDING,
	SESSION_UPDATE_SUCCESS,
	LOGIN_PENDING,
	LOGOUT_SUCCESS,
	LOGOUT_FAILED,
	LOGIN_USER_ACCESS_PENDING,
	LOGIN_USER_ACCESS_SUCCESS,
	LOGIN_USER_ACCESS_FAILED,
	USER_EMPTY_SUCCESS
} from '../../util/constant';
import _ from 'lodash';

const initialState = {
	user: {},
	currentUser:[],
	loginData:{},
	isAuthenticated: true,
	isLoading: false,
	error: {},
	menuDetails: {},
	operator:{},
	userAccess: [],
	accessError: {},
	isAccessLoading: false,
	role: [],
	userError: {},
	loginError:{}
};
const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOGIN_PENDING:
			return {
				...state,
				isLoading: true,
				error: {},
				loginData: {},
				loginError: {},
				accessError:{},
				role:[]
			};
		case LOGIN_SUCCESS:
			return {
				...state,
				isAuthenticated: true,
				isLoading: false,
				loginData: action.payload,
				role: action.payload.signInUserSession.accessToken.payload["cognito:groups"]
			};
		case LOGIN_FAILED:
			return {
				...state,
				loginError: action.payload,
				isAuthenticated: false,
				isLoading: false
			};
		case LOGIN_USER_ACCESS_PENDING:
			return {
				...state,
				isAccessLoading: true,
				accessError: {},
				userAccess: {},
				menuDetails: {},
				adminRoles: {},
				notificationAccess: {},
				accessDetails:{}
			};
		case LOGIN_USER_ACCESS_SUCCESS:
			
			return {
				...state,
				isAccessLoading: false,
				menuDetails: !_.isEmpty(action.payload) && _.get(action.payload, 'menuDetails'),
				adminRoles: !_.isEmpty(action.payload) && _.get(action.payload, 'adminRoles'),
				notificationAccess: !_.isEmpty(action.payload) && _.get(action.payload, 'notificationAccess'),
				accessDetails: !_.isEmpty(action.payload) && _.get(action.payload, 'accessDetails'),
				
			};
		case LOGIN_USER_ACCESS_FAILED:
			return {
				...state,
				isAuthenticated: false,
				accessError: action.payload,
				isAccessLoading: false
			};
		case SESSION_UPDATE_PENDING:
			return {
				...state,
				isLoading: true,
				user: {},
				currentUser:[],
				userError:{}
			};
		case SESSION_UPDATE_SUCCESS:
			return {
				...state,
				isAuthenticated: true,
				isLoading: false,
				user: action.payload.attributes,
				currentUser: action.payload
				
			};
		case SESSION_UPDATE_FAILED:
			return {
				...state,
				isAuthenticated: false,
				userError: action.payload,
				isLoading: false
			};
		case LOGOUT_SUCCESS:
			localStorage.removeItem('userAccess')
			return {
				...state,
				isAuthenticated: false,
				loginData: {},
				loginError: {},
				user: {},
				role: [],
				userAccess: {},
				currentUser: [],
				accessError: {},
				
			};
		case LOGOUT_FAILED:
			return {
				...state,
				error: action.payload
			};
		case USER_EMPTY_SUCCESS:
			return {
				...state,
				currentUser: [],
				isAuthenticated: false,
			};
		default:
			return state;
	}
};

export default authReducer;
