import React from 'react';
import { Modal, ModalBody, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';


const ErrorNotificationModal = ({
    message,
    open
}) => {
    const history = useHistory();

    const onClose = () => {
        history.push(`/notification`)
    }

    return (
        <div>
            <Modal className="confirmation-modal" show={open}>
                <ModalBody>
                    <h3>Connection Lost</h3>
                    <div className="message-error">Please check your internet connection.</div>
                    <Button onClick={onClose}>GO TO HOME PAGE</Button>
                </ModalBody>
            </Modal>
        </div>
    );
};



export default ErrorNotificationModal;
