import React, { Fragment } from 'react';
import { BallBeat } from 'react-pure-loaders';
import PropTypes from 'prop-types';

const { bool, node } = PropTypes;

const Loader = ({ loading, children }) => {
	return <Fragment>{loading ? <BallBeat color={'#000000'} loading={loading} /> : children}</Fragment>;
};

Loader.propTypes = {
	loading: bool,
	children: node,
};

export default Loader;
