import React from "react";
import { Modal } from "react-bootstrap";
import AllSet from "../../../../assets/images/allset.svg";
import Warning from "../../../../assets/images/warning-icon.svg";
import { Col, Row, Button } from "reactstrap";
const AllSetPopup = ({ modalOpen, handleClose, subText, isError}) => {
  return (
    <Modal className="no-results-modal" show={modalOpen}>
      <Modal.Header closeButton onClick={handleClose}></Modal.Header>
      <div className="content">
        <Row className="my-3">
          <Col className="text-center">
            {isError ? (
              <img src={Warning} alt="Warningicon" />
            ) : (
                <img src={AllSet} alt="Allseticon" />
              )}
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            {isError ? (
              <span className="font24-b">Oops! Something went wrong.</span>
            ) : (
                <span className="font24-b">All Set</span>
              )}
          </Col>
        </Row>
        <Row>
          <Col className="text-center mx-2 mt-1">
            <span className="font18">{subText}</span>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <Button className="button-search font16-b" onClick={handleClose}>
              OK
            </Button>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default AllSetPopup;
