import React from "react";
import { useSelector } from 'react-redux';
import { Container, Row, Col, Breadcrumb, BreadcrumbItem } from "reactstrap";
import _ from "lodash"
import { Link } from "react-router-dom";


const DashboardHeader = () => {
  const { user } = useSelector((state) => state.auth);
  return (
    <div className="dashboard_header">
      <Container>
        <Row>
          <Col className="text-left">
            <Breadcrumb className="page-breadcrumb">
              {" "}
              <BreadcrumbItem>
                <Link
                  to={{
                    pathname: "/dashboard",
                  }}
                >
                  Dashboard{" "}
                </Link>
              </BreadcrumbItem>{" "}
            </Breadcrumb>
            <div className="clearfix" />
            <h2 className="mt-2 text-uppercase">
              Welcome to COACH USA ADMIN PORTALS
            </h2>
            <h4 className="font24-b">Hi, {!_.isEmpty(user.given_name) ? user.given_name : ""} </h4>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DashboardHeader;
