import React, { useEffect, useState} from "react";
import { Link, useHistory } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import { connect } from "react-redux";
import {
  setSearchInputValues,
  resetCustomerSupportList,
} from "../../../../store/actions/CS_SearchAction";
import resetSearchInputs from "../../../../util/hooks/ResetSearchInputs";
import useUserAccessDetails from '../../../../util/hooks/UserAccessDetails';
import _ from "lodash";

const Navigation = ({
  isTravelHistory,
  isFailedTransaction,
  setSearchInputValues,
  resetCustomerSupportList
}) => {
  const userAccessDetails = useUserAccessDetails();
  const [accessList, setAccessList] = useState({});
  useEffect(() => {
    if (!_.isEmpty(userAccessDetails)) {
      setAccessList(_.get(userAccessDetails, "csAdminHome", {}))
    }
  })
  
  const history = useHistory();
  const onTravelHistory = () => {
    resetSearchInputs(setSearchInputValues);
    resetCustomerSupportList();
    history.push("/travelhistory");
  };
  const onFailedTransaction = () => {
    resetSearchInputs(setSearchInputValues);
    resetCustomerSupportList();
    history.push("/failedtransactions");
  };
  
  return (
    <Card>
      <CardBody>
        <Row>
          <Col
            md={isTravelHistory ? 4 : isFailedTransaction ? 3 : 2}
            className="navigation-border-right"
          >
            <span className="font20">Riskified</span>
            <br />
            {_.get(accessList, "riskifiedAccess", true) ?
              <a
                href="https://app.riskified.com/login"
                className="font14-b theme-blue-txt"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://app.riskified.com/login
            </a>
              :
              <span className="font14-b">  https://app.riskified.com/login </span>
            }
          </Col>
          <Col
            md={isTravelHistory ? 4 : isFailedTransaction ? 3 : 3}
            className="navigation-border-right"
          >
            <span className="font20">Cybersource</span>
            <br />
            {_.get(accessList, "cyberSourceAccess", true) ?
              <a
                href="https://ebc2.cybersource.com/ebc2/"
                target="_blank"
                rel="noopener noreferrer"
                className="font14-b theme-blue-txt"
              >
                https://ebc2.cybersource.com/ebc2/
            </a>
              :
              <span className="font14-b theme-blue-txt"> https://ebc2.cybersource.com/ebc2/</span> 
          }
          </Col>
          <Col
            md={isTravelHistory ? 4 : isFailedTransaction ? 3 : 2}
            className={`${!isTravelHistory && "navigation-border-right"}`}
          >
            <span className="font20">FullStory</span>
            <br />
            {_.get(accessList, "fullStoryAccess", true) ?
              <a
                href="https://www.fullstory.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="font14-b theme-blue-txt"
              >
                https://www.fullstory.com/
            </a>
              :
              <span className="font14-b theme-blue-txt"> https://www.fullstory.com/ </span>
            }
          </Col>
          <Col
            md="2"
            className={`${!isFailedTransaction && "navigation-border-right"}`}
            hidden={isTravelHistory}
          >
            <span className="font20">Travel History</span>
            <br />
            {_.get(accessList, "travelHistoryAccess", true) ?
              <Link
                className="font14-b theme-blue-txt"
                to="#"
                onClick={onTravelHistory}
              >
                Go to travel history
            </Link>
              :
              <span className="font14-b theme-blue-txt"> Go to travel history</span>
            }
          </Col>
          <Col md={3} hidden={isTravelHistory || isFailedTransaction}>
            <span className="font20">Search Failed Transaction</span>
            <br />
            {_.get(accessList, "failedTxnAccess", true) ?
              <Link
                className="font14-b theme-blue-txt"
                to="#"
                onClick={onFailedTransaction}
              >
                Go to search failed transaction
            </Link>
              :
              <span className="font14-b theme-blue-txt"> Go to search failed transaction </span>
          }
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    setSearchInputValues: (params) => setSearchInputValues(params, dispatch),
    resetCustomerSupportList: () => resetCustomerSupportList(dispatch),
  };
};
export default connect(null, mapDispatchToProps)(Navigation);
