import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import Logo from "../../assets/images/COACHUSA_logo.svg";
import downArrow from "../../assets/images/dropdown-arrow.svg";
import upArrow from "../../assets/images/dropdown-up.svg";
import { connect } from "react-redux";
import { logout } from "./../../store/actions/AuthAction";
import PropTypes from "prop-types";
import { Container, Button } from "reactstrap";
import _ from "lodash";
import useUserRole from "../../util/hooks/UserRole";
import { resetCustomerSupportList, setSearchInputValues } from "../../store/actions/CS_SearchAction";
import resetSearchInputs from "../../util/hooks/ResetSearchInputs";
import useUserAccessDetails from '../../util/hooks/UserAccessDetails';
const { func, object } = PropTypes;

const Header = ({
    auth: { user, menuDetails },
    handleLogout,
    resetCustomerSupportList,
    setSearchInputValues
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [menus, setMenus] = useState([]);
    const toggle = () => setIsOpen(!isOpen);
    const [activeMenu, setActiveMenu] = useState("");
    const [isLogoutShow, setIsLogoutShow] = useState(false);
    const userRole = useUserRole();
    const userAccessDetails = useUserAccessDetails();
   
    useEffect(() => {
        if (!_.isEmpty(menuDetails)) {
            if ( 
                _.includes(_.find(userRole), "cs") ||
                _.includes(_.find(userRole), "notification")
            ) {
                let menus = menuDetails
                    .map((menu) => {
                    return menu;
                });
                setMenus(menus);
            }
        }
    }, [menuDetails]);

    const history = useHistory();
    const changeValue = (e) => {
        if (!(history.location.pathname === `/${e.currentTarget.id}`)) {
            setActiveMenu("active");
            history.push(`/${e.currentTarget.id}`);
            resetCustomerSupportList();
            resetSearchInputs(setSearchInputValues)
        }
    };
    const onHandleLogout = () => {
        handleLogout();
        resetCustomerSupportList();
        resetSearchInputs(setSearchInputValues)
    };
    return (
        <Navbar className="pt-0 pb-0 header" expand="lg">
            <Container>
                <Navbar.Brand href="/dashboard">
                    <img src={Logo} alt="Logo" width="150px" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={toggle} />
                <Nav className="main_nav">
                    {menus.map((e, index) => {
                        return (
                            <div key={index}>
                                {e.haveChildren ? (
                                    <NavDropdown
                                        key={index}
                                        title={e.display}
                                        id="basic-nav-dropdown"
                                    >
                                        {e.children.map((subMenu, i) => {
                                            return (
                                                <NavDropdown.Item
                                                    key={i}
                                                    id={subMenu.uiKey}
                                                    className={activeMenu}
                                                    onClick={changeValue}
                                                >
                                                    {subMenu.display}
                                                </NavDropdown.Item>
                                            );
                                        })}
                                    </NavDropdown>
                                ) : (
                                        <Nav.Link
                                            className={
                                                e.uiKey === history.location.pathname.split("/")[1] || e.id === "dashboard"
                                                    ? "active"
                                                    : activeMenu
                                            }
                                            key={index}
                                            id={e.uiKey}
                                            onClick={changeValue}
                                        >
                                            {" "}
                                            {e.display}
                                        </Nav.Link>
                                    )}
                            </div>
                        );
                    })}
                </Nav>

                <div className="ml-auto user_nav navbar-nav">
                    <div id="basic-nav-dropdown">
                        <span>
                            <label>
                                {!_.isEmpty(user.given_name) ? user.given_name : user.family_name}{" "}
                            </label>
                        </span>
                        <img
                            src={isLogoutShow ? upArrow : downArrow}
                            onClick={() => setIsLogoutShow(!isLogoutShow)}
                            alt="show logout"
                        />
                        {isLogoutShow ? (
                            <ul className="notification_sub_checkbox">
                                <li>
                                    <Button onClick={onHandleLogout}> Logout </Button>
                                </li>
                            </ul>
                        ) : null}
                    </div>
                </div>
            </Container>
        </Navbar>
    );
};

Header.propTypes = {
    auth: object,
    handleLogout: func
};

const mapStateToProps = ({ auth }) => {
    return {
        auth,
        menuDetails: auth,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleLogout: () => logout(dispatch),
        resetCustomerSupportList: () => resetCustomerSupportList(dispatch),
        setSearchInputValues: (params) => setSearchInputValues(params, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
