import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardTitle,Row,Col } from "reactstrap";
import EmailTicketsPopup from "../modal/EmailTicketsPopup";
import { format, parseISO } from "date-fns";
import _ from "lodash";
import { useSelector } from "react-redux";
import useUserAccessDetails from '../../../../util/hooks/UserAccessDetails';

const OrderInformation = ({ orderInformation, setShowWarning, setResendTicketsEmail }) => {
  const [modalToggle, setModalToggle] = useState(false);
  const [transDate, setTransDate] = useState("");
  const [transTime, setTransTime] = useState("");
  const [emailAccess, setEmailAccess] = useState(false);
  const {  activeButton } = useSelector(
    (state) => state.ticketRedemReducer
  );
  const userAccessDetails = useUserAccessDetails();
  useEffect(() => {
    if (!_.isEmpty(userAccessDetails)) {
      const access = _.get(userAccessDetails, "csViewDetails", {})
      setEmailAccess(_.get(access, "emailReceiptAccess", ""))
    }
  }, []);
  useEffect(() => {
    if (!_.isEmpty(orderInformation.purchase_date)) {
      setTransDate(
        format(parseISO(orderInformation.purchase_date), "MMM dd,yyyy")
      );
      setTransTime(format(parseISO(orderInformation.purchase_date), "HH:mm"));
    }
  }, [orderInformation]);
  const handleOnEmailReceipt = () => {
    if (_.isEmpty(activeButton)) {
      setModalToggle(!modalToggle)
    }
    else {
      setShowWarning(true)
    }
  }
  return (
    <>
      {modalToggle && (
        <EmailTicketsPopup
          modal={modalToggle}
          handleClose={() => setModalToggle(!modalToggle)}
          transactionId={orderInformation.transactionId}
          orderId={orderInformation.order_id}
          setResendTicketsEmail={setResendTicketsEmail}
        />
      )}
      <Card className="h-100 pb-3">
        <CardBody className="px-4">
          <CardTitle>
            <span className="font16">
              Order Information
              <hr className="mt-2" />
            </span>
          </CardTitle>
          <div className="row">
            <div className="col-4">
              <span className="font14-b">Order Number</span>
            </div>
            <div className="col-8 font16">{orderInformation.order_id}</div>
          </div>
          <div className="row mt-3">
            <div className="col-4">
              <span className="font14-b">Number of Tickets</span>
            </div>
            <div className="col-8 font16">
              {orderInformation.tickets_quantity}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-4">
              <span className="font14-b">Transaction Date</span>
            </div>
            <div className="col-8 ">{transDate}</div>
          </div>
          <div className="row mt-3">
            <div className="col-4">
              <span className="font14-b">Transaction Time</span>
            </div>
            <div className="col-8 font16">
              {transTime ? `${transTime} EST` : ""}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-4">
              <span className="font14-b">Order Status</span>
            </div>
            <div className="col-8 font16">{orderInformation.order_status}</div>
          </div>
          <div className="row mt-3">
            <div className="col-4">
              <span className="font14-b">Order Total</span>
            </div> 
            <div className="col-8 font16">${parseFloat(orderInformation.total_amount).toFixed(2)}</div>
          </div>
        </CardBody>
        <Row >
          <Col className="text-center">
          <Button
            className="px-4 py-2-75 text-uppercase btn-pill"
            color="primary"
            onClick={() => handleOnEmailReceipt()}
            disabled={!emailAccess}
          >
            <span className="font16-b">EMAIL TICKET(S) &amp; RECEIPT</span>
            </Button>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default OrderInformation;
