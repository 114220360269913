import React, { Fragment } from 'react';
import Header from './layouts/Header';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import Loader from './layouts/Loader';
import Footer from './layouts/Footer';
import { useSelector } from 'react-redux';
const { node} = PropTypes;

const MainWrapper = ({
	children
}) => {
	const { isAuthenticated, isLoading } = useSelector(state => state.auth);
	if (!isAuthenticated) return <Redirect to="/login" />;
	return (
		<Loader loading={isLoading}>
				<Fragment>
					<Header />
					<Container fluid className="mt-3">
						{children}
					</Container>
					<Footer />
				</Fragment>
		</Loader>
	);
};

MainWrapper.propTypes = {
	children: node
};


export default MainWrapper;
