import React from "react";

const PaginationHeader = ({ pageIndex, pageCount }) => {
  return (
    <>
      <h6 className="font16 showing-result">
        Showing{" "}
        <span>
          {pageIndex+1} of {pageCount} pages
          {/* {pageIndex + 1} of {pageOptions.length} results */}
        </span>{" "}
      </h6>
    </>
  );
};

export default PaginationHeader;
