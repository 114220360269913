import React from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";

const CustomerInformation = ({ editClick, info, stateValue }) => {
  return (
    <Card className="fixed-height-card-container">
      <CardBody className="px-4">
        <CardTitle>
          <Row>
            <Col>
              <span className="font16">Customer Information</span>
            </Col>
            {/* <Col className="text-right ">
              <span className="font16">Edit</span>
            </Col> */}
          </Row>
          <hr className="mt-2" />
        </CardTitle>
        <div className="row">
          <div className="col-4">
            <span className="font14-b">First name</span>
          </div>
          <div className="col-8 font16">{info.first_name}</div>
        </div>

        <div className="row mt-2">
          <div className="col-4">
            <span className="font14-b">Middle initial</span>
          </div>
          <div className="col-8 font16"> </div>
        </div>

        <div className="row mt-2">
          <div className="col-4">
            <span className="font14-b">Last name</span>
          </div>
          <div className="col-8 font16">{info.last_name}</div>
        </div>

        <div className="row mt-2">
          <div className="col-4">
            <span className="font14-b">Email address</span>
          </div>
          <div className="col-8 font16">{info.email}</div>
        </div>

        <div className="row mt-2">
          <div className="col-4">
            <span className="font14-b">Billing address</span>
          </div>
          <div className="col-8 font16">{info.address1}</div>
        </div>

        <div className="row mt-2">
          <div className="col-4">
            <span className="font14-b">Address line 2</span>
          </div>
          <div className="col-8 font16"> </div>
        </div>

        <div className="row mt-2">
          <div className="col-4">
            <span className="font14-b">City</span>
          </div>
          <div className="col-8 font16">{info.city}</div>
        </div>
        <div className="row mt-2">
          <div className="col-4">
            <span className="font14-b">State</span>
          </div>
          <div className="col-8 font16">{stateValue} </div>
        </div>
        <div className="row mt-2">
          <div className="col-4">
            <span className="font14-b">Zip Code</span>
          </div>
          <div className="col-8 font16">{info.postalcode}</div>
        </div>
        <div className="row mt-2">
          <div className="col-4">
            <span className="font14-b">Phone number</span>
          </div>
          <div className="col-8 font16">{info.phone}</div>
        </div>
      </CardBody>
    </Card>
  );
};

export default CustomerInformation;
