import {
	TICKET_REDEMP_INSERT_PENDING,
	TICKET_REDEMP_INSERT_FAILED,
	TICKET_REDEMP_INSERT_SUCCESS,
	TICKET_REDEMP_FETCH_PENDING,
	TICKET_REDEMP_FETCH_FAILED,
	TICKET_REDEMP_FETCH_SUCCESS
} from '../../util/constant';
import api from '../../util/api';
import appURLConstant from '../../util/appURLConstants';

export const ticketRedempInsert = async (data, dispatch) => {
	try {
		dispatch({
			type: TICKET_REDEMP_INSERT_PENDING
		});
		const response = await api.post(
			`${appURLConstant.REACT_SAVE_TICKET_REDEMPTIONS}`,
			data
		);
		dispatch({
			type: TICKET_REDEMP_INSERT_SUCCESS,
			payload: response.data
		});
	} catch (error) {
		dispatch({
			type: TICKET_REDEMP_INSERT_FAILED,
			payload: error
		});
	}
};

export const setRedemptionsTicketInfo = async (data,isActive, dispatch) => {
	dispatch({
		type: 'SET_REDEMP_TICKET_INFO_SUCCESS',
		info: data,
		activeButton: isActive
	});
}

export const getTicketRedemptions = async (data, dispatch) => {
	try {
		dispatch({
			type: TICKET_REDEMP_FETCH_PENDING
		});
		const response = await api.fetch(`${appURLConstant.REACT_GET_TICKET_REDEMPTIONS_BY_OREDER}${data.searchText}?requestedFor=admin_portal`);
		if (response.data.orbitResponse.statusCode === 200) {
			dispatch({
				type: TICKET_REDEMP_FETCH_SUCCESS,
				payload: response.data.orbitResponse.body.Items
			});
		}
		else {
			dispatch({
				type: TICKET_REDEMP_FETCH_FAILED,
				payload: "error",
			});
		}
	} catch (error) {
		dispatch({
			type: TICKET_REDEMP_FETCH_FAILED,
			payload: error
		});
	}
};
