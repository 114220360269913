import React from 'react';

import {
	Input,
	Row,
	Col,
	InputGroup,
	InputGroupAddon,
	InputGroupText
} from 'reactstrap';
import PropTypes from 'prop-types';

import { MdSearch } from 'react-icons/md';

const GlobalFilter = ({  setGlobalFilter }) => {
	//const count = preGlobalFilteredRows.length;

	return (
		<div className="search">
			<Row>
				<Col>
					<InputGroup>
						<InputGroupAddon addonType="prepend">
							<InputGroupText>
								<MdSearch />
							</InputGroupText>
						</InputGroupAddon>

						<Input
							onChange={(e) => {
								setGlobalFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
							}}
							className="font16"
							placeholder='SEARCH'
							
						/>
					</InputGroup>
				</Col>
			</Row>
		</div>
	);
};

GlobalFilter.propTypes = {
	//preGlobalFilteredRows: PropTypes.array.isRequired,
	setGlobalFilter: PropTypes.func.isRequired
};

export default GlobalFilter;
