import React, { useState,useEffect } from 'react';
import {
	Container,
	Card,
	CardBody,
	FormGroup,
	Label,
	Input,
	Button,
	Row,
	Col,
	Spinner
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import MainWrapper from '../../../MainWrapper';
import RoutesList from '../routeslist/RoutesList';
import dateImg from '../../../../assets/images/cal.svg';
import _ from 'lodash';
import add from 'date-fns/add';
import useRoleAccess from '../../../../util/hooks/RoleAccess';

const NotificationCreateView = ({
	state,
	handleChange,
	onSendNotifications,
	isLoading,
	onAlertTypeClick,
	alertTypeValue,
	onDeliverHandleChange,
	onDtChange,
	selectDateValue,
	onDateSelectValue,
	isNotificationsLoading,
	fieldsError,
	errorMsg,
	deliverType,
	startDate,
	onSelectAllChange,
	allRoutesChecked,
	sentRoutesList,
	isDeliverTypeSelected
}) => {
	const roleAccess = useRoleAccess();
	const [userRoutes, setUserRoutes] = useState([]);
	const onDateChange = (date) => {
		onDtChange(date);
	};
	useEffect(() => {
		const operators = _.get(roleAccess, 'operators', 'ALL');
		setUserRoutes(operators);
	})
	
	const calendarRef = React.createRef();	
	return (
		<MainWrapper>
			<Container>
				<Row>
					<Col xs="12">
						<h3 className="font24-b text-camelCase">
							Create New Notification / Alert
						</h3>
					</Col>
				</Row>
				<Row>
					<Col xs="12">
						<div className={!_.isEmpty(errorMsg) ? 'error-msg' : ''}>
							{errorMsg}
						</div>
					</Col>
				</Row>
				<Card className="createNotification">
					{isNotificationsLoading && (
						<div className="loader">
							<div className="spinner">
								<Spinner animation="border" variant="primary" />
							</div>
						</div>
					)}
					<CardBody className="px-5 py-4">
						<div>
								<Row>
									<Col sm="12">
										<div className="float-left mr-5 font16-b">
											Select Alert Types:
										</div>
										<div className="float-left mr-5">										
										<FormGroup tag="fieldset" className="font16 mb-0">
												<Label check>
													<Input
														type="radio"
														value="Operational"
														name="operational"
													id="alertTypeValue"
													
														checked={alertTypeValue === 'Operational'}
														onChange={onAlertTypeClick}
													/>{' '}
													Operational Alerts
												<span className="radioMark"></span>
												</Label>
											</FormGroup>
										</div>
										<div className="float-right">
											{fieldsError.alertType ? (
												<div className="error-msg">{fieldsError.alertType}</div>
											) : null}
										</div>
										<div className="clearfix"></div>
										<hr />
									</Col>
								</Row>

								<Row>
									<Col sm="12">
										<div className="float-left mr-5 font16-b">Delivery Type:</div>
									<div className="font16 float-left mr-5">
											<Label check>
												<Input
													type="checkbox"
													value="push"
													checked={deliverType.includes('push')}
													id="deliverType"
												onChange={onDeliverHandleChange}
												/>{' '}
												Push Notification
												<span className="checkBoxMark"></span>
											</Label>
										</div>

									<div className="font16 float-left" >
										<Label check >
												<Input
													type="checkbox"
													id="deliverType"
													checked={deliverType.includes('sms')}
													value="sms"
												onChange={onDeliverHandleChange}
												/>{' '}
												Text SMS
												<span className="checkBoxMark"></span>
											</Label>
										</div>
										<div className="float-right">
											{fieldsError.deliverType ? (
												<div className="error-msg">
													{fieldsError.deliverType}
												</div>
											) : null}
										</div>
										<div className="clearfix"></div>
										<hr />
									</Col>
								</Row>

								<Row>
								{!_.isEmpty(deliverType)  && (
								
										<Col sm="6">
											<FormGroup>
												<Label for="title" className="font14">Title </Label>
												<Input
													type="text"
													id="title"
													name="title"
													value={state.title}
												onChange={handleChange}
												placeholder="Enter title here..."
													maxLength="20"
													className={
														!_.isEmpty(fieldsError.title) ? (
															'form-control error-field'
														) : (
															'form-control textField'
														)
													}
												/>
											<div className="letter_count">{state.title.length}/20</div>
											</FormGroup>
											{fieldsError.title ? (
												<div className="error-msg">{fieldsError.title}</div>
											) : null}
										</Col>
									)}
								{(!_.isEmpty(deliverType) && _.includes(deliverType, 'sms')) && (
										<Col sm="6">
											<FormGroup>
											<Label for="message" className="font14">SMS Message</Label>
												<Input
													type="textarea"
													id="message"
													name="message"
												value={state.message}
												placeholder='Enter sms message here…'
													maxLength="140"
													onChange={handleChange}
													className={
														!_.isEmpty(fieldsError.message) ? (
															'form-control error-field'
														) : (
															'form-control'
														)
													}
												/>
												{fieldsError.message ? (
													<div className="error-msg">{fieldsError.message}</div>
												) : null}
											</FormGroup>
										<div className="letter_count">{state.message.length}/140</div>
										</Col>
								 )} 
								</Row>
							{(!_.isEmpty(deliverType) && _.includes(deliverType,'push') )  && (
							
									<Row className={'card-tr'}>
										<Col sm="6" className="mb-5">
											<FormGroup>
											<Label for="description" className="font14">Push Message</Label>
												<Input
													type="textarea"
													id="description"
													name="description"
													maxLength="250"
												value={state.description}
												placeholder='Enter push message text here…'
													onChange={handleChange}
													className={
														!_.isEmpty(fieldsError.description) ? (
															'form-control error-field'
														) : (
															'form-control'
														)
													}
												/>
												{fieldsError.description ? (
													<div className="error-msg">
														{fieldsError.description}
													</div>
												) : null}
											</FormGroup>
										<div className="letter_count">{state.description.length}/250</div>
										</Col>
									</Row>
							)}
							<Row >
								<Col sm="12">
									<Label className="float-left mr-5 font16-b">
										{' '}
											Select Date :{' '}
									</Label>
									<div className="float-left mr-5">
										<FormGroup tag="fieldset" className="font16" disabled={isDeliverTypeSelected()}>
											<Label check>
												<Input
													type="radio"
													value="now"
													name="now"
													checked={selectDateValue === 'now'}
													onChange={onDateSelectValue}
												/>{' '}
													Now
												<span className="radioMark"></span>
												</Label>
										</FormGroup>
									</div>
									<div className="float-left ml-5 font16" >
										<FormGroup tag="fieldset" disabled={isDeliverTypeSelected()}>
											<Label check>
												<Input
													type="radio"
													value="later"
													name="later"
													selected={true}
													checked={selectDateValue === 'later'}
													onChange={onDateSelectValue}
												/>{' '}
													Later
												<span className="radioMark"></span>
												</Label>
										</FormGroup>
									</div>
									<div className="float-right">
										{fieldsError.selectDateValue ? (
											<div className="error-msg">{fieldsError.selectDateValue}</div>
										) : null}
									</div>
								</Col>
							</Row>
							<Row>
								<Col sm="4">
									<FormGroup className="date_picker_width" >
										<DatePicker
											selected={startDate}
											onChange={onDateChange}
											popperPlacement="right-bottom"
											className="startDateField form-control"
											minDate={add(new Date(), {
												days: 1
											})}
											ref={calendarRef}
											
											disabled={isDeliverTypeSelected() || selectDateValue === 'now'}
										/>
										<img
											src={dateImg}
											alt="dateImage"
											onClick={() => {
												calendarRef.current.setOpen(true);
											}}
										/>
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col sm="4" className="font12">
									{selectDateValue === 'later' ? (
										<p>
											<b>Please note:</b>{' '}
											<i>
												Messages are processed at 4 AM (EST), but may be
												delivered throughout the day based on carrier delivery
												times.
												</i>
										</p>
									) : (
											<p>
												<b>Please note:</b>{' '}
												<i>
													It may take some time to process all messages and
													delivery may not be immediate”
												</i>
											</p>
										)}
								</Col>
							</Row>
							<Row className="mb-3">
								<Col xs="12">
									<div className="clearfix"></div>
									<hr />
								</Col>
									<Col sm="4">
										<div className="font16-b float-left">
											<b>Send to:</b>
										</div>
									</Col>
									<Col sm="4">
										<div className="float-center">
											{fieldsError.routeList ? (
												<div className="error-msg">{fieldsError.routeList}</div>
											) : null}
										</div>
									</Col>
									<Col sm="4">
										{!_.isEmpty(userRoutes) && _.includes(_.find(userRoutes), 'ALL') ? (
											<div className="float-right">
												<Label check>
													<Input
														type="checkbox"
														value="allRoutesSelect"
														onChange={onSelectAllChange}
														disabled={isDeliverTypeSelected()}
														checked={allRoutesChecked}
													/>{' '}
													Select All Routes
												<span className="checkBoxMark"></span>
												</Label>
											</div>
										) : (
											''
										)} 
									</Col>
								</Row>
								<Row className={'card-tr'}>
								<Col sm="12" className="mb-3">
									<RoutesList allRoutesChecked={allRoutesChecked} deliverType={isDeliverTypeSelected()}  />
									</Col>
								</Row>
								<Row className="mt-4">
									<Col sm="12">
										<div className="text-right">
											<Button
											onClick={onSendNotifications}
											color="primary"
											className="rounded-pill"
											disabled={isDeliverTypeSelected() || _.isEmpty(sentRoutesList)}
											>
											{isLoading ? 'Please wait...' : 'SEND NOTIFICATION'}
											</Button>
										</div>
									</Col>
								</Row>
							</div>
					</CardBody>
				</Card>
			</Container>
		</MainWrapper>
	);
};

export default NotificationCreateView;
