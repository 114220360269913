import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { connect } from "react-redux";
import { Form, Col, Row, Button, Label,Input } from "reactstrap";
import { MdClose } from "react-icons/md";
import _ from "lodash";
import { sendEmailReciept } from "../../../../store/actions/CS_ViewOrderAction";
const EmailTicketsPopup = ({
  modal,
  handleClose,
  sendEmailReciept,
  transactionId,
  orderId,
  setResendTicketsEmail
}) => {
  const [email, setEmail] = useState("");
  const [isSendDisabled, setIsSendDisabled] = useState(true);
  useEffect(() => {
    validateField(email);
  }, [email]);

  const validateField = (email) => {
    let emailRegex = /\S+@\S+\.\S+/;

    if (emailRegex.test(email)) {
      setIsSendDisabled(false);
    } else {
      setIsSendDisabled(true);
    }
  };

  const handleChange = ({ target: { id, value } }) => {
    if (value === "") {
      setIsSendDisabled(true);
    }
    setEmail(value);
  };

  const handleSend = () => {
    const params = {
      email: email,
      transactionId: transactionId,
      orderId: orderId,
    };
    sendEmailReciept(params);
    setResendTicketsEmail(email);
    handleClose();
  };

  return (
    <Modal className="email-tickets-modal" show={modal}>
      <ModalBody>
        <span
          closeButton
          onClick={handleClose}
          className="h2 inline-block -mt-2 cursor-pointer float-right -mt-4 -mr-2"
        >
          <MdClose />
        </span>
        <div className="px-2">
          <span className="font20">Email Ticket(s) &amp; Receipt</span>
          <Form className="mt-3">
            <Label className="font14-b">Email address</Label>
            <Input
              className="font16-b"
              id="email"
              type="email"
              value={email}
              onChange={handleChange}
              placeholder="name@example.com"
            />
          </Form>
          <Row>
            <Col className="text-right mt-3">
              <Button
                outline color="primary"
                className="btn font14-b btn-pill px-4 py-2 mr-2 text-uppercase"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                className="btn-primary font14-b btn-pill px-4 py-2 text-uppercase"
                disabled={isSendDisabled}
                onClick={handleSend}
              >
                Send
              </Button>
            </Col>
          </Row>
        </div>
      </ModalBody>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendEmailReciept: (notes) => sendEmailReciept(notes, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(EmailTicketsPopup);
