import React, { useState } from 'react';
import NotificationCreateView from './NotificationCreateView';
import {
	notificationCreate,
	updateRoutesList,
	notificationEdit,
	notificationEditDate
} from '../../../../store/actions/NotificationAction';
import { fetchGtfsData } from '../../../../store/actions/ViewAction';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import _ from 'lodash';
import  add from 'date-fns/add';
import ConfirmationModal from '../confirmation/ConfirmationModal';
import ErrorNotificationModal from '../ErrorNotification/ErrorNotificationModal';
import useRoleAccess from '../../../../util/hooks/RoleAccess';
import useNetworkStatus from '../../../../util/hooks/NetworkStatus';

const NotificationCreate = ({
	handleNotificationCreate,
	saveNotifications: { saveNotifications },
	sentRoutesList: { sentRoutesList },
	isNotificationsLoading: { isNotificationsLoading },
	saveError: { saveError },
	isEditLoading: { isEditLoading },
	editDateError: { editDateError }
}) => {
	const roleAccess = useRoleAccess();
	const isOnline = useNetworkStatus();
	
	const operators = _.get(roleAccess, 'operators', ["ALL"]);
	const [ state, setState ] = useState({
		title: '',
		message: '',
		description: ''
	});
	const [ fieldsError, setFieldsError ] = useState({
		title: '',
		message: '',
		description: '',
		alertType: '',
		deliverType: '',
		routeList: ''
	});
	const [ selectDateValue, setSelectDateValue ] = useState('now');
	const [ alertTypeValue, setAlertTypeValue ] = useState('Operational');
	const [ deliverType, setDeliverType ] = useState([]);
	const [ startDate, setStartDate ] = useState(new Date());
	const [errorMsg, setErrorMsg] = useState("");
	const [open, setOpen] = useState(false);
	const [openError, setOpenError] = useState(false);
	const [allRoutesChecked, setAllRoutesChecked] = useState(_.find(operators) === "ALL" ? true : false);
	
	const validateForm = () => {
		let formIsValid = true;
		let fieldsError = {};

		if (deliverType.includes('push') && deliverType.includes('sms')) {
			if (_.isEmpty(state.title.trim())) {
				fieldsError.title = 'Please provide title';
				formIsValid = false;
			}
			if (_.isEmpty(state.message.trim())) {
				fieldsError.message = 'Please provide sms message';
				formIsValid = false;
			}
			if (_.isEmpty(state.description.trim())) {
				fieldsError.description = 'Please provide push message';
				formIsValid = false;
			}
		}
		if (!_.includes(deliverType,'sms') ) {
			if (_.isEmpty(state.title.trim())) {
				fieldsError.title = 'Please provide title';
				formIsValid = false;
			}
			if (_.isEmpty(state.description.trim())) {
				fieldsError.description = 'Please provide push message';
				formIsValid = false;
			}
			
		}
		if (!_.includes(deliverType, 'push')) {
			if (_.isEmpty(state.title.trim())) {
				fieldsError.title = 'Please provide a title';
				formIsValid = false;
			}
			if (_.isEmpty(state.message.trim())) {
				fieldsError.message = 'Please provide a short message';
				formIsValid = false;
			}

		}
		if (_.isEmpty(alertTypeValue)) {
			fieldsError.alertType = 'Please select alert type';
			formIsValid = false;
		}
		if (_.isEmpty(deliverType)) {
			fieldsError.deliverType = 'Please select delivery type';
			formIsValid = false;
		}
		if (_.isEmpty(sentRoutesList)) {
			fieldsError.routeList = 'Please select a route';
			formIsValid = false;
		}
		if (_.isEmpty(selectDateValue)) {
			fieldsError.selectDateValue = 'Please select start date';
			formIsValid = false;
		}
		if (!formIsValid) {
			setFieldsError(fieldsError);
		}
		return formIsValid;
	};

	const onSendNotifications = (e) => {
		e.preventDefault();
		window.scrollTo(0, 0);
		if (validateForm()) {
			const data = {
				notificationCategory: alertTypeValue,
				notificationType: deliverType,
				title: state.title.trim(),
				message: state.message.trim(),
				description: state.description.trim(),
				routeList: sentRoutesList,
				startDate: startDate,
				immediate: selectDateValue === 'now' ? true :false,
				sentTo: allRoutesChecked ? 'All':'Custom'
			};
			handleNotificationCreate(data);
		}
	};

	useEffect(
		() => {
			if (!_.isEmpty(saveNotifications)) {
				setErrorMsg('');
				setOpen(true);
				
			}
		},
		[ saveNotifications ]
	);

	useEffect(
		() => {
			if (!isOnline) {
				setErrorMsg('');
				setOpenError(true);
				
			}
		},
		[ isOnline ]
	);

	useEffect(
		() => {
			const fieldsError = Object.assign({}, state.fieldsError);
			if (!_.isEmpty(sentRoutesList)) {
				fieldsError.routeList = '';
				setFieldsError(fieldsError);
				setErrorMsg('');
			}
		},
		[sentRoutesList ]
	);
	
	const onDtChange = (date) => {
		let startDt =new Date(date);
		// startDt = new Date(
		// 	startDt.getTime() + startDt.getTimezoneOffset() * 60000
		// );
		const minDate = add(new Date(), {
			days: 1
		});
		if (selectDateValue === 'later' && startDt < minDate) {
			setStartDate(minDate);
		} else {
			setStartDate(startDt);
		}
		
		
	};

	const handleChange = ({ target: { id, value } }) => {
		const fieldsError = Object.assign({}, state.fieldsError);
		switch (id) {
			case 'title':
				if (typeof value !== 'undefined') {
					fieldsError.title = '';
				}
				break;
			case 'message':
				if (typeof value !== 'undefined') {
					fieldsError.message = '';
				}
				break;
			case 'description':
				if (typeof value !== 'undefined') {
					fieldsError.description = '';
				}
				break;
			default:
				break;
		}
		setFieldsError(fieldsError);
		setErrorMsg('');
		setState({
			...state,
			[id]: value
		});
	};
	const ResetData = () => {
		
		setAlertTypeValue('Operational');
		setDeliverType([]);
		setState({ title: '', message: '', description: '' });
		setSelectDateValue('')
		
	};
	useEffect(
		() => {
			setOpen(false)
		},[]);

	useEffect(
		() => {
			if (!_.isEmpty(saveError)) {
				setErrorMsg(saveError);
				ResetData();
			}
		},
		[saveError ]
	);
	useEffect(
		() => {
			if (!_.isEmpty(editDateError)) {
				setErrorMsg('Error coming on editing data!');
			}
		},
		[ editDateError ]
	);

	const onAlertTypeClick = (e) => {
		fieldsError.alertType = '';
		setFieldsError(fieldsError);
		setErrorMsg('');
		setAlertTypeValue(e.target.value);
	};

	const onSelectAllChange = (e) => {
		if (e.target.checked) {
				setAllRoutesChecked(true);

			} else {
				setAllRoutesChecked(false);
			}
	};

	const onDeliverHandleChange = (e) => {
		setErrorMsg('');
		if (e.target.checked) {
			fieldsError.deliverType = '';
			setFieldsError(fieldsError);
			setDeliverType([...deliverType, e.target.value]);
			// setDeliverType((deliverType) => deliverType.concat(deliverTypes));
		} else {
			setDeliverType(
				_.filter(deliverType, (dl) => dl !== e.target.value)
			);
		}
	};

	const onDateSelectValue = (e) => {
		setErrorMsg('');
		fieldsError.selectDateValue = '';
		setFieldsError(fieldsError);
		setSelectDateValue(e.target.value);
		if (e.target.value === 'now') {
			let nowDate = new Date();
			// nowDate = new Date(
			// 	nowDate.getTime() + nowDate.getTimezoneOffset() * 60000
			// );
			setStartDate(nowDate);
		
		} else {
			let nextDate = add(new Date(), {
				days: 1
			});
			// nextDate = new Date(
			// 	nextDate.getTime() + nextDate.getTimezoneOffset() * 60000
			// );
			
			setStartDate(nextDate);
		}
	};

	const isDeliverTypeSelected = () => {
		if (!_.isEmpty(deliverType) && !_.includes(deliverType, 'push') ? (!_.isEmpty(state.title.trim()) && !_.isEmpty(state.message.trim())) :
			!_.includes(deliverType, 'sms') ? (!_.isEmpty(state.title.trim()) && !_.isEmpty(state.description.trim())) :
				(_.includes(deliverType, 'push') && _.includes(deliverType, 'sms'))  ? (!_.isEmpty(state.title.trim()) && !_.isEmpty(state.message.trim()) && !_.isEmpty(state.description.trim()))
					: ''
		) {
			return false
		}
		return true
	}
	return (
		<div>
			<NotificationCreateView
				state={state}
				handleChange={handleChange}
				onSendNotifications={onSendNotifications}
				fieldsError={fieldsError}
				saveError={saveError}
				deliverType={deliverType}
				onAlertTypeClick={onAlertTypeClick}
				alertTypeValue={alertTypeValue}
				selectDateValue={selectDateValue}
				onDateSelectValue={onDateSelectValue}
				onDeliverHandleChange={onDeliverHandleChange}
				startDate={startDate}
				onDtChange={onDtChange}
				isNotificationsLoading={isNotificationsLoading}
				isEditLoading={isEditLoading}
				errorMsg={!isOnline ? errorMsg : ''}
				onSelectAllChange={onSelectAllChange}
				allRoutesChecked={allRoutesChecked}
				sentRoutesList={sentRoutesList}
				isDeliverTypeSelected={isDeliverTypeSelected}
				
			/>
			<ConfirmationModal message="saved" open={open}/>
			<ErrorNotificationModal message="saved" open={openError}/>
		
		</div>
	);
};

const mapStateToProps = ({
	notificationReducer,
	agencyReducer,
	productFareReducer
}) => {
	return {
		saveNotifications: notificationReducer,
		agencyItems: agencyReducer,
		productFareZone: productFareReducer,
		isAgencyLoading: agencyReducer,
		sentRoutesList: notificationReducer,
		isNotificationsLoading: notificationReducer,
		saveError: notificationReducer,
		editNotifications: notificationReducer,
		isEditLoading: notificationReducer,
		onEditDate: notificationReducer,
		editDateError: notificationReducer
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		handleNotificationCreate: (data) => notificationCreate(data, dispatch),
		handleNotificationEdit: (data) => notificationEdit(data, dispatch),
		handleNotificationEditDate: (data) => notificationEditDate(data, dispatch),
		getRoutesData: (tblKey) => fetchGtfsData(tblKey, dispatch),
		updateRoutesList: (list) => updateRoutesList(list, dispatch)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationCreate);
