import { get } from 'lodash';
import { useSelector } from 'react-redux';

const useUserRole = () => {
   
    const { currentUser } = useSelector(state => state.auth);
    return get(currentUser, 'signInUserSession.idToken.payload["cognito:groups"]', []);
};

export default useUserRole;
