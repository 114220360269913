const appURLConstants = {
	REACT_SAVE_EDIT_TABLES: `${process.env
		.REACT_APP_API_END_POINT_URL}gtfsadmin/synchronize/`,
	REACT_GET_GTFS_DATA_URL: `${process.env
		.REACT_APP_API_END_POINT_URL}admin/gtfs/lookup`,
	REACT_EDIT_GTFS_DATA_URL: `${process.env
		.REACT_APP_API_END_POINT_URL}admin/gtfs/merge`,
	REACT_GET_GTFS_MENUS: `${process.env
		.REACT_APP_API_END_POINT_URL}gtfsadmin/menus`,
	REACT_GET_NOTIFICATIONS: `${process.env
		.REACT_APP_API_END_POINT_URL}admin/notification/view`,
	REACT_GET_NOTIFICATION_MESSAGES: `${process.env
		.REACT_APP_API_END_POINT_URL}admin/notification/get/messagelist?notificationId`,
	REACT_SAVE_TICKET_REDEMPTIONS: `${process.env
		.REACT_APP_API_END_POINT_URL}admin/manageticket`,
	REACT_GET_TICKET_REDEMPTIONS_BY_OREDER: `${process.env
		.REACT_APP_API_END_POINT_URL}order/tickets/`,
	REACT_GET_TICKET_REDEMPTIONS_BY_EMAIL: `${process.env
		.REACT_APP_API_END_POINT_URL}proxy/customer/tickets?email=`,
	REACT_GET_TICKET_REDEMPTIONS_BY_TICKETID: `${process.env
		.REACT_APP_API_END_POINT_URL}customer/ticket/reference`,
	REACT_SAVE_NOTIFICATIONS_URL: `${process.env
		.REACT_APP_API_END_POINT_URL}admin/notification/save`,
	REACT_UPDATE_NOTIFICATIONS_URL: `${process.env
		.REACT_APP_API_END_POINT_URL}admin/notification/edit`,
	REACT_GET_SUBSCRIPTIONS: `https://sandboxapis.coachusa-orbit.com/dummy/lambda`,
	REACT_APP_ADMIN_TEAMS_ENDPOINT: `${process.env
		.REACT_APP_ADMIN_TEAMS_ENDPOINT}admin`,
	REACT_DELETE_NOTIFICATIONS_URL: `${process.env
		.REACT_APP_API_END_POINT_URL}admin/notification/deactivate`,
	REACT_SEARCH_NOTIFICATIONS_URL: `${process.env
		.REACT_APP_API_END_POINT_URL}admin/notification/search`,
	REACT_LOGIN_USER_ACCESS_URL: `${process.env
		.REACT_APP_API_END_POINT_URL}admin/login`,
	// REACT_SEARCH_NOTIFICATIONS_URL: `https://wnylar7ix6.execute-api.us-east-1.amazonaws.com/sandbox/dummy/lambda`
	//CS Portal
	REACT_CS_NOTE_URL: `${process.env.REACT_APP_API_END_POINT_URL}admin/notes`,
	REACT_CS_SEARCH_LIST_URL: `${process.env.REACT_APP_API_END_POINT_URL}admin/search`,
	REACT_CS_CUSTOMER_INFORMATION_URL: `${process.env.REACT_APP_API_END_POINT_URL}admin/customerinfo`,
	REACT_CS_EMAIL_RECIEPT_URL: `${process.env.REACT_APP_API_END_POINT_URL}admin/sendreceipt`,
	REACT_CS_STATE_CODE_URL: `${process.env.REACT_APP_API_END_POINT_URL}customer/countryinfo`,
};
export default appURLConstants;
