import React, { useMemo } from "react";
import { Card, CardTitle, Col, Row, Table } from "reactstrap";
import {
  useBlockLayout,
  usePagination,
  useResizeColumns,
  useTable,
} from "react-table";
import PaginationLeft from "../pagination/PaginationHeader";
import PaginationRight from "../pagination/PaginationRightSide";
import PaginationFooter from "../pagination/PaginationFooter";
import { format, parseISO } from "date-fns";
import _ from "lodash";

function NotesTable({ columns, data }) {
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30,
      width: 100,
      maxWidth: 400,
    }),
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,

    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useBlockLayout,
    useResizeColumns,
    usePagination
  );
  const onNextPage = () => {
    nextPage();
  };
  return (
    <>
      <Row className="productFareListHead">
        {!_.isEmpty(data) && (
          <Col sm="4">
            <PaginationLeft
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              pageOptions={pageOptions}
              pageCount={pageCount}
              gotoPage={gotoPage}
              nextPage={onNextPage}
              previousPage={previousPage}
              setPageSize={setPageSize}
              pageSize={pageSize}
              pageIndex={pageIndex}
              data={data}
            />
          </Col>
        )}
        {!_.isEmpty(data) && _.size(data) > 10 && (
          <Col sm="8">
            <div className="display_record">
              <PaginationRight
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                pageOptions={pageOptions}
                pageCount={pageCount}
                gotoPage={gotoPage}
                nextPage={onNextPage}
                previousPage={previousPage}
                setPageSize={setPageSize}
                pageSize={pageSize}
                pageIndex={pageIndex}
                page={page}
              />
            </div>
          </Col>
        )}
      </Row>
      <div className="table-responsive">
        <Table className="table noteHistoryList" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th className="font14-b" {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>
                        {" "}
                        {cell.column.id === "userEmail"
                          ? _.find(_.split(row.original.userEmail, "@", 1))
                          : cell.render("Header") === "Date"
                            ? format(
                              parseISO(row.original.createdAt),
                              "MMM dd,yyyy"
                            )
                            : cell.render("Header") === "Time"
                              ? format(parseISO(row.original.createdAt), "HH:mm")
                              : cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <div className="text-center">
        {!_.isEmpty(data) && _.size(data) > 10 && (
          <PaginationFooter
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageOptions={pageOptions}
            pageCount={pageCount}
            gotoPage={gotoPage}
            nextPage={onNextPage}
            previousPage={previousPage}
            setPageSize={setPageSize}
            pageSize={pageSize}
            pageIndex={pageIndex}
            page={page}
          />
        )}
      </div>
    </>
  );
}

const NoteHistory = ({ noteHistory }) => {
  const columns = useMemo(
    () => [
      {
        Header: "Username",
        accessor: "userEmail",
        width: 150,
      },
      {
        Header: "Date",
        accessor: "date",
        width: 100,
      },
      {
        Header: "Time",
        accessor: "time",
        width: 100,
      },
      {
        Header: "Ticket ID",
        accessor: "ticketId",
        width: 200,
      },
      {
        Header: "Note Category",
        accessor: "noteCategory",

        width: 130,
      },
      {
        Header: "Description",
        accessor: "noteText",
        width: 400,
      },
    ],
    []
  );

  return (
    <Card className="card-box-shadow-4">
      <CardTitle className="p-2">
        <span className="font16">Note History</span>
      </CardTitle>
      <NotesTable columns={columns} data={noteHistory} />
    </Card>
  );
};

export default NoteHistory;
