import React from "react";
import { Route, Redirect } from "react-router-dom";

export const AppliedRoute = ({ component: Component, user: user, ...rest }) => (
  <Route {...rest} render={props => {
    if (!user) {
       return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    }
    return <Component {...props} />
  }} />
    
);
