import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import {
	Container,
	Card,
	Row,
	Col,
	CardBody,
	Label,
	Input,
	CustomInput,
	Collapse,
	Spinner,
	Modal,
	ModalBody,
	ModalHeader,
} from "reactstrap";
import MainWrapper from "../../../MainWrapper";
import columnFields from "../../../../util/columnFields.json";
import {
	notificationFetch,
	notificationDelete,
	notificationSearch,
} from "../../../../store/actions/NotificationAction";
import { connect } from "react-redux";
import NotificationsGrid from "./NotificationsGrid";
import { fetchAgency } from "../../../../store/actions/AgencyAction";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import _ from "lodash";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import NotificationHeader from "./NotificationHeader";
import Question from "../../../../assets/images/Question.svg";
import dateImg from "../../../../assets/images/cal.svg";
import { formatISO, subDays, add } from "date-fns";
import { useSelector } from "react-redux";
import useRoleAccess from '../../../../util/hooks/RoleAccess';
const { func, object } = PropTypes;

const Dashboard = ({
	viewNotifications: { viewNotifications },
	isLoading: { isLoading },
	handleAgencyData,
	agencyItems: { agencyItems },
	auth: {
		user: { given_name },
	},
	fetchError: { fetchError },
	isViewLoading: { isViewLoading },
	handleDelete,
	deleteNotifications: { deleteNotifications },
	deleteError: { deleteError },
	isAlertDeleteLoading: { isAlertDeleteLoading },
	handleSearch,
	isSearchLoading: { isSearchLoading },
	searchError: { searchError },
	searchData: { searchData },
	user,
}) => {
	const [agencyData, setAgencyData] = useState([]);
	const [fromDate, setFromDate] = useState(subDays(new Date(), 15));
	const [toDate, setToDate] = useState(
		add(new Date(), {
			days: 75,
		})
	);
	const [tableColumns, setTableColumns] = useState({});
	const [isOpen, setIsOpen] = useState(false);
	const [errorMsg, setErrorMsg] = useState("");
	const [deleteMsg, setDeleteMsg] = useState("");
	const [alertId, setAlertId] = useState(0);
	const [modal, setModal] = useState(false);
	const [alertType, setAlertType] = useState([]);
	const [deliveryType, setDeliveryType] = useState([]);
	const [alertStatus, setAlertStatus] = useState("");
	const [operatorCode, setOperatorCode] = useState("");
	const [viewData, setViewData] = useState([]);
	const [routesData, setRoutesData] = useState([]);
	const [userRoute, setUserRoute] = useState("");
	const [routes, setRoutes] = useState("");
	const [minFromDate, setMinFromDate] = useState(subDays(new Date(), 15));
	const [maxToDate, setMaxToDate] = useState(
		add(new Date(), {
			days: 75,
		})
	);
	const toggle = () => setIsOpen(!isOpen);
	const operators = useSelector((state) =>
		_.get(state.auth.notificationAccess, "operators",["ALL"])
	);
	const selectedAlertId = (id) => {
		setAlertId(id);
		setErrorMsg("");
	};
	const getColumns = (tb) => {
		const getColumnKeys = tb;
		if (getColumnKeys) {
			const column = getColumnKeys
				.filter((col) => col.selected)
				.sort((a, b) => (a.order > b.order ? 1 : -1))
				.map((col) => {
					return {
						Header: col.display,
						accessor: col.dbKey,
						isFilter: col.isFilter,
						isSorted: col.isSorted,
						isVisible: col.isVisible,
					};
				});

			return column;
		} else {
			return [];
		}
	};
	const loadColumns = useCallback(() => {
		const menuFields = columnFields;
		const cols = menuFields["notification"];
		setTableColumns(getColumns(cols));
	}, []);
	useEffect(() => {
		if (!_.isEmpty(fetchError)) {
			setErrorMsg("Error coming on search.");
		}
	}, [fetchError]);
	const roleAccess = useRoleAccess();
	useEffect(() => {
		const data = {
			tblKey: "allAgenciesAndRoutes",
		};
		handleAgencyData(data);
	}, []);

	useEffect(() => {
		onSearch();
	}, []);

	useEffect(() => {
		if (!_.isEmpty(operators)) {
			// const userRoutes = _.get(
			// 	JSON.parse(localStorage.getItem('userAccess')),
			// 	'homeCarrier'
			// );
			setUserRoute(_.find(operators));
		}
	}, []);

	useEffect(() => {
		setErrorMsg("");
		setDeleteMsg("");
		setViewData([]);
		loadColumns();
		setViewData(viewNotifications);
	}, [viewNotifications, loadColumns]);

	useEffect(() => {
		const agencyList = !_.isEmpty(agencyItems) ? agencyItems : [];
		if (!_.isEmpty(operators) && _.includes(_.find(operators), "ALL")) {

			setAgencyData(agencyList);
		} else {
			setAgencyData(
				_.filter(agencyList, (ar) => _.includes(ar.operatorCode, _.find(operators)))
			);
		}
	}, [agencyItems]);

	const handleAgency = (e) => {
		setOperatorCode(e.target.value);
		setRoutes("");
		let selectedRoutes = _.find(agencyData, { operatorCode: e.target.value });
		setRoutesData(_.get(selectedRoutes,"routeDetails",[]));
	};

	const handleRoutes = (e) => {
		setRoutes(e.target.value);
	};

	const onFromDateChange = (date) => {
		const fromDate = new Date(date);
		setFromDate(fromDate);
		setToDate(
			add(new Date(fromDate), {
				days: 90,
			})
		);
	};

	const onToDateChange = (date) => {
		const toDate = new Date(date);
		setToDate(toDate);

		const minDate = subDays(new Date(toDate), 90);
		setFromDate(minDate);
		setMinFromDate(minDate);
	};
	const onRowSelect = (selectedRowId) => {
		if (_.isEmpty(selectedRowId)) {
			setAlertId(0);
		}
	};

	const onDelete = () => {
		if (alertId !== 0) {
			setErrorMsg("");
			setDeleteMsg("");
			setModal(true);
		} else {
			setErrorMsg("Please select a row for deletion.");
		}
	};

	// const onEdit = () => {
	// 	//setErrorMsg("Have No access to edit this route.");
	// };
	const handleAlertDelete = () => {
		if (alertId !== 0) {
			const id = {
				messageId: alertId,
			};
			handleDelete(id);
			setModal(false);
		}
	};
	const onCancel = () => {
		setModal(!modal);
		setAlertId(0);
	};

	useEffect(() => {
		if (!_.isEmpty(deleteNotifications)) {
			setAlertId(0);
			onSearch();
		}
	}, [deleteNotifications]);

	const handleAlertType = (e) => {
		setErrorMsg("");
		setDeleteMsg("");
		if (e.target.checked) {
			const alertTypes = e.target.value;
			setAlertType((alertType) => alertType.concat(alertTypes));
		} else {
			setAlertType(_.filter(alertType, (al) => al !== e.target.value));
		}
	};

	const handleDeliveryType = (e) => {
		setErrorMsg("");
		setDeleteMsg("");
		if (e.target.checked) {
			const deliverTypes = e.target.value;
			setDeliveryType((deliveryType) => deliveryType.concat(deliverTypes));
		} else {
			setDeliveryType(_.filter(deliveryType, (dl) => dl !== e.target.value));
		}
	};

	const handleStatusChange = (e) => {
		setAlertStatus(e.target.value !== "" && e.target.value);
	};

	const onSearch = () => {
		const data = {
			search: true,
			notificationCategory: alertType,
			notificationType: deliveryType,
			routeId: routes,
			operatorCode: operatorCode,
			startDate: formatISO(new Date(fromDate), { representation: "complete" }),
			endDate: formatISO(new Date(toDate), { representation: "complete" }),
			status: alertStatus,
		};
		handleSearch(data);
	};

	const onReset = () => {
		setDeliveryType([]);
		setAlertType([]);
		setOperatorCode("");
		
		setAlertStatus("");
		setRoutes("");
		setFromDate(subDays(new Date(), 15));
		setToDate(
			add(new Date(), {
				days: 75,
			})
		);
		setMaxToDate(
			add(new Date(), {
				days: 75,
			})
		);
		setMinFromDate(subDays(new Date(), 15));
		const fromDt = subDays(new Date(), 15);
		const toDt = add(new Date(), {
			days: 75,
		});
		const data = {
			search: true,
			notificationCategory: [],
			notificationType: [],
			routeId: "",
			operatorCode: "",
			startDate: formatISO(new Date(fromDt), { representation: "complete" }),
			endDate: formatISO(new Date(toDt), { representation: "complete" }),
			status: "",
		};
		handleSearch(data);
	};
	useEffect(() => {
		setViewData([]);
		setErrorMsg("");
		setDeleteMsg("");
		setViewData(searchData);
	}, [searchData]);
	useEffect(() => {
		if (!_.isEmpty(searchError)) {
			setViewData([]);
			setErrorMsg(searchError);
		}
	}, [searchError]);
	useEffect(() => {
		if (!_.isEmpty(deleteError)) {
			setAlertId(0);
			setDeleteMsg("Error coming on delete alert.");
		}
	}, [deleteError]);

	const fromDateRef = React.createRef();
	const toDateRef = React.createRef();
	
	return (
		<MainWrapper user={user}>
			<NotificationHeader userName={given_name} />
			{isLoading && (
				<div className="loader">
					<div className="spinner">
						<Spinner animation="border" variant="primary" />
					</div>
				</div>
			)}
			<Container>
				<Row className="mb-5">
					<Col xs="12">
						<Row className="filter_header">
							<Col xs="6" className="text-left">
								<h3>Filter</h3>
							</Col>
							<Col xs="6" className="text-right">
								<span onClick={toggle}>
									{isOpen ? "HIDE ALL FILTERS" : "SHOW ALL FILTERS"}{" "}
									{isOpen ? <FaChevronUp /> : <FaChevronDown />}
								</span>
							</Col>
						</Row>

						<Collapse isOpen={isOpen}>
							<Card className="filter_body">
								<CardBody>
									<Row className="mb-3">
										<Col md="3">
											<span className="font16-b">Alert Type:</span>
											<Col xs="12" className="px-0">
												<Label check>
													<Input
														type="checkbox"
														value="Operational"
														id="alertType"
														checked={alertType.includes("Operational")}
														onChange={handleAlertType}
													/>{" "}
                          Operational
                          <span className="checkBoxMark"></span>
												</Label>
											</Col>
											<Col xs="12" className="px-0">
												{/* <Label check>
														<Input
															type="checkbox"
															value="Marketing"
															id="alertType"
															checked={alertType.includes('Marketing')}
															onChange={handleAlertType}
														/>{' '}
																	Marketing and Promotional
																	<span className="checkBoxMark"></span>
													</Label> */}
											</Col>
										</Col>
										<Col md="4" className="offset-md-1">
											<span className="font16-b">Deliver Type:</span>
											<Col xs="12" className="px-0">
												<Label check>
													<Input
														type="checkbox"
														value="push"
														id="deliverType"
														checked={deliveryType.includes("push")}
														onChange={handleDeliveryType}
													/>{" "}
                          Push Notification
                          <span className="checkBoxMark"></span>
												</Label>
											</Col>
											<Col xs="12" className="px-0">
												<Label check>
													<Input
														type="checkbox"
														value="sms"
														id="deliverType"
														checked={deliveryType.includes("sms")}
														onChange={handleDeliveryType}
													/>{" "}
                          Text SMS
                          <span className="checkBoxMark"></span>
												</Label>
											</Col>
										</Col>

										<Col md="3" className="offset-md-1">
											<span className="font16-b">Date</span>
											<div className="datePicker_ico">
												<DatePicker
													value={fromDate}
													selected={fromDate}
													onChange={onFromDateChange}
													dateFormat="MMMM d, yyyy"
													popperClassName="some-custom-class"
													popperPlacement="top-end"
													className="form-control mb-2 form-control-lg"
													maxDate={maxToDate}
													//minDate={minFromDate}
													ref={fromDateRef}
												/>
												<img
													src={dateImg}
													alt="dateImage"
													onClick={() => {
														fromDateRef.current.setOpen(true);
													}}
												/>
											</div>
											<div className="datePicker_ico">
												<DatePicker
													value={toDate}
													selected={toDate}
													onChange={onToDateChange}
													dateFormat="MMMM d, yyyy"
													popperClassName="some-custom-class"
													popperPlacement="top-end"
													className="form-control form-control-lg"
													//maxDate={maxToDate}
													minDate={minFromDate}
													ref={toDateRef}
												/>
												<img
													src={dateImg}
													alt="dateImage"
													onClick={() => {
														toDateRef.current.setOpen(true);
													}}
												/>
											</div>
										</Col>
									</Row>
									<Row>
										<Col md="3">
											<span className="font16-b">Filter by Agency</span>
											<CustomInput
												type="select"
												id="operatorCode"
												value={operatorCode}
												onChange={handleAgency}
												className="form-control-lg"
											>
												<option value="">Select Agency</option>
												{_.map(agencyData, (agency, a) => (
													<option
														key={a}
														id={agency.agencyId}
														value={agency.operatorCode}
													>
														{agency.agencyName}
													</option>
												))}
											</CustomInput>
										</Col>

										<Col md="4" className="offset-md-1">
											<span className="font16-b">Filter by Route</span>

											<CustomInput
												type="select"
												id="routeId"
												value={routes}
												onChange={handleRoutes}
												className="form-control-lg"
											>
												<option value="">Select Route</option>
												{_.map(routesData, (route, i) => (
													<option
														key={i}
														id={route.routeId}
														value={route.routeId}
													>
														{route.routeLongName}
													</option>
												))}
											</CustomInput>
										</Col>

										<Col md="3" className="offset-md-1">
											<span className="font16-b"> Select Status</span>

											<CustomInput
												type="select"
												id="alertStatus"
												value={alertStatus}
												onChange={handleStatusChange}
												className="form-control-lg"
											>
												<option value="">Select Status</option>
												<option value="Draft">Draft</option>
												<option value="Sent">Sent</option>
											</CustomInput>
										</Col>
									</Row>
									<Row>
										<Col xs="8"></Col>

										<Col xs="4" className="text-right">
											<span className="bold">&nbsp;</span>
											<div className="clearfix" />

											<button
												className="btn btn-outline-primary rounded-pill no-border mr-2"
												onClick={onReset}
											>
												RESET
                      </button>
											<button
												className="btn btn-primary rounded-pill"
												onClick={onSearch}
											>
												SEARCH
                      </button>
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Collapse>
					</Col>
				</Row>

				<Row>
					<Col>
						<h4 className="font24-b text-uppercase">Alert List</h4>
					</Col>
				</Row>
				<Row>
					<Col xs="12">
						<div
							className={
								!_.isEmpty(errorMsg) || !_.isEmpty(deleteMsg) ? "error-msg" : ""
							}
						>
							{errorMsg}
							{deleteMsg}
						</div>
					</Col>
				</Row>
				<Row>
					{(isViewLoading || isAlertDeleteLoading || isSearchLoading) && (
						<div className="loader">
							<div className="spinner">
								<Spinner animation="border" variant="primary" />
							</div>
						</div>
					)}
					<Col xs="12">
						{!_.isEmpty(tableColumns) ? (
							<NotificationsGrid
								columns={tableColumns}
								data={viewData}
								menuId="notifications"
								onDelete={onDelete}
								selectedAlertId={selectedAlertId}
								pageSize={10}
								alertId={alertId}
								setAlertId={setAlertId}
								userRoute={userRoute}
								onRowSelect={onRowSelect}
								roleAccess={roleAccess}
							/>
						) : null}
					</Col>
				</Row>
			</Container>
			<Modal
				className="journal-modal"
				isOpen={modal}
				toggle={() => setModal(!modal)}
			//onHide={() => setModal(!modal)}
			>
				<ModalHeader closeButton />
				<ModalBody>
					<img src={Question} alt="GroupImg" />
					<p>Are you sure you</p>
					<p>want to Delete</p>
					<p>this record?</p>
					<div className="text-center mt-8">
						<button onClick={handleAlertDelete}>Yes</button>
					</div>
					<div className="text-center" onClick={onCancel}>
						<span className="logout-cancel">Cancel</span>
					</div>
				</ModalBody>
			</Modal>
		</MainWrapper>
	);
};

Dashboard.propTypes = {
	notificationFetch: func,
	notification: object,
};

const mapStateToProps = ({
	notificationReducer,
	agencyReducer,
	auth,
	viewGtfsReducer,
}) => {
	return {
		auth,
		viewNotifications: notificationReducer,
		agencyItems: agencyReducer,
		fetchError: notificationReducer,
		isViewLoading: notificationReducer,
		isAlertDeleteLoading: notificationReducer,
		deleteError: notificationReducer,
		deleteNotifications: notificationReducer,
		isSearchLoading: notificationReducer,
		searchError: notificationReducer,
		searchData: notificationReducer,
		isLoading: viewGtfsReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getNotifications: () => notificationFetch(dispatch),
		handleAgencyData: (tblKey) => fetchAgency(tblKey, dispatch),
		handleDelete: (id) => notificationDelete(id, dispatch),
		handleSearch: (data) => notificationSearch(data, dispatch),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
