import { get } from 'lodash';
import { useSelector } from 'react-redux';

const useUserPermissions = () => {

    const { currentUser } = useSelector(state => state.auth);
    return get(currentUser, 'signInUserSession.idToken.payload["permissions"]', []);
};

export default useUserPermissions;
