import React,{useMemo} from "react";
import { Modal } from "react-bootstrap";
import {  Row,Col,Button } from "reactstrap";
import List from "../list/CustomerSupportGrid";
import _ from "lodash";
const FailedTransPopup = ({
    modalOpen,
    handleClose,
    data,
     isFailedTransaction
}) => {
    const columns = useMemo(
        () => [
            {
                Header: "Transaction Date",
                accessor: "transactionOn",
                width: 150,
            },
            {
                Header: "Failed Reason",
                accessor: "failedReason",
                width: 145,
            },

            {
                Header: "Failed Description",
                accessor: "failedDescription",
                width: 200,
            },
            
        ],
        []
    );
    return (
        <Modal className={_.isEmpty(data) ? "no-results-modal" : "add-notes-modal"} show={modalOpen}>
            <Modal.Header closeButton onClick={handleClose}></Modal.Header>
            <div className="content">
                {_.isEmpty(data) ?
                    <>
                        <Row>
                            <Col className="text-center">
                                <span className="font16-b">Failed transaction details not found</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center">
                                <Button className="button-search font16-b" onClick={handleClose}>
                                    OK
                            </Button>
                            </Col>
                        </Row>
                    </>
                    :
                    <>
                    <Row>
                            <Col className="text-center">
                                <span className="font16-b text-uppercase">Failed transaction details</span>
                            </Col>
                        </Row>
                    <List
                        data={data}
                            columns={columns}
                            isFailedTransaction={isFailedTransaction}
                        />
                        </>
                }
            </div>
        </Modal>
    );
};


export default FailedTransPopup;
