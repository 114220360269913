import {
	LOGIN_SUCCESS,
	LOGIN_FAILED,
	SESSION_UPDATE_FAILED,
	SESSION_UPDATE_SUCCESS,
	LOGIN_PENDING,
	SESSION_UPDATE_PENDING,
	LOGOUT_SUCCESS,
	LOGOUT_FAILED,
	LOGIN_USER_ACCESS_PENDING,
	LOGIN_USER_ACCESS_SUCCESS,
	LOGIN_USER_ACCESS_FAILED,
	USER_EMPTY_SUCCESS
} from '../../util/constant';
import { Auth } from 'aws-amplify';
import { adminLogin } from '../../util/func';
import _ from 'lodash';

export const login = async (data, dispatch) => {
	try {
		dispatch({
			type: LOGIN_PENDING
		});
		const { username, password } = data;
		const response = await Auth.signIn(username, password);
		dispatch({
			type: LOGIN_SUCCESS,
			payload: response
		});
	} catch (error) {
		dispatch({
			type: LOGIN_FAILED,
			payload: error
		});
	}
};

//Current User session Manage
export const getUser = async (dispatch) => {
	try {
		dispatch({
			type: SESSION_UPDATE_PENDING
		});
		const resp = await Auth.currentAuthenticatedUser();
		dispatch({
			type: SESSION_UPDATE_SUCCESS,
			payload: resp
		});
	} catch (error) {
		dispatch({
			type: SESSION_UPDATE_FAILED,
			payload: error
		});
	}
};

export const loginUserAccess = async (data, dispatch) => {
	try {
		dispatch({
			type: LOGIN_USER_ACCESS_PENDING
		});
		// const response = await api.post(
		// 	`${appURLConstant.REACT_LOGIN_USER_ACCESS_URL}`,
		// 	data
		// );
		const response = await adminLogin(data)
		const result = response.body;
		result.statusCode === 400 || result.statusCode === 403 ||
			!_.isEmpty(result.errorMessage) ||
			_.isEmpty(result) || result===""
			? dispatch({
					type: LOGIN_USER_ACCESS_FAILED,
					payload:
						result && 'Access not allowed!'
				})
			: 
				dispatch({
					type: LOGIN_USER_ACCESS_SUCCESS,
					payload: result
				});
	} catch (error) {
		dispatch({
			type: LOGIN_USER_ACCESS_FAILED,
			payload: error
		});
	}
};

export const logout = async (dispatch) => {
	try {
		await Auth.signOut();
		dispatch({
			type: LOGOUT_SUCCESS
		});
	} catch (error) {
		dispatch({
			type: LOGOUT_FAILED,
			payload: error
		});
	}
};

export const manageUnauthenticatedUser = async (dispatch) => {
		dispatch({
			type: USER_EMPTY_SUCCESS
		});
};

