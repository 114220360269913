import React, { useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Collapse,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Button,
} from "reactstrap";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dateImg from "../../../assets/images/cal.svg";
import { subDays, add } from "date-fns";
import { format } from "date-fns";
import { setSearchInputValues } from "../../../store/actions/CS_SearchAction";
import { connect, useSelector } from "react-redux";
import { toLower } from "lodash";

const Search = ({
  onClear,
  onSearch,
  isTravelHistory,
  isFailedTransaction,
  setSearchInputValues,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [fromDate, setFromDate] = useState(subDays(new Date(), 90));
  const [searchFields, setsearchFields] = useState({
    orderNumber: "",
    ticketId: "",
    customerName: "",
    emailAddress: "",
    wellsFargoReferenceNumber: "",
    totalOrderAmount: "",
  });

  const [fieldEdited, setfieldEdited] = useState("");
  const [isSearchDisabled, setIsSearchDisabled] = useState(true);
  const [toDate, setToDate] = useState(new Date());
  const [minFromDate, setMinFromDate] = useState(subDays(new Date(), 90));
  const { searchInputValues } = useSelector((state) => state.csSearchReducer);

  const validateField = (id) => {
    let nameRegex = /^(?=.*[a-zA-Z ])[a-zA-Z ]{3,}$/;
    let emailRegex = /\S+@\S+\.\S+/;
    let amountRegex = /\d+\.\d{2}$/;
    switch (id) {
      case "orderNumber":
        if (searchFields[id].length >= 8) {
          setIsSearchDisabled(false);
        } else {
          setIsSearchDisabled(true);
        }
        break;

      case "ticketId":
        if (searchFields[id].length >= 8) {
          setIsSearchDisabled(false);
        } else {
          setIsSearchDisabled(true);
        }
        break;

      case "wellsFargoReferenceNumber":
        if (searchFields[id].length >= 8) {
          setIsSearchDisabled(false);
        } else {
          setIsSearchDisabled(true);
        }
        break;
      case "customerName":
        if (nameRegex.test(searchFields[id])) {
          setIsSearchDisabled(false);
        } else {
          setIsSearchDisabled(true);
        }
        break;

      case "emailAddress":
        if (emailRegex.test(searchFields[id])) {
          setIsSearchDisabled(false);
        } else {
          setIsSearchDisabled(true);
        }
        break;
      case "totalOrderAmount":
        if (amountRegex.test(searchFields[id])) {
          setIsSearchDisabled(false);
        } else {
          setIsSearchDisabled(true);
        }
        break;
      default:
        setIsSearchDisabled(true);
        break;
    }
  };
  useEffect(() => {
    setfieldEdited(searchInputValues.fieldEdited);
    setsearchFields(searchInputValues.searchFields);
    setToDate(searchInputValues.toDate);
    setFromDate(searchInputValues.fromDate);
    validateField(fieldEdited);
  }, [searchInputValues]);
  const onHandleSearch = () => {
    const searchType = isTravelHistory
      ? "searchTravelHistory"
      : isFailedTransaction
      ? "searchFailedTransaction"
      : "searchOrder";
    const data = {
      searchType: searchType,
      fromDate: format(new Date(fromDate), "yyyy-MM-dd"),
      toDate: format(new Date(toDate), "yyyy-MM-dd"),
      additionalFilterKey: fieldEdited,
      additionalFilterValue:
        fieldEdited === "emailAddress"
          ? toLower(searchFields[fieldEdited])
          : searchFields[fieldEdited],
    };
    const searchParams = {
      fieldEdited: fieldEdited,
      searchFields: searchFields,
      fromDate: fromDate,
      toDate: toDate,
    };
    setSearchInputValues(searchParams);
    onSearch(data);
  };

  const handleChange = ({ target: { id, value } }) => {
    setfieldEdited(id);
    if (value === "") {
      setfieldEdited("");
      setIsSearchDisabled(true);
    }

    setsearchFields((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };
  useEffect(() => {
    validateField(fieldEdited);
  }, [fieldEdited, searchFields]);

  const toggle = () => setIsOpen(!isOpen);

  const onFromDateChange = (date) => {
    const fromDate = new Date(date);
    setFromDate(fromDate);
    const toDateCheck = add(new Date(fromDate), {
      days: 90,
    });
    if (toDateCheck >= new Date()) {
      setToDate(new Date());
    } else if (fromDate !== new Date()) {
      setToDate(
        add(new Date(fromDate), {
          days: 90,
        })
      );
    }
  };

  const onToDateChange = (date) => {
    const toDate = new Date(date);
    setToDate(toDate);

    const minDate = subDays(new Date(toDate), 90);
    setFromDate(minDate);
    setMinFromDate(minDate);
  };
  const onReset = () => {
    setFromDate(subDays(new Date(), 90));
    setToDate(new Date());
    setMinFromDate(subDays(new Date(), 15));

    setfieldEdited("");
    setsearchFields({
      orderNumber: "",
      ticketId: "",
      customerName: "",
      emailAddress: "",
      wellsFargoReferenceNumber: "",
      totalOrderAmount: "",
    });
    setIsSearchDisabled(true);
    const searchParams = {
      fieldEdited: "",
      searchFields: {
        orderNumber: "",
        ticketId: "",
        customerName: "",
        emailAddress: "",
        wellsFargoReferenceNumber: "",
        totalOrderAmount: "",
      },
      toDate: new Date(),
      fromDate: subDays(new Date(), 90),
    };
    setSearchInputValues(searchParams);
    onClear();
  };

  const fromDateRef = React.createRef();
  const toDateRef = React.createRef();
  return (
    <>
      <Row className="mb-4">
        <Col xs="12">
          <Row className="filter_header">
            <Col xs="6" className="text-left">
              <h3 className="font24-b">SEARCH OPTIONS</h3>
            </Col>
            <Col xs="6" className="text-right">
              <span onClick={toggle}>
                {isOpen ? "HIDE ALL FILTERS" : "SHOW ALL FILTERS"}{" "}
                {isOpen ? <FaChevronUp /> : <FaChevronDown />}
              </span>
            </Col>
          </Row>

          <Collapse isOpen={isOpen}>
            <Card className="filter_body">
              <CardBody className="p-4 mx-2">
                <Form>
                  <Row>
                    <Col md={6} hidden={isFailedTransaction || isTravelHistory}>
                      <FormGroup>
                        <Label className="font14-b mb-2">Order Number</Label>
                        <Input
                          onChange={handleChange}
                          disabled={
                            fieldEdited && fieldEdited !== "orderNumber"
                          }
                          className="form-control-lg"
                          type="text"
                          value={searchFields.orderNumber}
                          id="orderNumber"
                          placeholder="Order number"
                        />
                      </FormGroup>
                    </Col>
                    <Col
                      md={isTravelHistory ? 4 : 6}
                      hidden={isFailedTransaction}
                    >
                      <FormGroup>
                        <Label className="font14-b mb-2">Ticket ID</Label>
                        <Input
                          onChange={handleChange}
                          disabled={fieldEdited && fieldEdited !== "ticketId"}
                          className="form-control-lg"
                          type="text"
                          value={searchFields.ticketId}
                          id="ticketId"
                          placeholder="Ticket ID"
                        />
                      </FormGroup>
                    </Col>

                    <Col md={isTravelHistory ? 4 : isFailedTransaction ? 4 : 6}>
                      <FormGroup>
                        <Label className="font14-b mb-2">Email Address</Label>
                        <Input
                          onChange={handleChange}
                          disabled={
                            fieldEdited && fieldEdited !== "emailAddress"
                          }
                          className="form-control-lg"
                          type="email"
                          value={searchFields.emailAddress}
                          id="emailAddress"
                          placeholder="Email address"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6} hidden={isFailedTransaction || isTravelHistory}>
                      <FormGroup>
                        <Label className="font14-b mb-2">
                          Wells Fargo Reference Number
                        </Label>
                        <Input
                          onChange={handleChange}
                          disabled={
                            fieldEdited &&
                            fieldEdited !== "wellsFargoReferenceNumber"
                          }
                          className="form-control-lg"
                          type="text"
                          value={searchFields.wellsFargoReferenceNumber}
                          id="wellsFargoReferenceNumber"
                          placeholder="Wells fargo reference number"
                        />
                      </FormGroup>
                    </Col>

                    <Col md={12} hidden={isTravelHistory}>
                      <hr />
                    </Col>

                    <Col md={12} hidden={isTravelHistory}>
                      <span className="font14-b mb-2">
                        {isFailedTransaction
                          ? "Transition Date"
                          : "Purchase Date"}
                      </span>
                    </Col>

                    <Col md={2} hidden={isTravelHistory}>
                      <div className="datePicker_ico">
                        <DatePicker
                          value={fromDate}
                          selected={fromDate}
                          onChange={onFromDateChange}
                          dateFormat="d MMM, yyyy"
                          popperClassName="some-custom-class"
                          popperPlacement="top-end"
                          className="form-control mb-2 form-control-lg"
                          maxDate={new Date()}
                          //minDate={minFromDate}
                          ref={fromDateRef}
                        />
                        <img
                          src={dateImg}
                          alt="dateImage"
                          onClick={() => {
                            fromDateRef.current.setOpen(true);
                          }}
                        />
                      </div>
                    </Col>
                    <Col md={2} hidden={isTravelHistory}>
                      <div className="datePicker_ico">
                        <DatePicker
                          value={toDate}
                          selected={toDate}
                          onChange={onToDateChange}
                          dateFormat="d MMM, yyyy"
                          popperClassName="some-custom-class"
                          popperPlacement="top-end"
                          className="form-control form-control-lg"
                          maxDate={new Date()}
                          minDate={minFromDate}
                          ref={toDateRef}
                        />
                        <img
                          src={dateImg}
                          alt="dateImage"
                          onClick={() => {
                            toDateRef.current.setOpen(true);
                          }}
                        />
                      </div>
                    </Col>
                    <Col
                      md={isTravelHistory ? 4 : isFailedTransaction ? 8 : 8}
                      className="text-right"
                    >
                      {isTravelHistory ? (
                        <Row className="mt-1">
                          <Col>&nbsp;</Col>
                        </Row>
                      ) : (
                        ""
                      )}
                      <div className="clearfix" />

                      <Button
                        outline
                        color="primary"
                        className="btn rounded-pill no-border mr-2 py-2 px-5"
                        onClick={onReset}
                      >
                        RESET
                      </Button>
                      <Button
                        className="btn btn-primary rounded-pill mr-2 py-2 px-5"
                        onClick={() => onHandleSearch()}
                        disabled={isSearchDisabled}
                      >
                        SEARCH
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Collapse>
        </Col>
      </Row>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSearchInputValues: (params) => setSearchInputValues(params, dispatch),
  };
};
export default connect(null, mapDispatchToProps)(Search);
