import React, { useEffect } from "react";
import { useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { connect } from "react-redux";
import { MdClose } from "react-icons/md";
import { updateNoteHistory } from "../../../../store/actions/CS_ViewOrderAction";
import { Col, Row, Form, FormGroup, Label, Input, Button } from "reactstrap";
import _ from "lodash";
import useUserAccessDetails from '../../../../util/hooks/UserAccessDetails';
import Select from "react-select";

const AddNotesPopup = ({
  modal,
  handleClose,
  ticketId,
  isAllOverNotes,
  addNotes,
  setAutomatedNotes
}) => {
  const [notes, setNotes] = useState({
    ticketId: ticketId,
    category: "",
    note: "",
  });
  const userAccessDetails = useUserAccessDetails();
  const [isAddEnabled, setisAddEnabled] = useState(false);
  const [notesAccess, setNotesAccess]=useState({})
  const handleSelectChange = (newValue) => {
    setNotes((prevState) => ({
      ...prevState,
      category: newValue.value,
    }));
  };
  const options =
    _.get(notesAccess, "addRefundAccess", true) ?
      [
        { value: "Refund", label: "Refund Ticket" },
        { value: "Others", label: "Other Note" }
      ]
      :
      [
        { value: "Others", label: "Other Note" }
      ];
  
  useEffect(() => {
    if (!_.isEmpty(userAccessDetails)) {
      setNotesAccess(_.get(userAccessDetails, "csViewDetails", {}))
    }
  }, []);
  useEffect(() => {
    if (notes.category !== "" && notes.note.length > 3) {
      setisAddEnabled(true);
    }
  }, [notes.category, notes.note]);

  useEffect(() => {
    setNotes((prevState) => ({
      ...prevState,
      ticketId: ticketId,
    }));
  }, [ticketId]);

  const handleChange = ({ target: { id, value } }) => {
    setNotes((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleAdd = async () => {
    const noteData = {
       ticketId: isAllOverNotes ? "" : notes.ticketId,
       noteText: notes.note,
       noteCategory: notes.category,
    };
    setAutomatedNotes(false);
    addNotes(noteData);
    handleClose();
  };

  return (
    <Modal className="add-notes-modal" show={modal}>
      <ModalBody>
        <span
          onClick={handleClose}
          className="h2 inline-block -mt-2 cursor-pointer float-right -mt-4 -mr-2"
        >
          <MdClose />
        </span>
        <div className="px-2">
          <span className="font20">Add Notes</span>
          <Form className="mt-2 pb-2">
            <Row form>
              <Col className="ticket_label" md={12} hidden={isAllOverNotes}>
                <span className="font14-b"> Ticket ID:  </span>
                <Label className="font16-b"> {notes.ticketId} </Label>
              </Col>
            </Row>
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <Label className="font14-b">Select Note Category</Label>
                  <Select
                    className="react-select-dropdown"
                    options={options}
                    placeholder="Select any category"
                    onChange={handleSelectChange}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row form>
              <Col md={12}>
                <FormGroup>
                  <Label className="font14-b">Add a Note</Label>
                  <Input
                    onChange={handleChange}
                    type="textarea"
                    id="note"
                    placeholder="Enter a note here"
                    maxLength="200"
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col className="text-right">
                <Button
                  outline
                  color="primary"
                  className="btn font14-b btn-pill px-4 py-2 mr-2 text-uppercase"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  className="btn-primary font14-b btn-pill px-4 py-2 text-uppercase"
                  disabled={!isAddEnabled}
                  onClick={handleAdd}
                >
                  Add
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </ModalBody>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateNoteHistory: (notes) => updateNoteHistory(notes, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(AddNotesPopup);
