import React from "react";
import { Modal } from "react-bootstrap";
import searchIcon from "../../../../assets/images/search.svg";

import { Col, Row, Button } from "reactstrap";
const NoResultsPopup = ({ modalOpen, handleClose }) => {
  return (
    <Modal className="no-results-modal" show={modalOpen}>
      <Modal.Header closeButton onClick={handleClose}></Modal.Header>
      <div className="content">
        <Row className="my-3">
          <Col className="text-center">
            <img src={searchIcon} alt="searchicon" />
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <span className="font24-b">No Results Found</span>
          </Col>
        </Row>
        <Row>
          <Col className="text-center mx-2 mt-1">
            <span className="font18">
              No captured orders found. Would you like to search
              unconfirmed/failed orders?
            </span>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <Button className="button-search font16-b" onClick={handleClose}>
              SEARCH
            </Button>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default NoResultsPopup;
