import React, { useMemo, useState, useEffect } from "react";
import { Col, Container, Row, Spinner } from "reactstrap";
import MainWrapper from "../../../MainWrapper";
import Breadcrumbs from "../navigation/Breadcrumbs";
import NoResultsPopup from "../modal/NoResultsPopup";
import Navigation from "../navigation/Navigation";
import List from "../list/CustomerSupportGrid";
import { connect, useSelector } from "react-redux";
import { getCustomerSupportList } from "../../../../store/actions/CS_SearchAction";
import { useHistory } from "react-router-dom";
import Search from "../Search";
import _ from "lodash";

const View = ({ getCSList}) => {
  const [data, setData] = useState([]);
  const [isTravelHistory, setIsTravelHistory] = useState(false)
  const [filters, setFilters] = useState(false);
  const [noResultsPopup, setnoResultsPopup] = useState(false);
  const { isSearchLoading, listData, searchError } = useSelector(
    (state) => state.csSearchReducer
  );
  const history = useHistory();
  const columns = useMemo(
    () => [
      {
        Header: "Email Address",
        accessor: "email",
        width: 180,
      },
      {
        Header: "Ticket Number",
        accessor: "ticket_id",
        width: 250,
      },
      {
        Header: "Action",
        accessor: "action",
        width: 100,
      },
      {
        Header: "Scan Date Time",
        accessor: "scan_date_time",
        width: 200,
      },
      {
        Header: "Scan Details",
        accessor: "scan_details",
        width: 400,
      },
    ],
    []
  );
  useEffect(() => {
    setData([]);
    setFilters(false);
    setnoResultsPopup(false);
    const path = history.location.pathname;
    setIsTravelHistory(path === "/travelhistory" && true)
  }, []);

  const onSearch = (data) => {
    getCSList(data);
    setFilters(!_.isEmpty(data.additionalFilterKey) && true);
  };

  useEffect(() => {
    setData([]);
    const travelData = !_.isEmpty(listData) && _.get(listData, "searchTravelHistory", [])
    if (filters && (_.includes(travelData, "no data") || _.isEmpty(travelData)) && !isSearchLoading) {
      setnoResultsPopup(true);
    } else {
      _.mapValues(travelData, (v) => setData((prev) => [...prev, ...v]));
    }
  }, [listData, filters, isSearchLoading]);

  useEffect(() => {
    if (!_.isEmpty(searchError)) setnoResultsPopup(true);
  }, [searchError]);

  const onClear = () => {
    setData([]);
  };

  return (
    <MainWrapper>
      {isSearchLoading && (
        <div className="loader">
          <div className="spinner">
            <Spinner animation="border" variant="primary" />
          </div>
        </div>
      )}
      <Container>
        <Breadcrumbs isTravelHistory={isTravelHistory} />
        {noResultsPopup && (
          <NoResultsPopup
            modalOpen={noResultsPopup}
            handleClose={() => setnoResultsPopup(!noResultsPopup)}
          />
        )}
        <Search isTravelHistory={isTravelHistory} onSearch={onSearch} onClear={() => onClear()} />
        <Navigation isTravelHistory={isTravelHistory} />
        {!_.isEmpty(data) && (
          <>
            <Row className="mt-4">
              <Col>
                <span className="font24-b">TRAVEL DETAILS</span>
              </Col>
            </Row>
            <List
              data={data}
              columns={columns}
              isTravelHistory={isTravelHistory}
             
            />
          </>
        )}
      </Container>
    </MainWrapper>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCSList: (params) => getCustomerSupportList(params, dispatch),
   
  };
};
export default connect(null, mapDispatchToProps)(View);
