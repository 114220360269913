import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Card, CardTitle, Col, Row } from "reactstrap";
import WarningPopup from "../modal/WarningPopup";
import AddNotesPopup from "../modal/AddNotesPopup";
import AllSetPopup from "../modal/AllSetPopup";
import _, { replace } from "lodash";
import { format } from "date-fns";
import {
  setRedemptionsTicketInfo,
  getTicketRedemptions,
} from "../../../../store/actions/TicketRedemAction";
import { connect, useSelector } from "react-redux";
import TicketTable from "../list/TicketTable";
import useUserAccessDetails from '../../../../util/hooks/UserAccessDetails';

const TicketInformation = ({
  ticketsData,
  setRedemptionsTicketInfo,
  updateTicket,
  orderId,
  getTicketRedemptions,
  addNotes,
  onAutomatedNotes,
  setAutomatedNotes
}) => {
  const [rowSelected, setRowSelected] = useState(-1);
  const [rowData, setSelectedRowData] = useState("");
  const [isAddNote, setIsAddNote] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [activeButton, setActiveButton] = useState("");
  const [updatedTicketsData, setUpdatedTicketsData] = useState(false);
  const [onRefund, setOnRefund] = useState(false);
  const [onAddNote, setOnAddNote] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [redemptionsData, setRedemptionsData] = useState([]);
  const [allSetMessage, setAllSetMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [redeemedCount, setRedeemedCount] = useState("");
  const [ticketAccess, setTicketAccess] = useState({});
  const { savedRedeemResponse, saveTicketRedeemError } = useSelector(
    (state) => state.ticketRedemReducer
  );
  const { currentUser } = useSelector((state) => state.auth);
  const userAccessDetails = useUserAccessDetails();
  const onRowSelected = (row, pageIndex) => {
    if (row.id !== rowSelected) {
      setRedemptionsData(ticketsData);
      setRowSelected(-1);
      setSelectedRowData("");
      setActiveButton("");
    }
    setRowSelected(row.id);
    setSelectedRowData(row.original);
    setCurrentPage(pageIndex);
  };
  useEffect(() => {
    if (!_.isEmpty(userAccessDetails)) {
      setTicketAccess(_.get(userAccessDetails, "csViewDetails", {}))
    }
  }, []);
  useEffect(() => {
    setRedemptionsData(ticketsData);
  }, [ticketsData]);

  const addAutomatedNotes = (noteText) => {
    const noteData = {
      ticketId: rowData.id,
      noteText: noteText,
      noteCategory: "ChangedRides",
    };
    onAutomatedNotes(noteData);
  }
  useEffect(() => {
    if (!_.isEmpty(savedRedeemResponse)) {
      if (savedRedeemResponse.orbitResponse.body.status === "success") {
        setRowSelected(-1);
        setUpdatedTicketsData(true);
        setActiveButton("");
        setRedemptionsTicketInfo([], "");
        setAllSetMessage("Your ticket information has been updated successfully.")
        setIsError(false)
        const data = {
          searchText: orderId,
          searchBy: "Order Id",
          profile: false,
        };
        if (!_.isEmpty(orderId)) {
          getTicketRedemptions(data);
        }
      } else {
        setUpdatedTicketsData(false);
      }
      if (_.get(ticketAccess, "automatedNotesAccess", true)) {
        const userName = _.get(currentUser, "signInUserSession.idToken.payload", "");
        const name = !_.isEmpty(userName.given_name) ? userName.given_name : "" + " " + !_.isEmpty(userName.family_name) ? userName.family_name : "";
        addAutomatedNotes(`${name} on  ${format(new Date(), "MM/dd/yyyy")} at ${format(new Date(), "HH: mm")} changed Ticket Number ${rowData.id} by ${activeButton === "plus" ? "-" : "+"}${redeemedCount} Trips.`)
      }
     
    }
  }, [savedRedeemResponse]);
  
  useEffect(() => {
    if (!_.isEmpty(saveTicketRedeemError)) {
        setRowSelected(-1);
        setUpdatedTicketsData(true);
        setActiveButton("");
        setRedemptionsTicketInfo([], "");
        setAllSetMessage("Ticket information has not been updated.")
      setIsError(true)
      if (_.get(ticketAccess, "automatedNotesAccess", true)) {
        const userName = _.get(currentUser, "signInUserSession.idToken.payload", "");
        const name = !_.isEmpty(userName.given_name) ? userName.given_name : "" + " " + !_.isEmpty(userName.family_name) ? userName.family_name : "";
        addAutomatedNotes(`${name} on  ${format(new Date(), "MM/dd/yyyy")} at ${format(new Date(), "HH: mm")} error coming on changed Ticket Number ${rowData.id}`)
      }
      
    }
  }, [saveTicketRedeemError]);

  const handleRefundClick = () => {
    setOnRefund(true);
    if (_.isEmpty(activeButton)) {
      window.open("https://ebc2.cybersource.com/ebc2/");
    } else {
      setShowWarning(true);
    }
  };
  const updateMyData = useCallback(
    (rowIndex, columnID, value, redemCount, activeBtn, pageIndex) => {
      setActiveButton(activeBtn);
      setCurrentPage(pageIndex);
      setRedeemedCount(Math.abs(value));
      setRedemptionsData((redemptionsData) =>
        redemptionsData.map((row, index) => {
          if (index === rowIndex) {
            if(value === 0) setActiveButton("")
            return {
              ...redemptionsData[rowIndex],
              [columnID]: redemCount,
              added: activeBtn,
              redemedCount: value,
            };
          }
          return row;
        })
      );
    },
    []
  );
  const handleInputChange = useCallback(
    (value, newValue, index, id, pageIndex) => {
      updateMyData(
        index,
        id,
        parseInt(newValue),
        (parseInt(value) > parseInt(newValue) ? (parseInt(value) - parseInt(newValue)) : (parseInt(newValue) - parseInt(value))),
        parseInt(value) > parseInt(newValue) ? 'minus': "plus",
        pageIndex 
      );
    },
    [updateMyData]
  );
  const handlePlus = useCallback(
    (value, index, redemCount, id, pageIndex) => {
      updateMyData(
        index,
        id,
        parseInt(value) + 1,
        parseInt(redemCount) + 1,
        "plus",
        pageIndex 
      );
    },
    [updateMyData]
  );
  const handleMinus = useCallback(
    (value, index, redemCount, id, pageIndex) => {
      updateMyData(
        index,
        id,
        parseInt(value) - 1,
        parseInt(redemCount) - 1,
        "minus",
        pageIndex
      );
    },
    [updateMyData]
  );
  const columns = useMemo(
    () => [
      {
        Header: " ",
        accessor: "checkbox",
        width: 40,
      },
      {
        Header: "Ticket ID",
        accessor: "id",
        width: 130,
      },
      {
        Header: "Ticket Type",
        accessor: "ticket_type",
        width: 70,
      },
      {
        Header: "Passenger Type",
        accessor: "passenger_type",
        width: 95,
      },
      {
        Header: "Expiration Date",
        accessor: "valid_to",

        width: 140,
      },
      {
        Header: "Original Trip Rides",
        accessor: "ticket_type_redemption_count",
        width: 100,
      },
      {
        Header: "Redeemed Rides",
        accessor: "redeemed_ticket_count",
        width: 95,
      },
      {
        Header: "Remaining Rides",
        accessor: "remainingcount",
        width: 150,
      },
      {
        Header: "Price",
        accessor: "",
        width: 100,
      },
    ],
    []
  );
  useEffect(() => {
    if (!_.isEmpty(redemptionsData)) {
      setRedemptionsTicketInfo(redemptionsData, activeButton);
    }
  }, [redemptionsData]);
  const handleAddNote = () => {
    setOnAddNote(true);
    //setAutomatedNotes(false)
    if (_.isEmpty(activeButton)) {
      setIsAddNote(!isAddNote);
    } else {
      setShowWarning(true);
    }
  };
  const onSavePopup = () => {
    updateTicket(redemptionsData);
    setShowWarning(!showWarning);
  };
  const onWarningClose = () => {
    if (onRefund) window.open("https://ebc2.cybersource.com/ebc2/");
    setOnRefund(false);
    if (onAddNote) setIsAddNote(!isAddNote);
    setShowWarning(!showWarning);
    setOnAddNote(false);
  };
  return (
    <>
      {showWarning && (
        <WarningPopup
          modalOpen={showWarning}
          handleClose={() => onWarningClose()}
          onSave={() => onSavePopup()}
        />
      )}
      {isAddNote && (
        <AddNotesPopup
          modal={isAddNote}
          addNotes={addNotes}
          handleClose={() => setIsAddNote(!isAddNote)}
          ticketId={rowData.id}
          orderId={rowData.order_id}
          customerId={rowData.customer_id}
          addNotes={addNotes}
          setAutomatedNotes={setAutomatedNotes}
        />
      )}
      {updatedTicketsData && (
        <AllSetPopup
          modalOpen={updatedTicketsData}
          subText={allSetMessage}
          isError={isError}
          handleClose={() => setUpdatedTicketsData(!updatedTicketsData)}
        />
      )}
      <Card className="card-box-shadow-4">
        <CardTitle className="p-2">
          <span className="font16">Ticket Information</span>
        </CardTitle>
        <TicketTable
          columns={columns}
          data={redemptionsData}
          rowSelected={rowSelected}
          setSelectedRowData={setSelectedRowData}
          handlePlus={handlePlus}
          handleInputChange={handleInputChange}
          handleMinus={handleMinus}
          updateMyData={updateMyData}
          activeButton={activeButton}
          onRowSelected={onRowSelected}
          currentPage={currentPage}
        />
        <Row>
          <Col className="text-center">
            <Button
              className="px-4 py-2-75  btn-pill"
              color="primary"
              disabled={_.isEmpty(activeButton)}
              onClick={() => updateTicket(redemptionsData)}
            >
              <span className="font16-b">SAVE TICKET INFORMATION</span>
            </Button>
            <Button
              className="mx-3 py-2-75 px-4 btn-pill"
              color="primary"
              disabled={!(rowSelected > -1) || !_.get(ticketAccess,"addNotesAccess",true)}
              onClick={() => handleAddNote()}
            >
              <span className="font16-b">ADD NOTES</span>
            </Button>
            <Button
              className="px-4 py-2-75 btn-pill"
              color="primary"
              disabled={!(rowSelected > -1) || !_.get(ticketAccess, "cyberSourceRefundAccess", true)}
              onClick={handleRefundClick}
            >
              <span className="font16-b">REFUND IN CYBERSOURCE</span>
            </Button>
          </Col>
        </Row>
      </Card>
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    setRedemptionsTicketInfo: (data, isActive) =>
      setRedemptionsTicketInfo(data, isActive, dispatch),
    getTicketRedemptions: (orderId) => getTicketRedemptions(orderId, dispatch),
  };
};
export default connect(null, mapDispatchToProps)(TicketInformation);
