import { createStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { logger } from 'redux-logger/src';
import thunk from 'redux-thunk';
import reducer from './reducers/IndexReducer';

let parameters = [];
if (process.env.NODE_ENV !== 'production') {
  parameters.push(compose(composeWithDevTools(applyMiddleware(thunk, logger))));
} else {
  parameters.push(applyMiddleware(thunk));
}

const store = createStore(reducer, ...parameters);

export default store;
