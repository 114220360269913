import React, { useState } from 'react';
import downArrow from '../../../../assets/images/down-arrow.svg';
import upArrow from '../../../../assets/images/arrow-up.svg';
import { Label } from 'reactstrap';
import _ from 'lodash';
import MultipleRouteList from './MultipleRouteList'
import useRoleAccess from '../../../../util/hooks/RoleAccess';
const SingleRouteList = ({
    data,
    handleChange,
    onRoutesChange,
    allRoutesChecked,
    selectedRoutes,
    deliverType,
    
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const roleAccess = useRoleAccess();
    const operators = _.get(roleAccess, 'operators', '');
    return (
        <div className="mb-2 bg-light py-2 pl-3" key={_.get(data, "agencyId", "")} >
            {allRoutesChecked ? (
               
                    <Label check>
                        <input
                            type="checkbox"
                            checked={allRoutesChecked}
                            readOnly={true}
                            className="mr-2"
                            disabled={deliverType}
                        />
                    {_.get(data, "agencyName", "")}
                        <span className="checkBoxMark"></span>
                    </Label>
            ) :
                
                (
                        <Label check>
                            <input
                                type="checkbox"
                            name={_.get(data, "operatorCode", "")}
                            value={_.get(data, "agencyId", "")}
                                onChange={handleChange}
                            checked={!_.isEmpty(selectedRoutes[_.get(data, "agencyId", "")])}
                                className="mr-2"
                                disabled={deliverType}
                        />
                        {_.get(data, "agencyName", "")}
                            <span className="checkBoxMark"></span>
                        </Label>
                )
            
            }

                <span className="float-right"
                    onClick={() => !deliverType && !allRoutesChecked && setIsOpen(!isOpen)}
                >
                Selected Routes ({selectedRoutes[_.get(data, "agencyId", "")] && !allRoutesChecked ? (  
                        selectedRoutes[_.get(data,"agencyId","")].length
                    ) :
                    allRoutesChecked ?
                            _.get(data, "routeDetails",[]).length
                            // data.routeDetails.length
                            :
                        (
                            0
                        )})

					{' '}
                    <img
                        src={
                            isOpen ? (
                                upArrow
                            ) : (
                                    downArrow
                                )
                        }
                        alt="show routes"
                    key={_.get(data, "agencyId", "")}
                    />
                </span>

            <ul className="notification_sub_checkbox list-group list-group-flush ml-2 mt-2">
                
                {!allRoutesChecked && !_.isEmpty(selectedRoutes[_.get(data, "agencyId", "")]) &&  isOpen ? (
                
                    _.get(data, "routeDetails", []).map((route, index) => {
                        
                            return (
                                <MultipleRouteList key={index}
                                    agencyId={_.get(data, "agencyId", "")}
                                    routeDetails={route}
                                    onRoutesChange={onRoutesChange}
                                    selectedRoutes={selectedRoutes}
                                    allRoutesChecked={allRoutesChecked}/>
                        )
                    })
                   
                ) : ''}
                </ul>
            </div>
    );
};

export default SingleRouteList;