import React, { useState, useEffect } from 'react';
import { fetchAgency } from '../../../../store/actions/AgencyAction';
import { connect } from 'react-redux';
import { Spinner, Label } from 'reactstrap';
import _ from 'lodash';
import { updateRoutesList } from '../../../../store/actions/NotificationAction';
import SingleRouteList from './SingleRouteList';
import useRoleAccess from '../../../../util/hooks/RoleAccess';

const RoutesList = ({
	handleAgencyData,
	agencyItems: { agencyItems },
	isAgencyLoading: { isAgencyLoading },
	updateRoutesList,
	allRoutesChecked,
	deliverType
}) => {
	const [agencyData, setAgencyData] = useState([]);
	const [checkedAgency, setCheckedAgency] = useState([]);
	const [routeResult, setRouteResult] = useState([]);
	const [selectedRoutes, setSelectedRoutes] = useState([]);
	const [errorMsg, setErrorMsg] = useState('');
	const roleAccess = useRoleAccess();
	const operators = _.get(roleAccess, 'operators', '');
	const handleChange = (e) => {
		let agency_Id = {}
		let agency = _.find(agencyData, { agencyId: _.toNumber(e.target.value) });

		let routes = {}
		
		if (e.target.checked) {
				setErrorMsg('');
				agency_Id['agencyId'] = e.target.value
				setCheckedAgency([...checkedAgency, agency_Id]);
				let route_ids = ['']
				route_ids = _.map(agency.routeDetails, 'routeId');
		
				let rList = {
					routeIds: route_ids,
					agencyId: e.target.value,
					operatorCode: e.target.name
			};
				setRouteResult([...routeResult, rList]);
				routes[e.target.value] = route_ids;
				setSelectedRoutes({ ...selectedRoutes, ...routes });
		} else {
				setRouteResult(
					_.filter(routeResult, (rs) => rs.agencyId !== String(e.target.value))
				);
	
				routes[e.target.value] = _.filter(
					agency.agencyId,
					(ar) => ar !== e.target.value
			);
			
			setSelectedRoutes({ ...selectedRoutes, ...routes });
			}
		
	};

	const onRoutesChange = (e) => {
		const routes = {};
		let agency = _.find(routeResult, { agencyId: e.target.name });
		
		if (_.isEmpty(agency)) {
			setErrorMsg('Please select the agency first');
			e.target.checked = false;
			return
		}
		if (e.target.checked) {
			agency.routeIds = [...agency.routeIds, _.toNumber(e.target.value)];
			setRouteResult(
				_.map(routeResult, (rs) => {
					if (rs.agencyId === agency.agencyId) {
						routes[rs.agencyId] = agency.routeIds;
						setSelectedRoutes({ ...selectedRoutes, ...routes });
						return agency;
					} else {
						return rs;
					}
				})
			);
		} else {
			setErrorMsg("")
			agency.routeIds = _.filter(
				agency.routeIds,
				(ar) => ar !== _.toNumber(e.target.value)
			);
			setRouteResult(
				_.map(routeResult, (rs) => {
					if (Number(rs.agencyId) === Number(agency.agencyId)) {
						routes[rs.agencyId] = agency.routeIds
							setSelectedRoutes({ ...selectedRoutes, ...routes });
						
						if (_.isEmpty(agency.routeIds)) {
							return []
						}
						return agency;
					} else {
						
						if (_.isEmpty(rs.routeIds)) {
							return []
						}
						return rs;
					}
				})
			);
			
		}
	};

	useEffect(() => {
		setErrorMsg('');
	}, []);

	useEffect(() => {
		let param = {}
		//if (!_.isEmpty(JSON.parse(localStorage.getItem('userAccess')))) {
			// const userRoutes =
			// 	_.get(JSON.parse(localStorage.getItem('userAccess')), 'homeCarrier'
			// 	);
		const userRoutes ='ALL'
			if (!_.isEmpty(userRoutes) && !_.includes(userRoutes, 'ALL')) {
				param = {
					tblKey: 'allAgenciesAndRoutes',
					operatorCode: userRoutes
				};
			} else {
				param = {
					tblKey: 'allAgenciesAndRoutes'
				};
			}
		//}

		handleAgencyData(param);
	}, []);


	useEffect(
		() => {
			setErrorMsg('')
			const agencyList = !_.isEmpty(agencyItems) ? agencyItems : [];
			if (!_.isEmpty(agencyList) && _.find(operators)!=="ALL") {
				setAgencyData(agencyList.filter((a) =>
					a.agencyName !== null && a.operatorCode !== null && a.operatorCode === _.find(operators)
				));
			} else {
				setAgencyData(agencyList.filter((a) =>
					a.agencyName !== null && a.operatorCode !== null 
				));
			}
		},
		[agencyItems]
	);

	useEffect(
		() => {
			let rList=[]
			 rList=routeResult.map((a) => {
				if (!_.isEmpty(a)) {
					return {
						routeIds: a.routeIds,
						agencyId: a.agencyId,
						operatorCode: a.operatorCode ? a.operatorCode : ''
					};
				} else {
					return null
				}
			});
			const result = rList.filter(x => x !== null)
			updateRoutesList(result);
		},
		[routeResult, updateRoutesList]
	);

	useEffect(
		() => {
			if (allRoutesChecked) {
				setSelectedRoutes([]);
				let route_ids = ['']
				const rlIst = agencyData.map((a) => {
					let agency = _.find(agencyData, { agencyId: _.toNumber(a.agencyId) });
					const routeData = _.get(agency,"routeDetails",[])
					route_ids = _.map(routeData, 'routeId')
					return {
						routeIds: route_ids,
						agencyId: a.agencyId,
						operatorCode: a.operatorCode ? a.operatorCode : ''
					};
				});
				
				updateRoutesList(rlIst);

			}
			else {
				updateRoutesList([]);
			}
		},
		[allRoutesChecked, agencyData, updateRoutesList]
	);

	return (
		<div className="routeCheckbox">
			{(isAgencyLoading) && (
				<div className="loader">
					<div className="spinner">
						<Spinner animation="border" variant="primary" />
					</div>
					<br />
				</div>
			)}
			<Label className="error-msg-text">{errorMsg}</Label>
			<div className="routeCheckboxList">
			
				{agencyData.map((a, index) => {
					return (
						<SingleRouteList data={a}
							key={index}
							handleChange={handleChange}
							onRoutesChange={onRoutesChange}
							allRoutesChecked={allRoutesChecked}
							selectedRoutes={selectedRoutes}
							checkedAgency={checkedAgency}
							deliverType={deliverType}
							
						/>
					)
				})}
			</div>
		</div>
	);
};

const mapStateToProps = ({ agencyReducer }) => {
	return {
		agencyItems: agencyReducer,
		isAgencyLoading: agencyReducer
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		handleAgencyData: (tblKey) => fetchAgency(tblKey, dispatch),
		updateRoutesList: (list) => updateRoutesList(list, dispatch)
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(RoutesList);