import React from 'react';
import {
    useTable,
    usePagination,
    useSortBy,
    useRowSelect,
    useFilters,
    useGlobalFilter
} from 'react-table';
import matchSorter from 'match-sorter';
import {
    Table,
    Row,
    Col,
    Card,

} from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import _ from 'lodash';
import PaginationLeft from '../pagination/PaginationHeader';
import PaginationRight from '../pagination/PaginationRightSide';
import PaginationFooter from '../pagination/PaginationFooter';
import ArrowDown from "../../../../assets/images/arrow_drop_down-24px.svg";
import ArrowUp from "../../../../assets/images/arrow_drop_up-24px.svg";
import Search from '../../../layouts/Search';
import { format } from 'date-fns';


const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef();
        const resolvedRef = ref || defaultRef;

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate;
        }, [resolvedRef, indeterminate]);

        return (
            <label className="customCheckbox">
                <input type="checkbox" ref={resolvedRef} {...rest}  />
                &nbsp;
                <span className="checkBoxMark"></span>
            </label>
        );
    }
);

const NotificationsGrid = ({
    columns,
    data,
    idKey,
    menuId,
    heading,
    history: { push },
    updateMyData,
    disablePageResetOnDataChange,
    onDelete,
    selectedAlertId,
    alertId,
    onRowSelect
}) => {
    const onNextPage = () => {
        nextPage();
    };
    
    const queryString =
        menuId.includes('notification') || menuId.includes('messages')
            ? 'id'
            : _.find(idKey);

    const fuzzyTextFilterFn = ({ rows, id, filterValue }) => {
        return matchSorter(rows, filterValue, {
            keys: [(row) => row.values[id]]
        });
    };

    // Let the table remove the filter if the string is empty
    fuzzyTextFilterFn.autoRemove = (val) => !val;

    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter((row) => {
                    const rowValue = row.values[id];
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true;
                });
            }
        }),
        []
    );


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageIndex, pageSize,selectedRowIds },
        
    } = useTable(
        {
            columns,
            data,
            filterTypes,
            disableMultiSort: true,
            heading,
            disablePageResetOnDataChange,
            updateMyData,
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        usePagination,
        useRowSelect,
        (hooks) => {

            hooks.allColumns.push(columns => [
                {
                    id: "selection",
                   
                    Header: () => <div />,
                    
                    // The cell can use the individual row's getToggleRowSelectedProps method
                    // to the render a checkbox
                    Cell: ({ row }) => {
                        if ((row.original.status).toLowerCase() !== "sent" && !row.original.immediate) {
                            if (
                                rows.filter((row) => (row.isSelected).length < 1 ||
                                    row.isSelected)
                            ) {
                                
                                if (row.isSelected) {
                                    
                                    selectedAlertId(row.original.id);
                                   
                                } 
                                return (
                                    <div>
                                        <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                                    </div>
                                )
                              
                            }
                            
                        } else {
                            return null
                        }
                    }
                },
                ...columns
            ]
            );
        },
        useRowSelect
        );

   
    if (!_.isEmpty(JSON.stringify(selectedRowIds))) {
        onRowSelect(JSON.stringify(selectedRowIds))
    }
    return (
        <>
            <Row className="productFareListHead">
                <Col sm="4">
                    <PaginationLeft
                        canPreviousPage={canPreviousPage}
                        canNextPage={canNextPage}
                        pageOptions={pageOptions}
                        pageCount={pageCount}
                        gotoPage={gotoPage}
                        nextPage={onNextPage}
                        previousPage={previousPage}
                        setPageSize={setPageSize}
                        pageSize={pageSize}
                        pageIndex={pageIndex}
                        data={data}
                    />
                </Col>
                <Col sm="8">
                    <div className="display_record">
                        <PaginationRight
                            canPreviousPage={canPreviousPage}
                            canNextPage={canNextPage}
                            pageOptions={pageOptions}
                            pageCount={pageCount}
                            gotoPage={gotoPage}
                            nextPage={onNextPage}
                            previousPage={previousPage}
                            setPageSize={setPageSize}
                            pageSize={pageSize}
                            pageIndex={pageIndex}
                            page={page}
                        />
                    </div>
                    <div className="search_field">
                        <Search
                            addonType="append"  
                            preGlobalFilteredRows={preGlobalFilteredRows}
                            setGlobalFilter={setGlobalFilter}
                        />
                    </div>
                    <button className="font16-b px-4 btn btn-primary btn-sm rounded-pill mr-3 float-right" disabled={_.isEmpty(selectedRowIds) && true} onClick={onDelete}> Delete</button>
                </Col>
            </Row>
            <Card>
                    <div className="table-responsive">
                        <Table
                            className="table productFareList" borderless
                            {...getTableProps()}
                        >
                            <thead>
                                {headerGroups.map((headerGroup) => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column) => (

                                            <th {...column.getHeaderProps({

                                                ...(column.isSortable
                                                    ? column.getSortByToggleProps()
                                                    : {}),

                                            })}>
                                                    <span >
                                                    <span className="text-sort">{column.render('Header')}</span>
                                                    {(column.id !== 'selection' && column.Header !== 'Action') && (
                                                            !column.isSortable ? (
                                                            <span className="sort-container" {...column.getSortByToggleProps()}>
                                                                    {column.isSorted ? (
                                                                        column.isSortedDesc ? (
                                                                            <img src={ArrowDown} alt="" />
                                                                        ) : (
                                                                                <img src={ArrowUp} alt="" />
                                                                            )
                                                                    ) : (
                                                                            <>
                                                                                <img src={ArrowUp} alt="" />
                                                                                <img src={ArrowDown} alt="" />
                                                                            </>
                                                                        )}
                                                                </span>
                                                            ) : null)
                                                        }
                                                    </span>
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {page.map((row) => {
                                    prepareRow(row);

                                    return (

                                        <tr key={row.id}  {...row.getRowProps()}>
                                            {row.cells.map((cell, cIndex) => {
                                                
                                                return (
                                                    <td key={cIndex} {...cell.getCellProps()} >
                                                        {cell.render('Header') === 'Action' ?
                                                            
                                                            ((row.original.status).toLowerCase() === "draft" && !row.original.immediate) ? 
                                                                !_.isEmpty(selectedRowIds) && alertId === row.original.id ? 
                                                                        (
                                                                                <Link
                                                                                to={{ pathname: `/editnotifications/${window.btoa(row.original.id)}` }} > Edit</Link>)
                                                                        
                                                                            :
                                                                            (<span>  Edit</span>)
                                                                
                                                                :
                                                                 <Link
                                                                        to={{
                                                                        pathname: `/viewnotifications/${window.btoa(_.get(
                                                                                row.original,
                                                                                    queryString
                                                                                ))}`,
                                                                                state: { menuId, data: row.original }
                                                                            }}>  View</Link>
                                                                    
                                                                    
                                                                   
                                                            
                                                                :
                                                            cell.column.Header === 'Deliver Type' ?
                
                                                                _.join(_.map(row.original.deliveryType, (d) => {
                                                                    if (d === "push") {
                                                                        return "Push Notifications"
                                                                    } else if (d === "sms") {
                                                                        return "Text Sms"
                                                                    } else {
                                                                        return _.camelCase(d)
                                                                    }
                                                                }), ", ")
                                                                :
                                                                cell.column.Header === 'Published Date' ?
                                                                    format(new Date(row.original.startDate), 'MMM dd, yyyy')
                                                                :
                                                                cell.column.Header === 'Status' ?
                                                                    _.upperFirst(row.original.status)
                                                                    :
                                                                    cell.render('Cell')
                                                                
                                                        }

                                                    </td>
                                                );
                                            })}

                                        </tr>
                                    );
                                })}
                            </tbody>
                    </Table>
                    
                    </div>
            </Card>
 
            <div className="text-center">
                {!_.isEmpty(data) ?
                    <PaginationFooter
                        canPreviousPage={canPreviousPage}
                        canNextPage={canNextPage}
                        pageOptions={pageOptions}
                        pageCount={pageCount}
                        gotoPage={gotoPage}
                        nextPage={onNextPage}
                        previousPage={previousPage}
                        setPageSize={setPageSize}
                        pageSize={pageSize}
                        pageIndex={pageIndex}
                        page={page}

                /> 
                    : ""} 
                 </div> 
           
        </>
    );
};

export default withRouter(NotificationsGrid);
