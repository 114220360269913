import { useCallback } from 'react';


const useValidation = () => {
  const isEmailValid = useCallback((username) => {
    const regex = new RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    return regex.test(username);
  }, []);

  const isPasswordValid = useCallback((password) => {
    const regex = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,100}$/);
    return regex.test(password);
  }, []);

  return {
    isEmailValid,
    isPasswordValid
  };
};

export default useValidation;