import React from 'react';
import { Container, Row, Col, Spinner } from 'reactstrap';
import MainWrapper from '../MainWrapper';
import CustomerSupportCard from "./dashboard/CustomerSupportCard";
import DashboardHeader from "./dashboard/DashboardHeader";
import AlertsAdminCard from "./dashboard/AlertsAdminCard";
import useUserAccessDetails from '../../util/hooks/UserAccessDetails';
import useUserPermissions from '../../util/hooks/UserPermissions';
import { useSelector } from 'react-redux';

import _ from "lodash";
const Dashboard = () => {
	const userPermissions = useUserPermissions();
	const userAccessDetails = useUserAccessDetails();
	const dashboardAccess = _.get(userAccessDetails, "dashboard", {})
	const { isAccessLoading, isLoading } = useSelector(state => state.auth);
	return (
		<div className="landing-container">
		<MainWrapper>
			<>
					{" "}
					{(isLoading || isAccessLoading) && (
						<div className="loader">
							<div className="spinner">
								<Spinner animation="border" variant="primary" />
							</div>
						</div>
					)}
				<DashboardHeader />
				<Container className="mt-4 pt-2">
					<span className="font24-b">
							{!_.isEmpty(userPermissions) && (_.get(dashboardAccess, "csAdminSearch", false) || _.get(dashboardAccess, "notificationSearch", false)) ? 
							"YOU CAN EASY ACCESS TO ANY ADMIN SECTION"
							:
							"YOU CAN NOT ACCESS TO ADMIN SECTION"
						}
          </span>
					
					<Row className="mt-2">
							{_.get(dashboardAccess,"csAdminSearch",false) &&
							<Col xs="12" md="6">
								<CustomerSupportCard />
							</Col>
						}
							{_.get(dashboardAccess, "notificationSearch", false) &&
							<Col xs="12" md="6">
								<AlertsAdminCard />
							</Col>
						}
						</Row>
						
				</Container>
			</>{" "}
		</MainWrapper>
		</div>
	);
};

export default Dashboard;
