import React, { useState, useMemo } from "react";
import {
  Card,
  CardBody,
  FormGroup,
  Input,
  Button,
  Spinner,
} from "reactstrap";
import { connect } from "react-redux";
import { login } from "../../store/actions/AuthAction";
import PropTypes from "prop-types";
import PhoneIco from "../../assets/images/phone_ico.svg";
import ViewPwd from "../../assets/images/show.svg";
import Logo from "../../assets/images/COACHUSA_logo.svg";
import { useHistory } from "react-router-dom";
import Footer from "../layouts/Footer";
import _ from "lodash";
import useValidation from "../../util/hooks/Validation";
import useValidationMessage from "../../util/hooks/ValidationMessage";
import { useEffect } from "react";
import { MdClose } from "react-icons/md";

const { func, object } = PropTypes;

const Login = ({
  handleLogin,
  auth: {
    isAuthenticated,
    loginData,
    isLoading,
    isAccessLoading,
    error: { message },
    role,
    loginError,
    user,
  },
}) => {
  const history = useHistory();
  const [state, setState] = useState({
    username: "",
    password: "",
  });
  const [hidden, setHidden] = useState(true);
  const [modalIsOpen, openModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const { isEmailValid, isPasswordValid } = useValidation();
  const {
    emailNotValidLoginMessage,
    passwordInvalidLogin,
  } = useValidationMessage();

  const handleChange = ({ target: { id, value } }) => {
    setErrorMsg("");
    setState({
      ...state,
      [id]: value.trim(),
    });
  };

  const handleSubmit = () => {
    setErrorMsg("");
    handleLogin(state);
  };

  const { username, password } = state;

  const emailError = useMemo(
    () =>
      emailNotValidLoginMessage(
        !_.isEmpty(username) && !isEmailValid(username)
      ),
    [username]
  );
  const passwordError = useMemo(
    () =>
      passwordInvalidLogin(!_.isEmpty(password) && !isPasswordValid(password)),
    [password]
  );

  const isDisabled = useMemo(
    () =>
      _.isEmpty(username) ||
      _.isEmpty(password) ||
      !isEmailValid(username) ||
      !isPasswordValid(password),
    [username, password]
  );

  const toggleShowPwd = () => {
    setHidden(password !== "" && !hidden);
  };
  useEffect(() => {
    setErrorMsg("");
  }, []);

  useEffect(() => {
    if (_.isEmpty(username) && _.isEmpty(password)) {
      setErrorMsg("");
    }
  }, [username, password]);

  useEffect(() => {
    if (isAuthenticated && !_.isEmpty(role)) {
      history.push("/");
    }
  }, [loginData]);

  useEffect(() => {
    if (!isAuthenticated && !_.isEmpty(user)) {
      setErrorMsg("No access allowed!");
    }
  }, [user, isAuthenticated]);

  useEffect(() => {
    if (!_.isEmpty(loginError)) {
      setErrorMsg("Incorrect username or password.");
    }
  }, [loginError]);

  return (
    <>
      <div className="auth-form container">
        {(isLoading || isAccessLoading) && (
          <div className="loader">
            <div className="spinner">
              <Spinner animation="border" variant="primary" />
            </div>
          </div>
        )}
        <div className="row mb-5">
          <div className="col-12 text-center">
            <img src={Logo} alt="Logo" width="300px" />
          </div>
        </div>
        {modalIsOpen ? (
          <>
            <Card className="login_card">
              <CardBody>
                <div className="text-right">
                  <span
                    onClick={() => openModal(false)}
                    className="theme-blue-txt h2 inline-block -mt-2 cursor-pointer"
                  >
                    <MdClose />
                  </span>
                </div>
                <div className="px-5">
                  <h3 className="text-center font48 mt-4 mb-5 leading-10">
                    For Log In Help Please Contact Your Supervisor.
                  </h3>
                  <hr className="theme-blue-bg col-6" />
                  <div className="text-center pb-5 pt-4">
                    <img src={PhoneIco} alt="Logo" width="100px" />
                  </div>
                </div>
              </CardBody>
            </Card>
          </>
        ) : (
          <Card className="login_card">
            <CardBody>
              <div className="px-4 mx-1">
                <h2 className="text-center font48 mt-3 mb-4">Log In</h2>
                <small className={errorMsg ? "error-msg" : ""}>
                  {errorMsg}
                </small>
                <FormGroup>
                  <span className="font14 mb-2 d-block">Employee ID</span>
                  <Input
                    bsSize="lg"
                    type="text"
                    id="username"
                    placeholder="Employee ID"
                    value={username}
                    onChange={handleChange}
                    className={
                      !_.isEmpty(emailError)
                        ? "form-control error-field"
                        : "form-control"
                    }
                  />
                  {!_.isEmpty(emailError) && (
                    <span className="error-msg mt-1">{emailError}</span>
                  )}
                </FormGroup>
                <FormGroup className="mb-1">
                  <span className="font14 mb-2 d-block">Password</span>

                  <Input
                    type={hidden ? "password" : "text"}
                    bsSize="lg"
                    id="password"
                    placeholder="Password"
                    value={password}
                    onChange={handleChange}
                    className={
                      !_.isEmpty(passwordError)
                        ? "form-control error-field pr-5"
                        : "form-control pr-5"
                    }
                  />
                  <img
                    src={ViewPwd}
                    onClick={toggleShowPwd}
                    alt="viewPassword"
                    className="password"
                  />
                  {!_.isEmpty(passwordError) && (
                    <span className="error-msg mt-1">{passwordError}</span>
                  )}
                </FormGroup>

                <div className="row mb-4">
                  <div className="col-12 help_msg">
                    <div className="text-right">
                      <a href="#/" onClick={() => openModal(true)}>
                        Forgot Password?
                      </a>
                    </div>
                  </div>
                </div>
                <div className="clearfix"></div>
                <Button
                  color="primary"
                  block
                  onClick={handleSubmit}
                  disabled={isDisabled}
                  className="font16-b mt-3 mb-4 login-button"
                >
                  LOG IN
                </Button>
              </div>
            </CardBody>
          </Card>
        )}
      </div>
      <Footer />
    </>
  );
};

Login.propTypes = {
  handleLogin: func,
  auth: object,
};

const mapStateToProps = ({ auth }) => {
  return {
    auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleLogin: (data) => login(data, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
