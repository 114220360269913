import {
	AGENCY_CREATE_FAILED,
	AGENCY_CREATE_PENDING,
	AGENCY_CREATE_SUCCESS,
	AGENCY_EDIT_PENDING,
	AGENCY_EDIT_SUCCESS,
	AGENCY_EDIT_FAILED,
	AGENCY_EDIT_FETCH_PENDING,
	AGENCY_EDIT_FETCH_SUCCESS,
	AGENCY_EDIT_FETCH_FAILED
} from '../../util/constant';
import api from '../../util/api';
import appURLConstant from '../../util/appURLConstants';
import _ from 'lodash';
export const agencyCreate = (data, dispatch) => {
	try {
		dispatch({
			type: AGENCY_CREATE_PENDING
		});

		dispatch({
			type: AGENCY_CREATE_SUCCESS,
			payload: data
		});
	} catch (error) {
		dispatch({
			type: AGENCY_CREATE_FAILED,
			payload: error
		});
	}
};

export const agencyEdit = (data, dispatch) => {
	try {
		dispatch({
			type: AGENCY_EDIT_PENDING
		});
		dispatch({
			type: AGENCY_EDIT_SUCCESS,
			payload: data
		});
	} catch (error) {
		dispatch({
			type: AGENCY_EDIT_FAILED,
			payload: error
		});
	}
};

export const fetchAgency = async (id, dispatch) => {
	try {
		dispatch({
			type: AGENCY_EDIT_FETCH_PENDING
		});
		const response = await api.post(
			`${appURLConstant.REACT_GET_GTFS_DATA_URL}`,
			id
		);
		const results =
			response.data.orbitResponse.statusCode === 400 ||
			!_.isEmpty(response.data.orbitResponse.errorMessage)
				? []
				: response.data.orbitResponse.body;

		dispatch({
			type: AGENCY_EDIT_FETCH_SUCCESS,
			payload: results
		});
	} catch (error) {
		dispatch({
			type: AGENCY_EDIT_FETCH_FAILED,
			payload: error
		});
	}
};
