import React from 'react';
import {  NavbarBrand } from 'reactstrap';
import Logo from '../../assets/images/footerLogo.svg';

const Footer = () => {
	return (		
		<footer className="footer">
			<div className="container">
				<div className="row">
					<div className="col-sm-6 poweredBy">						
						<div>Powered by</div>
						<NavbarBrand href="/">
							<img src={Logo} className="logo-footer" alt="logo" />
						</NavbarBrand>
					</div>
					<div className="col-sm-6 footerCopyright">
						<p className="m-0">© Coach USA {new Date().getFullYear()}</p>
					</div>
				</div>
			</div>
		
		</footer>
	);
};

export default Footer;
