import React from 'react';
import { Modal, ModalBody, Button } from 'react-bootstrap';
import GroupImg from '../../../../assets/images/Group.svg'
import { useHistory } from 'react-router-dom';


const ConfirmationModal = ({
    message,
    open
}) => {
    const history = useHistory();

    const onClose = () => {
        history.push(`/notification`)
    }

    return (
        <div>
            <Modal className="confirmation-modal" show={open}>
                <ModalBody>
                    <img src={GroupImg} alt="GroupImg" />
                    <h3>All Set</h3>
                    <div className="message">Your notification </div>
                    <div className="message">has been {message} successfully.</div>
                    <Button onClick={onClose}>GO TO HOME PAGE</Button>
                </ModalBody>
            </Modal>
        </div>
    );
};



export default ConfirmationModal;
