import React from 'react';
import {  Input} from 'reactstrap';

const PaginationRightSide = ({
	setPageSize,
	pageSize
}) => {
	return (
		<>
			Display
			<Input
				type="select"
				id="pageshow"
				bsSize="sm"
				value={pageSize}
				className="font16 pageShow"
				onChange={(e) => {
					setPageSize(Number(e.target.value));
				}}
			>
				{[ 5, 10, 20, 30, 40, 50 ].map((pageSize) => (
					<option key={pageSize} value={pageSize}>
						{pageSize}
					</option>
				))}
			</Input>
			records
		</>
	);
};

export default PaginationRightSide;
