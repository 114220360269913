import _ from "lodash";
// import moment from "moment";
// import { data } from "../../util/viewOrderData";
import api from "../../util/api";
import appURLConstant from "../../util/appURLConstants";

export const getViewOrder = (data, dispatch) => {
  try {
    dispatch({
      type: "CS_VIEW_ORDER_PENDING",
    });

    dispatch({
      type: "CS_VIEW_ORDER_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "CS_VIEW_ORDER_FAILED",
      payload: error,
    });
  }
};

export const getNoteHistory = async (data, dispatch) => {
  try {
    dispatch({
      type: "CS_GET_NOTE_HISTORY_PENDING",
    });
    const response = await api.fetch(
      `${appURLConstant.REACT_CS_NOTE_URL}?id=${data.id}&type=${data.type}`
    );

    response.data.orbitResponse.statusCode === 400 ||
      !_.isEmpty(response.data.orbitResponse.errorMessage)
      ? dispatch({
        type: "CS_GET_NOTE_HISTORY_FAILED",
        payload:
          response.data.orbitResponse.statusCode === 400 ||
          (!_.isEmpty(response.data.orbitResponse.errorMessage) &&
            "Notes not found."),
      })
      : dispatch({
        type: "CS_GET_NOTE_HISTORY_SUCCESS",
        payload: response.data.orbitResponse.body,
      });
  } catch (error) {
    dispatch({
      type: "CS_GET_NOTE_HISTORY_FAILED",
      payload: error,
    });
  }
};

export const updateNoteHistory = async (noteData, dispatch) => {
  try {
    dispatch({
      type: "UPDATE_NOTE_HISTORY_PENDING",
    });

    const response = await api.post(
      `${appURLConstant.REACT_CS_NOTE_URL}`,
      noteData
    );
    response.data.orbitResponse.statusCode === 400 ||
      !_.isEmpty(response.data.orbitResponse.errorMessage)
      ? dispatch({
        type: "UPDATE_NOTE_HISTORY_FAILED",
        payload:
          response.data.orbitResponse.statusCode === 400 ||
          (!_.isEmpty(response.data.orbitResponse.errorMessage) &&
            "Note not found."),
      })
      : dispatch({
        type: "UPDATE_NOTE_HISTORY_SUCCESS",
        payload: response.data.orbitResponse.body,
      });
  } catch (error) {
    dispatch({
      type: "UPDATE_NOTE_HISTORY_FAILED",
      payload: error,
    });
  }
};

export const getCustomerInformation = async (data, dispatch) => {
  try {
    dispatch({
      type: "CS_GET_CUSTOMER_INFORMATION_PENDING",
    });
    const response = await api.fetch(
      `${appURLConstant.REACT_CS_CUSTOMER_INFORMATION_URL}?type=${data.type}&id=${data.id}`
    );

    response.data.orbitResponse.statusCode === 400 ||
      !_.isEmpty(response.data.orbitResponse.errorMessage)
      ? dispatch({
        type: "CS_GET_CUSTOMER_INFORMATION_FAILED",
        payload:
          response.data.orbitResponse.statusCode === 400 ||
          (!_.isEmpty(response.data.orbitResponse.errorMessage) &&
            "Info not found."),
      })
      : dispatch({
        type: "CS_GET_CUSTOMER_INFORMATION_SUCCESS",
        payload: response.data.orbitResponse.body,
      });
  } catch (error) {
    dispatch({
      type: "CS_GET_CUSTOMER_INFORMATION_FAILED",
      payload: error,
    });
  }
};

export const sendEmailReciept = async (params, dispatch) => {
  try {
    dispatch({
      type: "SEND_EMAIL_RECIEPT_PENDING",
    });

    const response = await api.post(
      `${appURLConstant.REACT_CS_EMAIL_RECIEPT_URL}`,
      params
    );
    response.data.orbitResponse.statusCode === 400 ||
      !_.isEmpty(response.data.orbitResponse.errorMessage)
      ? dispatch({
        type: "SEND_EMAIL_RECIEPT_FAILED",
        payload:
          response.data.orbitResponse.statusCode === 400 ||
          (!_.isEmpty(response.data.orbitResponse.errorMessage) &&
            "Error in sending reciept."),
      })
      : dispatch({
        type: "SEND_EMAIL_RECIEPT_SUCCESS",
        payload: !_.isEmpty(params) ? response.data.orbitResponse.body : [],
      });
  } catch (error) {
    dispatch({
      type: "SEND_EMAIL_RECIEPT_FAILED",
      payload: error,
    });
  }
};
export const fetchStates = async (code, dispatch) => {
  try {
    dispatch({
      type: "STATE_LIST_PENDING",
    });
    const response = await api.fetch(
      `${appURLConstant.REACT_CS_STATE_CODE_URL}?code=${code}`
    );
    if (response.data.orbitResponse.statusCode === 200) {
      dispatch({
        type: "STATE_LIST_SUCCESS",
        payload: response.data.orbitResponse.body,
      });
    }
    else {
      dispatch({
        type: "STATE_LIST_FAILED",
        payload: "error",
      });
    }
  } catch (error) {
    dispatch({
      type: "STATE_LIST_FAILED",
      payload: error,
    });
  }
}