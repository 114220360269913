import React, { useCallback, useEffect, useState } from "react";
import { useTable, usePagination, useResizeColumns } from "react-table";
import { Input, Label, Table, Row, Col, Button } from "reactstrap";
import { format } from "date-fns";
import addEnable from "../../../../assets/images/add-enable.svg";
import minusDisable from "../../../../assets/images/minus-disable.svg";
import addDisable from "../../../../assets/images/add-disable.svg";
import minusEnable from "../../../../assets/images/minus-enable.svg";
import { getMonthlyPassTicket, toLocalDate } from "../../../../util/func";
import PaginationLeft from "../pagination/PaginationHeader";
import PaginationRight from "../pagination/PaginationRightSide";
import PaginationFooter from "../pagination/PaginationFooter";
import useUserAccessDetails from "../../../../util/hooks/UserAccessDetails";
import _ from "lodash";

const TicketTable = ({
  columns,
  data,
  rowSelected,
  handlePlus,
  handleMinus,
  activeButton,
  onRowSelected,
  currentPage,
  handleInputChange,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: currentPage },
    },
    useResizeColumns,
    usePagination
  );
  const userAccessDetails = useUserAccessDetails();
  const ticketAccess = _.get(userAccessDetails, "csViewDetails", {});
  const addRemoveRides = _.get(ticketAccess, "addRemoveRidesAccess", false);
  const [ticketRedeem, setTicketRedeem] = useState({});

  useEffect(() => {
    _.map(data, (d) => {
      setTicketRedeem((prev) => ({
        ...prev,
        [d.id]:
          parseInt(d.ticket_type_redemption_count) -
          parseInt(d.redeemed_ticket_count),
      }));
    });
  }, [data]);

  const handleOnChangeCheckbox = (row) => {
    onRowSelected(row, pageIndex);
  };
  const onNextPage = () => {
    nextPage();
  };

  const disableMinusButton = (row) => {
    return (
      !addRemoveRides ||
      !(row.id === rowSelected) ||
      parseInt(row.original.redeemed_ticket_count) === 0 ||
      (!row.original.redemedCount &&
        parseInt(row.original.ticket_type_redemption_count) ===
          parseInt(row.original.redeemed_ticket_count)) ||
      (parseInt(row.original.ticket_type_redemption_count) -
        parseInt(row.original.redeemed_ticket_count) ===
        0 &&
        _.isEmpty(activeButton) &&
        parseInt(row.original.ticket_type_redemption_count) !== 1)
    );
  };
  const disablePlusButton = (row) => {
    return (
      !addRemoveRides ||
      !(row.id === rowSelected) ||
      parseInt(row.original.ticket_type_redemption_count) -
        parseInt(row.original.redeemed_ticket_count) ===
        0
    );
  };
  const getAdminFee = useCallback(
    (ticket_service_fee_detail) =>
      _.reduce(
        ticket_service_fee_detail,
        (prev, curr) => prev + parseFloat(curr.amount),
        0
      ),
    []
  );
  const pricePlusAdminFee = (row) => {
    let fee = "";
    let fare = "";
    fee = parseFloat(getAdminFee(row.ticket_service_fee_detail)).toFixed(2);
    fare = parseFloat(row.ticket_fare).toFixed(2);
    const price =
      _.get(row, "has_service_fee", "") &&
      _.get(row, "has_service_fee", "").toUpperCase() === "Y"
        ? parseFloat(fee) + parseFloat(fare)
        : parseFloat(fare);
    return `$${price.toFixed(2)}`;
  };

  const onlyNumber = (value, maxValue) => {
    const finalValue = _.toNumber(
      _.join(
        _.filter(
          _.map(_.split(value, ""), (d) => parseInt(d)),
          (d) => d || d === 0
        ),
        ""
      )
    );
    return !value ? value : finalValue <= maxValue ? finalValue : maxValue;
  };

  return (
    <>
      <Row className="productFareListHead">
        {!_.isEmpty(data) && (
          <Col sm="4">
            <PaginationLeft
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              pageOptions={pageOptions}
              pageCount={pageCount}
              gotoPage={gotoPage}
              nextPage={onNextPage}
              previousPage={previousPage}
              setPageSize={setPageSize}
              pageSize={pageSize}
              pageIndex={pageIndex}
              data={data}
            />
          </Col>
        )}
        {!_.isEmpty(data) && _.size(data) > 10 && (
          <Col sm="8">
            <div className="display_record">
              <PaginationRight
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                pageOptions={pageOptions}
                pageCount={pageCount}
                gotoPage={gotoPage}
                nextPage={onNextPage}
                previousPage={previousPage}
                setPageSize={setPageSize}
                pageSize={pageSize}
                pageIndex={pageIndex}
                page={page}
              />
            </div>
          </Col>
        )}
      </Row>
      <div className="table-responsive">
        <Table className="table ticketInfoList" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th className="font14-b" {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    if (cell.column.id === "checkbox")
                      return (
                        <td {...cell.getCellProps()}>
                          <Label check>
                            <Input
                              type="radio"
                              value="select"
                              name="select"
                              checked={row.id === rowSelected}
                              onChange={() => handleOnChangeCheckbox(row)}
                            />
                            {""}
                            <span className="radioMark"></span>
                          </Label>
                        </td>
                      );
                    return (
                      <td {...cell.getCellProps()}>
                        {cell.render("Header") === "Expiration Date" &&
                        !_.isEmpty(row.original.valid_to)
                          ? format(
                              toLocalDate(row.original.valid_to),
                              "MMM dd,yyyy"
                            )
                          : cell.column.id === "ticket_type"
                          ? _.includes(
                              row.original.ticket_type.toUpperCase(),
                              "MONTHLY"
                            )
                            ? getMonthlyPassTicket(
                                row.original.valid_from
                              ).toUpperCase()
                            : row.original.ticket_type
                          : cell.render("Cell")}

                        {cell.render("Header") === "Remaining Rides" && (
                          <div className="containerbox">
                            {" "}
                            <Button
                              className="no-border no-bg pt-0"
                              onClick={() =>
                                handleMinus(
                                  row.original.redemedCount
                                    ? row.original.redemedCount
                                    : 0,
                                  row.index,
                                  row.original.redeemed_ticket_count,
                                  "redeemed_ticket_count",
                                  pageIndex
                                )
                              }
                              disabled={disableMinusButton(row)}
                            >
                              {rowSelected === row.id &&
                              !disableMinusButton(row) ? (
                                <img src={addEnable} alt="minus enable" />
                              ) : (
                                <img src={addDisable} alt="minus disable" />
                              )}
                            </Button>{" "}
                            <span className="persion-count pb-2">
                              {" "}
                              {cell.render("Header") === "Remaining Rides" && (
                                <input
                                  style={{ width: 30, textAlign: "center" }}
                                  value={_.get(
                                    ticketRedeem,
                                    row.original.id,
                                    0
                                  )}
                                  onChange={({ target: { value } }) =>
                                    setTicketRedeem((prev) => ({
                                      ...prev,
                                      [row.original.id]: onlyNumber(
                                        value,
                                        parseInt(
                                          row.original
                                            .ticket_type_redemption_count
                                        )
                                      ),
                                    }))
                                  }
                                  onBlur={() => handleInputChange(row.original.ticket_type_redemption_count
                                    ? parseInt(row.original.ticket_type_redemption_count)
                                    : 0,
                                  ticketRedeem[row.original.id] || 0,
                                  row.index,
                                  "redeemed_ticket_count",
                                  pageIndex)}
                                />
                              )}
                            </span>{" "}
                            <Button
                              className="no-border no-bg pt-0"
                              onClick={() =>
                                handlePlus(
                                  row.original.redemedCount
                                    ? row.original.redemedCount
                                    : 0,
                                  row.index,
                                  row.original.redeemed_ticket_count,
                                  "redeemed_ticket_count",
                                  pageIndex
                                )
                              }
                              disabled={disablePlusButton(row)}
                            >
                              {rowSelected === row.id &&
                              !disablePlusButton(row) ? (
                                <img src={minusEnable} alt="add enable" />
                              ) : (
                                <img src={minusDisable} alt="add disable" />
                              )}
                            </Button>{" "}
                          </div>
                        )}
                        {cell.render("Header") === "Price" &&
                          pricePlusAdminFee(row.original)}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <div className="text-center mb-4 mt-0">
        {!_.isEmpty(data) && _.size(data) > 10 && (
          <PaginationFooter
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageOptions={pageOptions}
            pageCount={pageCount}
            gotoPage={gotoPage}
            nextPage={onNextPage}
            previousPage={previousPage}
            setPageSize={setPageSize}
            pageSize={pageSize}
            pageIndex={pageIndex}
            page={page}
          />
        )}
      </div>
    </>
  );
};

export default TicketTable;
