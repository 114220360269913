import { format, addMonths } from "date-fns";

export const getMonthlyTicketType = (data) => {
  const currentMonth = format(new Date(), "MMM");
  const nextMonth = format(addMonths(new Date(), 1), "MMM");
  const ticket =
    data === "MONTHLY-PASS"
      ? `Monthly (${currentMonth})`
      : `Monthly (${nextMonth})`;
  return ticket;
};
export const getMonthlyPassTicket = (data) => {
  const currentMonth = format(new Date(data), "MMM");
  const ticket = `Monthly (${currentMonth})`;
  return ticket;
};
export const toLocalDate = (timestamp) => {
  return new Date(new Date(timestamp).getTime() + new Date(timestamp).getTimezoneOffset() * 60000);
};

//start Login Response after getting roles and permissions from Cognito API----
export const adminLogin = async (requestBody) => {
  const email = requestBody.email;
  const roles = requestBody.role;
  if (isNullOrEmpty(email)) {
    return constructResponse('err-email-not-provided', 400);
  }
  try {
    let permissions = requestBody.permissions;
    let response = null;
    if (!isNullOrEmpty(permissions)) {
      response = loginWithPermission(roles, permissions);
      if (!isNullOrEmpty(response)) {
        return constructResponse(response, 200)
      }
    }
    response = loginWithoutPermission(roles);
    if (!isNullOrEmpty(response)) {
      return constructResponse(response, 200)
    }
  }
  catch (e) {
    logError('error occurred', e);
  }
  return constructResponse('err-occurred', 400);
};

function loginWithPermission(roles, permissions) {
  let dict = {};
  try {
    let dashboardAccess = { notificationSearch: false, csAdminSearch: false };
    if (permissions.includes('CS_ACCESS_FEATURE')) {
      dashboardAccess.csAdminSearch = true;
    }
    if (permissions.includes('NOTIFICATION_ACCESS_FEATURE')) {
      dashboardAccess.notificationSearch = true;
    }
    dict = {
      menuDetails: getMenuDetails(dashboardAccess),
      adminRoles: roles,
      notificationAccess: {
        deliveryType: [],
        operators: [],
        accessControl: []
      }
    }
    if (roles !== ('super_admin' || 'alert_cs_admin')) {
      if (roles === ('gtfs_admin' || 'rates_admin' || 'ticket_redemptions') || !dashboardAccess.notificationSearch) {
        dict["notificationAccess"] = {};
      } else {
        const operatorCodeList = ['RCK', 'SUB', 'VGB', 'COM', 'HTL', 'WCL', 'NAE', 'WCC'];
        const accessList = ['view', 'edit', 'delete', 'readonly', 'create'];
        const deliveryTypeList = ['push', 'sms'];
        roles.forEach(role => {
          const splitRoleData = role.split('_');
          splitRoleData.forEach(data => {
            if (accessList.indexOf(data) >= 0) {
              if (data === 'readonly') {
                dict["notificationAccess"].accessControl.push('view');
              } else {
                dict["notificationAccess"].accessControl.push(data);
              }
            }
            if (deliveryTypeList.indexOf(data) >= 0) {
              dict["notificationAccess"].deliveryType.push(data);
            }
            if (operatorCodeList.indexOf(data) >= 0) {
              dict["notificationAccess"].operators.push(data);
            }
          });
        })
        if (dict["notificationAccess"].operators.length === 0) {
          dict["notificationAccess"].operators = ['ALL'];
        }
        if (dict["notificationAccess"].deliveryType.length === 0) {
          dict["notificationAccess"].deliveryType = ['push', 'sms'];
        }
        if (dict["notificationAccess"].accessControl.length === 0) {
          dict["notificationAccess"].accessControl = ['view', 'edit', 'delete', 'create'];
        }
      }
    } else {
      dict["notificationAccess"].accessControl = ['view', 'edit', 'delete', 'create'];
      dict["notificationAccess"].deliveryType = ['push', 'sms'];
      dict["notificationAccess"].operators = ['ALL'];
    }
    let csAdminHome = {
      riskifiedAccess: true,
      cyberSourceAccess: true,
      fullStoryAccess: true,
      travelHistoryAccess: true,
      failedTxnAccess: true,
      viewOrderAccess: true
    };
    let csViewDetails = {
      viewNotesAccess: true,
      addNotesAccess: true,
      addRemoveRidesAccess: true,
      updateCustomerAccess: true,
      emailReceiptAccess: true,
      addRefundAccess: true,
      cyberSourceRefundAccess: true,
      automatedNotesAccess: true
    }
    if (permissions.includes('HIDE_CYBERSOURCE_LINK')) {
      csAdminHome.cyberSourceAccess = false;
    }
    if (permissions.includes('HIDE_FULLSTORY_LINK')) {
      csAdminHome.fullStoryAccess = false;
    }
    if (permissions.includes('NO_ADD_REMOVE_RIDES')) {
      csViewDetails.addRemoveRidesAccess = false;
    }
    if (permissions.includes('NO_ADD_NOTE_REFUND')) {
      csViewDetails.addRefundAccess = false;
    }
    if (permissions.includes('NO_UPDATE_CUSTOMER')) {
      csViewDetails.updateCustomerAccess = false;
    }
    if (permissions.includes('NO_CYBERSOURCE_REFUND')) {
      csViewDetails.cyberSourceRefundAccess = false;
    }
    dict.accessDetails = {
      dashboard: dashboardAccess,
      csAdminHome,
      csViewDetails
    }
    return dict;
  } catch (error) {
    logError("error", error);
  }
  return null;
}

function loginWithoutPermission(role) {
  let dict = {};
  try {
    dict = {};
    if (isNullOrEmpty(role)) {

      return constructResponse('Missing_Role', 400);
    }

    if ((role.indexOf('notification') === -1) && (role.indexOf('admin') === -1)) {
      console.log("Inside Invalid role");
      return constructResponse('Access_Not_Allowed', 403);
    } else {
      console.log("Inside Valid role");
      dict = {
        menuDetails: getOldMenuDetails(role),
        adminRoles: [role],
        notificationAccess:
        {
          "deliveryType": [],
          "operators": [],
          "accessControl": []
        }
      }
      if (role !== ('super_admin' || 'alert_cs_admin')) {
        if (role === ('gtfs_admin' || 'rates_admin' || 'ticket_redemptions')) {
          dict["notificationAccess"] = {};
        } else {
          const operatorCodeList = ['RCK', 'SUB', 'VGB', 'COM', 'HTL', 'WCL', 'NAE', 'WCC'];
          const accessList = ['view', 'edit', 'delete', 'readonly', 'create'];
          const deliveryTypeList = ['push', 'sms'];
          const splitRoleData = role.split('_');
          splitRoleData.forEach(data => {
            if (accessList.indexOf(data) >= 0) {
              if (data === 'readonly') {
                dict["notificationAccess"].accessControl.push('view');
              } else {
                dict["notificationAccess"].accessControl.push(data);
              }
            }
            if (deliveryTypeList.indexOf(data) >= 0) {
              dict["notificationAccess"].deliveryType.push(data);
            }
            if (operatorCodeList.indexOf(data) >= 0) {
              dict["notificationAccess"].operators.push(data);
            }
          });
          if (dict["notificationAccess"].operators.length === 0) {
            dict["notificationAccess"].operators = ['ALL'];
          }
          if (dict["notificationAccess"].deliveryType.length === 0) {
            dict["notificationAccess"].deliveryType = ['push', 'sms'];
          }
          if (dict["notificationAccess"].accessControl.length === 0) {
            dict["notificationAccess"].accessControl = ['view', 'edit', 'delete', 'create'];
          }
        }
      } else {
        dict["notificationAccess"].accessControl = ['view', 'edit', 'delete', 'create'];
        dict["notificationAccess"].deliveryType = ['push', 'sms'];
        dict["notificationAccess"].operators = ['ALL'];
      }
      if ((role.startsWith('notification') && !role.endsWith('admin'))) {
        dict['adminRoles'] = ['notification'];
      }
    }
    return dict;
  } catch (error) {
    logInfo("error", error);
  }
  return null;
}


function isNullOrEmpty(value) {
  if (value === null || value === 'null' || typeof value === 'undefined' || value === '') {
    return true;
  }
  return false;
}

function constructResponse(body, statusCode) {
  const response = {
    statusCode: statusCode,
    body: body,
  };
  // logInfo("Response to return is:=>", response);
  return response;
}
//utility function for storing logs
function logInfo(text, obj = '') {
  console.log(text, JSON.stringify(obj));
}

function logError(text, obj = '') {
  console.error(text, obj);
}

function getMenuDetails(dashboardAccess) {
  let menuDetails = [
    {
      "uiKey": "dashboard",
      "url": "#dashboard",
      "display": "Dashboard",
      "order": 1,
      "selected": true,
      "active": true,
      "idKey": "dashboard",
      "hasChildren": false,
      "childrens": []
    },
    {
      "uiKey": "csportal",
      "url": "/csportal",
      "display": "Customer Support",
      "order": 2,
      "selected": false,
      "active": false,
      "idKey": "customer_support",
      "haveChildren": false,
      "children": []
    },
    {
      "uiKey": "notification",
      "url": "/notification",
      "display": "Alert Admin",
      "order": 3,
      "selected": false,
      "active": false,
      "idKey": "notifications",
      "hasChildren": false,
      "children": []
    }
  ]
  if (!dashboardAccess.notificationSearch) {
    menuDetails.splice(2, 1);
  }
  if (!dashboardAccess.csAdminSearch) {
    menuDetails.splice(1, 1);
  }
  return menuDetails;
}


function getOldMenuDetails(role) {
  let menuDetails = [
    {
      "uiKey": "dashboard",
      "url": "#dashboard",
      "display": "Dashboard",
      "order": 1,
      "selected": true,
      "active": true,
      "idKey": "dashboard",
      "hasChildren": false,
      "childrens": []
    },
    {
      "uiKey": "csportal",
      "url": "/csportal",
      "display": "Customer Support",
      "order": 2,
      "selected": false,
      "active": false,
      "idKey": "customer_support",
      "haveChildren": false,
      "children": []
    },
    {
      "uiKey": "notification",
      "url": "/notification",
      "display": "Alert Admin",
      "order": 3,
      "selected": false,
      "active": false,
      "idKey": "notifications",
      "hasChildren": false,
      "childrens": []
    }
  ]
  if (role !== ('super_admin' || 'alert_cs_admin')) {
    let filterBy = {};
    if (role === 'gtfs_admin') {
      filterBy = { uiKey: ["gtfs_admin"] };
      menuDetails = getFilteredRoleData(menuDetails, filterBy);
    }
    if (role === 'rates_admin') {
      filterBy = { uiKey: ["rates_admin"] };
      menuDetails = getFilteredRoleData(menuDetails, filterBy);
    }
    if (role === 'ticket_redemptions') {
      filterBy = { uiKey: ["ticket_redemptions"] };
      menuDetails = getFilteredRoleData(menuDetails, filterBy);
    }
    if (role.substring(0, 5) === 'notif') {
      filterBy = { uiKey: ["notification"] };
      menuDetails = getFilteredRoleData(menuDetails, filterBy);
    }
  }
  return menuDetails;
}

function getFilteredRoleData(menuDetails, filterBy) {
  let result = [];
  result = menuDetails.filter(function (menu) {
    return Object.keys(filterBy).every(function (filter) {
      return filterBy[filter].some(function (key) {
        return menu[filter] === key;
      });
    });
  });
  return result;
}
//End Login Response after get roles and permissions from Cognito API----