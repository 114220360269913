import {
	AGENCY_CREATE_PENDING,
	AGENCY_CREATE_SUCCESS,
	AGENCY_CREATE_FAILED,
	AGENCY_EDIT_FETCH_PENDING,
	AGENCY_EDIT_FETCH_SUCCESS,
	AGENCY_EDIT_FETCH_FAILED,
	AGENCY_EDIT_PENDING,
	AGENCY_EDIT_SUCCESS,
	AGENCY_EDIT_FAILED
} from '../../util/constant';
import _ from 'lodash';
//import { agencydata } from '../../gtfsJson/agency.json';

const initialState = {
	isLoading: false,
	//agencies: agencydata,
	error: {},
	agencyItems: {},
	isAgencyLoading: false
};

const agencyReducer = (state = initialState, action) => {
	switch (action.type) {
		case AGENCY_CREATE_PENDING:
			return {
				...state,
				isLoading: true
			};
		case AGENCY_CREATE_SUCCESS:
			return {
				...state,
				isLoading: false,
				agencies: [
					...state.agencies,
					{ ...action.payload, agency_id: _.random() }
				]
			};
		case AGENCY_CREATE_FAILED:
			return {
				...state,
				isLoading: false,
				error: action.payload
			};
		case AGENCY_EDIT_FETCH_PENDING:
			return {
				...state,
				agencyItems: {},
				isAgencyLoading: true
			};
		case AGENCY_EDIT_FETCH_SUCCESS:
			return {
				...state,
				isAgencyLoading: false,
				agencyItems: action.payload
			};
		case AGENCY_EDIT_FETCH_FAILED:
			return {
				...state,
				isAgencyLoading: false,
				error: action.payload
			};
		case AGENCY_EDIT_PENDING:
			return {
				...state,
				isLoading: true
			};
		case AGENCY_EDIT_SUCCESS:
			return {
				...state,
				isLoading: false,
				agencies: _.map(
					state.agencies,
					(ag) =>
						ag.agency_id === action.payload.agency_id ? action.payload : ag
				)
			};
		case AGENCY_EDIT_FAILED:
			return {
				...state,
				isLoading: false,
				error: action.payload
			};
		default:
			return state;
	}
};

export default agencyReducer;
