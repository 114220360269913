import React, { useEffect} from 'react';
import Routes from './Routes/Routes';
import { BrowserRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { getUser, loginUserAccess, manageUnauthenticatedUser } from '../store/actions/AuthAction';
import _ from "lodash";
import useUserRole from './../util/hooks/UserRole';
import useUserPermissions from './../util/hooks/UserPermissions';

const App = ({ handleLoginAccess, getCurrentUser, manageUnauthenticatedUser }) => {
	const userRole = useUserRole();
	const userPermissions = useUserPermissions();
	const { user, isAuthenticated } = useSelector(state => state.auth);
	useEffect(() => {
		getCurrentUser()
	}, [getCurrentUser]);
	
	useEffect(() => {
		if (!_.isEmpty(userPermissions) && ( _.includes(_.find(userRole), "cs") || _.includes(_.find(userRole), "notification"))) {
			const data = {
				email: user.email,
				role: userRole,
				permissions: userPermissions
			};
			handleLoginAccess(data)
		}
		else if (!_.isEmpty(userPermissions) && ( !_.includes(_.find(userRole), "cs") || !_.includes(_.find(userRole), "notification"))) { 
			manageUnauthenticatedUser()
		}
	}, [user, userRole, handleLoginAccess]);
	
	return (
		<BrowserRouter>
			<Routes childProps={user} isAuthenticated={isAuthenticated} />
		</BrowserRouter>
	);
};
const mapDispatchToProps = (dispatch) => {
	return {
		getCurrentUser: () => getUser(dispatch),
		handleLoginAccess: (data) => loginUserAccess(data, dispatch),
		manageUnauthenticatedUser: () => manageUnauthenticatedUser(dispatch)
	};
};
export default connect(null, mapDispatchToProps)(App);

