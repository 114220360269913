import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Home from "../Home";
import Dashboard from "../pages/Dashboard";
import Login from "../auth/Login";
import Notifications from "../pages/notifications/view/Dashboard";
import NotificationCreate from "../pages/notifications/create/NotificationCreate";
import NotificationEdit from "../pages/notifications/edit/NotificationEdit";
import ViewNotifications from "../pages/notifications/view/NotificationView";
import CustomerSupport from "../pages/customerSupport/Home";
import ViewOrder from "../pages/customerSupport/vieworder/ViewOrder";
import { AppliedRoute } from "./AppliedRoute";
import TravelHistory from '../pages/customerSupport/travelHistory/View';
import FailedTransaction from '../pages/customerSupport/failedTransaction/View.jsx';
import useUserPermissions from '../../util/hooks/UserPermissions';
import _ from 'lodash';
import useUserAccessDetails from '../../util/hooks/UserAccessDetails';
const Routes = ({ childProps, isAuthenticated }) => {
  const userPermissions = useUserPermissions();
  const userAccessDetails = useUserAccessDetails();
  const dashboardAccess = _.get(userAccessDetails, "dashboard", {})
  if (!_.isEmpty(dashboardAccess)) {
    if (_.get(dashboardAccess, "csAdminSearch", "") && !_.get(dashboardAccess, "notificationSearch", "")) {
      return (
        <Switch>
          <AppliedRoute exact path="/" component={Home} user={childProps} isAuthenticated={isAuthenticated} permissions={userPermissions} />
          <Route path="/login" component={Login} />
          <Route path="/dashboard" exact component={Dashboard} />
          <Route path="/vieworder" component={ViewOrder} />
          <Route path="/travelhistory" component={TravelHistory} />
          <Route path="/csportal" component={CustomerSupport} />
          <Route path="/failedtransactions" component={FailedTransaction} />
          <Redirect from="*" to="/" />
        </Switch>
      )
    }
    if (_.get(dashboardAccess, "notificationSearch", "") && !_.get(dashboardAccess, "csAdminSearch", "")) {
      return (
        <Switch>
          <AppliedRoute exact path="/" component={Home} user={childProps} isAuthenticated={isAuthenticated} permissions={userPermissions} />
          <Route path="/login" component={Login} />
          <Route path="/dashboard" exact component={Dashboard} />
          <Route path="/notification" component={Notifications} />
          <Route path="/createNotifications" component={NotificationCreate} />
          <Route path="/editnotifications/:id" component={NotificationEdit} />
          <Route
            path="/viewnotifications/:id"
            exact
            component={ViewNotifications}
          />
          <Redirect from="*" to="/" />
        </Switch >
      )
    }
  }
  if (_.get(dashboardAccess, "notificationSearch", "") && _.get(dashboardAccess, "csAdminSearch", "")) {
    return (
      <Switch>
        <AppliedRoute exact path="/" component={Home} user={childProps} isAuthenticated={isAuthenticated} permissions={userPermissions} />
        <Route path="/login" component={Login} />
        <Route path="/dashboard" exact component={Dashboard} />
        <Route path="/notification" component={Notifications} />
        <Route path="/createNotifications" component={NotificationCreate} />
        <Route path="/editnotifications/:id" component={NotificationEdit} />
        <Route path="/vieworder" component={ViewOrder} />
        <Route
          path="/viewnotifications/:id"
          exact
          component={ViewNotifications}
        />
        <Route path="/travelhistory" component={TravelHistory} />
        <Route path="/csportal" component={CustomerSupport} />
        <Route path="/failedtransactions" component={FailedTransaction} />
        <Redirect from="*" to="/" />
      </Switch>
    );
  }
  return (
    <Switch>
      <AppliedRoute exact path="/" component={Home} user={childProps} isAuthenticated={isAuthenticated} permissions={userPermissions}/>
      <Route path="/login" component={Login} />
      <Route path="/dashboard" exact component={Dashboard} />
      <Redirect from="*" to="/" />
    </Switch>
 );
};
export default Routes;
