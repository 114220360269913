import {
	GTFS_TABLES_FETCH_PENDING,
	GTFS_TABLES_FETCH_SUCCESS,
	GTFS_TABLES_FETCH_FAILED,
	FETCH_GTFS_MENUS_PENDING,
	FETCH_GTFS_MENUS_SUCCESS,
	FETCH_GTFS_MENUS_FAILED
} from '../../util/constant';

const initialState = {
	isLoading: false,
	gtfsTablesData: [],
	gtfsMenusData: [],
	viewError: {}
};
const viewGtfsReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_GTFS_MENUS_PENDING:
			return {
				...state,
				isLoading: true
			};
		case FETCH_GTFS_MENUS_SUCCESS:
			return {
				...state,
				isLoading: false,
				gtfsMenusData: action.payload
			};
		case FETCH_GTFS_MENUS_FAILED:
			return {
				...state,
				isLoading: false,
				error: action.payload
			};
		case GTFS_TABLES_FETCH_PENDING:
			return {
				...state,
				gtfsTablesData: [],
				viewError:{},
				isLoading: true
			};
		case GTFS_TABLES_FETCH_SUCCESS:
			return {
				...state,
				isLoading: false,
				gtfsTablesData: action.payload
			};
		case GTFS_TABLES_FETCH_FAILED:
			return {
				...state,
				isLoading: false,
				viewError: action.payload
			};

		default:
			return state;
	}
};

export default viewGtfsReducer;
