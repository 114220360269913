import React, { useEffect, useMemo, useState } from "react";
import { Col, Container, Row,Spinner } from "reactstrap";
import MainWrapper from "../../../MainWrapper";
import Breadcrumbs from "../navigation/Breadcrumbs";
import Navigation from "../navigation/Navigation";
import Search from "../Search";
import NoResultsPopup from "../modal/NoResultsPopup";
import FailedTransPopup from "../modal/FailedTransPopup";
import List from "../list/CustomerSupportGrid";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { connect, useSelector } from "react-redux";
import { getCustomerSupportList } from "../../../../store/actions/CS_SearchAction";
const View = ({ getCSList }) => {
    const { isSearchLoading, listData } = useSelector(
        (state) => state.csSearchReducer
    );
    const [data, setData] = useState([]);
    const [isFailedTransaction, setIsFailedTransaction] = useState(false);
    const [filters, setFilters] = useState(false);
    const [noResultsPopup, setnoResultsPopup] = useState(false);
    const [showFailedTransPopup, setShowFailedTransPopup] = useState(false);
    const [failedTransData, setFailedTransData] = useState([]);
    const history = useHistory();
    useEffect(() => {
        setFilters(false);
        setnoResultsPopup(false);
        setShowFailedTransPopup(false)
        const path = history.location.pathname;
        setIsFailedTransaction(path === "/failedtransactions" && true);

        window.scrollTo(0, 0);
    }, []);
    const columns = useMemo(
        () => [
            {
                Header: "Transaction Date",
                accessor: "transactionOn",
                width: 210,
            },
            {
                Header: "Email Address",
                accessor: "email",
                width: 300,
            },
            
            {
                Header: "Order Total",
                accessor: "orderAmount",
                width: 100,
            },
            {
                Header: "Failed Reason",
                accessor: "failedReason",
                width: 350,
            },
            {
                Header: "Action",
                accessor: "failedAction",
                width: 140,
            }
        ],
        []
    );

    const onSearch = (data) => {
        getCSList(data);
        setFilters(!_.isEmpty(data.additionalFilterKey) && true);
    };
    useEffect(() => {
        const failedTransData =
            !_.isEmpty(listData) && _.get(listData, "searchFailedTransaction", {});
        if (
            filters &&
            (failedTransData.statusCode === 400 || _.isEmpty(failedTransData)) &&
            !isSearchLoading
        ) {
            setnoResultsPopup(true);
        } else {
            setData(failedTransData)
            // _.mapValues(failedTransData, (v) => setData((prev) => [...prev, ...v]));
        }
    }, [listData, filters, isSearchLoading]);

    const onClear = () => {
        setData([]);
    };
    const handleViewOrder = (data) => {
        setShowFailedTransPopup(true)
        setFailedTransData(!_.isEmpty(data.failedReasonDetails)? data.failedReasonDetails:[])
        
    };
    return (
        <MainWrapper>
            {isSearchLoading && (
          <div className="loader">
            <div className="spinner">
              <Spinner animation="border" variant="primary" />
            </div>
          </div>
        )}
            <Container>
                <Breadcrumbs isFailedTransaction={isFailedTransaction} />
                {showFailedTransPopup && (
                    <FailedTransPopup
                        modalOpen={showFailedTransPopup}
                        data={failedTransData}
                        handleClose={() => setShowFailedTransPopup(!showFailedTransPopup)}
                        isFailedTransaction={isFailedTransaction}
                    />
                )}
                {noResultsPopup && (
            <NoResultsPopup
              modalOpen={noResultsPopup}
              handleClose={() => setnoResultsPopup(!noResultsPopup)}
            />
          )}
                <Search
                    isFailedTransaction={isFailedTransaction}
                    onSearch={onSearch}
                    onClear={() => onClear()}
                />
                <Navigation isFailedTransaction={isFailedTransaction} />
                {!_.isEmpty(data) && (
                    <>
                        <Row className="mt-4">
                            <Col>
                                <span className="font24-b">FAILED TRANSACTION RESULT</span>
                            </Col>
                        </Row>
                        <List
                            data={data}
                            columns={columns}
                            handleViewOrder={handleViewOrder}
                        />

                    </>
                )}
            </Container>
        </MainWrapper>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCSList: (params) => getCustomerSupportList(params, dispatch)
    };
};

export default connect(null, mapDispatchToProps)(View);
