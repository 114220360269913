import React from 'react';
import { Row, Col, Breadcrumb, BreadcrumbItem, Button } from 'reactstrap';
import AddIcon from '../../../../assets/images/add.svg';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
const NotificationHeader = ({ userName, onCreateAlert, showView }) => {
	const history = useHistory();
	return (
		<div className="dashboard_header">
			<div className="container">
				<Row>
					<Col xs="6" className="text-left">
						<Breadcrumb className="page-breadcrumb">
							{' '}
							<BreadcrumbItem>
								<Link
									to={{
										pathname: '/notification'
									}}
								>
									Dashboard{' '}
								</Link>
							</BreadcrumbItem>{' '}
							<BreadcrumbItem>{showView && `/ View`}</BreadcrumbItem>
						</Breadcrumb>
						<div className="clearfix" />
						<h2 className="mt-2">Welcome {userName}</h2>
					</Col>
					<Col xs="6" className="text-right">
						<div className="pr-md-5">
							<Button
								className="btn btn-lg btn-light mt-3"
								onClick={() => history.push('/createNotifications')}
							>
								<img src={AddIcon} alt="add" /> Create Alerts
							</Button>
						</div>
					</Col>
				</Row>
			</div>
		</div>
	);
};
export default NotificationHeader;
